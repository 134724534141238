import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Button,
  Menu,
  message,
  DatePicker,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import GraphImage from "../../../Assets/Graph.png";
import Graphbar from "../../../Assets/Graphbar.png";
import Greencolor from "../../../Assets/greencolor.png";
import Orangecolor from "../../../Assets/orangecolor.png";
import Bluecolor from "../../../Assets/bluecolor.png";
import Browncolor from "../../../Assets/Browncolor.png";
import { toast } from "react-toastify";
import { AiFillCalendar } from "react-icons/ai";
import "./style.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Customized,
  Legend,
} from "recharts";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import {
  brandUnderwritingList,
  brandUnderwritingListWithDates,
  BASE_URL
} from "../../utils/Api";
import DateSelect from "./DatePicker";

const DataNetProcess = () => {
  const [date, setDate] = useState(new Date());
  const [underwritingList, setUnderwritingList] = useState([]);
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");
  const [commission, setCommission] = useState("");


  const getBrandUnderwritingList = async () => {
    const res = await brandUnderwritingList();
    setUnderwritingList(res.data.data);
  };

  useEffect(() => {
    getBrandUnderwritingList();
  }, []);

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  const handleDate = async () => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
    };

    const response = await brandUnderwritingListWithDates(requestedData);

    if (response?.status === 200) {
      toast.success(response.message);
      setUnderwritingList(response.data.data);
    } else {
      toast.error(response.message);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
          <p className="label">{`Underwriting Value : ${payload[0].payload.UnderWriting_Value}`}</p>
          <p className="label">{`Admin Fee : ${payload[0].payload.MBX_Admin_Fee}`}</p>
        </div>
      );
    }

    return null;
  };

  const data = [
    { name: "A", Share_Sold: 8, Share_Buy: 30, Profit: 20 },
    { name: "B", Share_Sold: 20, Share_Buy: 15, Profit: 11 },
    { name: "C", Share_Sold: 30, Share_Buy: 40, Profit: 7 },
    { name: "D", Share_Sold: 10, Share_Buy: 18, Profit: 13 },
    { name: "E", Share_Sold: 40, Share_Buy: 25, Profit: 18 },
    { name: "F", Share_Sold: 28, Share_Buy: 13, Profit: 2 },
    { name: "G", Share_Sold: 46, Share_Buy: 25, Profit: 16 },
    { name: "H", Share_Sold: 15, Share_Buy: 30, Profit: 20 },
    { name: "I", Share_Sold: 60, Share_Buy: 35, Profit: 20 },
  ];
  const data1 = underwritingList.map((res, i) => {
    return {
      name: i + 1,
      MBX_Admin_Fee: (5 / 100) * res.brandValue,
      MBX_Admin_FeeCopy: res.totalNumberOfShares,
      UnderWriting_Value: res.brandValue,
      Total_Listed_Share: res.totalNumOfListedShares,
      Sold_Shares: res.totalNumOfListedShares - res.totalNumberOfShares,
    };
  });

  const menu = (
    <Menu
      items={[
        {
          label: "1st menu item",
          key: "1",
          icon: <UserOutlined />,
        },
        {
          label: "2nd menu item",
          key: "2",
          icon: <UserOutlined />,
        },
        {
          label: "3rd menu item",
          key: "3",
          icon: <UserOutlined />,
        },
      ]}
    />
  );
  return (
    <div className="data-container">
      <h4>Net Process Per User</h4>
      <div className="dataChart">
        <div style={{ marginTop: "32px" }}>
          {" "}
          <CommanCalender />
        </div>
        <h4 className="totalSharevalue">Total value of shares </h4>
        <div className="image_container">
          <AreaChart width={1120} height={200} data={data}>
            <YAxis />
            <Tooltip />

            <Area
              dataKey="Share_Buy"
              stackId="1"
              stroke="#0f65d6"
              fill="#0f65d6"
              fillOpacity={1}
            />
            <Area
              dataKey="Share_Sold"
              stackId="1"
              stroke="#eb7c05"
              fill="#eb7c05"
              fillOpacity={1}
            />
            <Area
              dataKey="Profit"
              stackId="2"
              stroke="#05eb1c"
              fill="#05eb1c"
              fillOpacity={1}
            />
          </AreaChart>{" "}
        </div>
        <div className="datacolor">
          <span>
            {" "}
            Share Sold <img src={Orangecolor} />{" "}
          </span>{" "}
          <span>
            Share Buy <img src={Bluecolor} />
          </span>{" "}
          <span>
            {" "}
            Profit <img src={Greencolor} />
          </span>
        </div>
      </div>
      <div>
        <h4>Underwriting Proceeds Per Brand</h4>
        <div className="dataChart">
          <select
            className=""
            style={{
              marginLeft: "30px",
              marginTop: "20px",
              width: "150px",
              height: "30px",
            }}
            value=""
          >
            {underwritingList.map((brand, index) => (
              <option>
                {index + 1} {brand.brandUserName}
              </option>
            ))}
          </select>
          <div style={{ marginTop: "5px" }}>
            {" "}
            {/* <CommanCalender /> */}
            <div className="mainCalender">
              <div className="date_pick_box">
                <label>
                  <b>Select Date</b>
                </label>
                <label className="input_label">From</label>
                <DatePicker className="dateInput" onChange={onChangeStart} />
                <label className="input_label">To</label>
                <DatePicker className="dateInput" onChange={onChangeEnd} />
                <button className="buttonMain" onClick={handleDate}>
                  View
                </button>
              </div>
            </div>
          </div>
          {/* <DateSelect /> */}

          <div className="image_container">
            <BarChart
              width={1120}
              height={200}
              data={data1}
              strokeDasharray={1}
            >
              <Customized />
              <XAxis dataKey="name" />

              <Tooltip content={<CustomTooltip />} />
              <YAxis dataKey="MBX_Admin_FeeCopy" />
              {/* <CartesianGrid /> */}
              <Bar
                dataKey="Sold_Shares"
                stackId="a"
                fill="#F48327"
                fillOpacity={1}
                barSize={30}
                strokeDasharray={1}
              />
              <Bar
                dataKey="Total_Listed_Share"
                stackId="a"
                fill="#3F3D3E"
                fillOpacity={1}
                barSize={30}
                strokeDasharray={0.5}
              />
              {/* <Legend /> */}
            </BarChart>
          </div>
          <div className="datacolor">
            <span>
              Underwriting Value <img src={Browncolor} />{" "}
            </span>{" "}
            <span>
              {" "}
              MB.X Admin Fee (5%) <img src={Orangecolor} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataNetProcess;

import React, { useEffect, useState } from "react";
import UploadIcon from "../../../Assets/uploadIcon.svg";
import "./edit.scss";
import { getUsersById, BASE_URL, updatePaymentStatus } from "../../utils/Api";
import { Avatar, Image } from "antd";
import { Button, Form } from "react-bootstrap";

const EditModal = ({
  id,
  visible,
  userTransaction,
  setVisible,
  setUserTransactions,
  userTransactions,
}) => {
  const id1 = userTransaction.id;
  const [data, setData] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [comment, setComment] = useState("");
  useEffect(() => {
    const getData = async () => {
      setData(userTransaction);
      setSelectedStatus(userTransaction.Status);
      setComment(userTransaction.Comment);
    };
    getData();
  }, [id, userTransaction]);
  const changeStatus = async () => {
    try {
      await updatePaymentStatus(id1, selectedStatus, comment);

      const index = userTransactions.findIndex((data) => data._id === id1);
      //   setData(updatedUserTransaction);
      setUserTransactions((prevArr) => {
        let newArr = [...prevArr];
        newArr[index].paymentStatus = selectedStatus;
        newArr[index].comment = comment;
        return newArr;
      });
      setVisible(false);
      setComment("");
    } catch (error) {
      // Handle error
      console.error("Error updating payment status:", error);
    }
  };

  // console.log("userTransaction", userTransaction.Comment);

  return (
    <div className="managuser_modal">
      <div className="container">
        <div className="mb-3 mt-3 text-center">
          <h4>Withdraw Request</h4>
        </div>
        <div className="row_outerBox">
          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Username
              </div>
              <div className="col-lg-6">{data.userName}</div>
            </div>
          </div>
          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Email
              </div>
              <div className="col-lg-6">{data.Email}</div>
            </div>
          </div>

          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Banking Email
              </div>
              <div className="col-lg-6">{data.BankingEmail}</div>
            </div>
          </div>

          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Amount
              </div>
              <div className="col-lg-6">{data.Amount}</div>
            </div>
          </div>
          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Change Status
              </div>
              <div className="col-lg-6">
                <select
                  className="form-select"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="Processing">Processing</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Transaction Successful">
                    Transaction Successful
                  </option>
                  <option value="Transaction Failed">Transaction Failed</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Comment
              </div>
              <div className="col-lg-6">
                <textarea
                  style={{ width: "200px", height: "50px" }}
                  value={comment}
                  maxLength="120"
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Add note here..."
                  rowKey="2"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 text-center">
          <button
            className="withdrwal-modal-button btn-primary fs-6"
            type="submit"
            onClick={changeStatus}
          >
            Update Payment Status
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

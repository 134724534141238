import { React, useState, useEffect } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaSignInAlt, FaEdit } from "react-icons/fa";
import axios from "axios";
import Headers from "../../UserHeader/Headers";
import {
  getUserWalletbyId,
  getBrandWalletbyId,
  ChecuserWalletBeforeWithdraw,
  CheckbrandWalletBeforeWithdraw,
  withdrawmoneytoBrandWallet,
  InitialPaypalPayout,
  addUbrandPaypalAccounttoWallet,
  GetPayoutDetailsByBatchId,
  withdrawmoneytoUserWallet,
  addUserPaypalAccounttoWallet,
  Usewalletbeforwithdraw,
  withdrawrequestpayment,
  getwithdrawalBalance,
  getUserDetails,
} from "../../utils/Api";
import { Modal } from "antd";
import EditModal from "./Editmodal";

function UserWallet() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amount, setAmount] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(null);
  const [bankemailId, setBankemailId] = useState("");
  const [bankEmailError, setBankEmailError] = useState("");
  const { code } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [payerId, setPayerId] = useState("");
  const [message, setMessage] = useState("");
  const [payoutBatchId, setPayoutBatchId] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  // console.log(paypalEmail,"email")

  const [withdrawableAmount, setwithdrawableAmount] = useState(null);
  const [totalcost, settotalcost] = useState(null);

  let token = localStorage.getItem("token");
  let id = localStorage.getItem("resObj");
  let userType = localStorage.getItem("userType");
  let brandType = localStorage.getItem("brandType");

  // useEffect(() => {
  //   const getData = async () => {
  //     if (localStorage.getItem('userType')) {
  //       const data = await getUserWalletbyId(id);
  //       settotalcost(data.data.totalcost);
  //       setPaypalEmail(data.data.paypalEmail);

  //     } else if (localStorage.getItem('brandType')) {
  //       const data1 = await getBrandWalletbyId(id);
  //       settotalcost(data1.data.totalcost);
  //       setPaypalEmail(data1.data.paypalEmail);

  //     }
  //   };
  //   getData();
  //   const authCode = localStorage.getItem('code');
  //   getUserInfoWithAuthorizationCode(authCode);
  // }, []);

  // Email field Validation
  const validateEmail = (email) => {
    if (!email) {
      setBankEmailError("Email is required");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    if (!isValid) {
      setBankEmailError("Invalid email format");
    } else {
      setBankEmailError("");
    }

    return isValid;
  };

  //handle withdraw request button
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!bankemailId) {
      setBankEmailError("Banking email is required");
      return;
    }
    if (!validateEmail(bankemailId)) {
      setBankEmailError("Invalid email format");
      return;
    } else {
      setBankEmailError("");
    }
    await checkwallebeforeWithdraw();
  };

  //before
  //check user wallet before withdraw
  const checkwallebeforeWithdraw = async () => {
    const data = await ChecuserWalletBeforeWithdraw(id, amount,userType,brandType);
    // console.log("data", data);
    if (data?.status === 200) {
      setIsModalVisible(true);
    } else {
      toast.error(data?.message);
    }
  };

  //After
  // const checkwallebeforeWithdraw = async () => {
  //   if (localStorage.getItem('userType')) {
  //     const data = await ChecuserWalletBeforeWithdraw(id, amount);
  //     if (data?.status === 200) {
  //       payout(data.data.paypalPayerId, amount);
  //       toast.success(data?.message);
  //     } else {
  //       toast.error(data?.message);
  //     }
  //   } else if (localStorage.getItem('brandType')) {
  //     const brandData = await CheckbrandWalletBeforeWithdraw(id, amount);
  //     if (brandData?.status === 200) {
  //       payout(brandData.data.paypalPayerId, amount);
  //       toast.success(brandData?.message);
  //     } else {
  //       toast.error(brandData?.message);
  //     }
  //   }
  // };

  const fetchUserDetails = async () => {
    try {
      const response = await getUserDetails(id,userType,brandType);
      // Check the response status and handle accordingly
      if (response.status === 200) {
        const userdata = response?.Data?.bankingEmail;
        setBankemailId(userdata);
      } else {
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAvailableBalance = async () => {
    try {
      const response = await getwithdrawalBalance(id,userType,brandType);

      // Check the response status and handle accordingly
      if (response.status === 200) {
        const balance = response?.data?.totalcost;
        setAvailableBalance(balance);
      } else {
        // Handle errors or show appropriate messages
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    getAvailableBalance();
  }, []);

  //Available Balance

  const getEditData = () => {
    showModal();
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const withdrawpayment = async () => {
    try {
      if (userType) {
        const data = await withdrawrequestpayment(
          userType,
          id,
          amount,
          bankemailId
        );
        if (data?.status === 200) {
          toast.success(data?.message);
        } else {
          toast.error("Invalid amount.");
        }
        // console.log({ data });
      } else if (localStorage.getItem("brandType")) {
        //   const brandData = await CheckbrandWalletBeforeWithdraw(id, amount);
        //   if (brandData?.status === 200) {
        //     payout(brandData.data.paypalPayerId, amount);
        //     toast.success(brandData?.message);
        //   } else {
        //     toast.error(brandData?.message);
        //   }
      }
    } catch (error) {
      console.log(error.response.message);
      toast.error(error?.response?.response?.message);
    }
  };
  //Handle Amount

  //deduct money from wallet
  // const DeductmoneyfromWallet = async (amount, payoutBatchId, transactionId, paymentStatus) => {
  //   try {
  //     const data = await withdrawmoneytoUserWallet(id, amount, payoutBatchId, transactionId, paymentStatus)
  //     if (data?.status) {
  //       settotalcost(data?.data?.walletBalance)
  //       setAmount("")

  //       toast.success(`Withdrawal successful! 💰 Amount: ${amount}`);

  //     } else {
  //       toast.error(data?.message);
  //     }
  //   } catch (error) {
  //     toast.error('Failed to deduct balance');

  //   }
  // }

  // const DeductmoneyfromWallet = async (amount, payoutBatchId, transactionId, paymentStatus) => {
  //   try {
  //     let data;
  //     let isUserWithdrawal = false; // Set to false for brand withdrawal

  //     if (isUserWithdrawal) {
  //       data = await withdrawmoneytoUserWallet(id, amount, payoutBatchId, transactionId, paymentStatus);
  //     } else {
  //       data = await withdrawmoneytoBrandWallet(id, amount, payoutBatchId, transactionId, paymentStatus);
  //     }

  //     if (data && data.status) {
  //       settotalcost(data.data.walletBalance);
  //       setAmount("");
  //       toast.success(`Withdrawal successful! 💰 Amount: ${amount}`);
  //     } else {
  //       toast.error(data && data.message ? data.message : "Withdrawal failed");
  //     }
  //   } catch (error) {
  //     toast.error('Failed to deduct balance');
  //   }
  // }

  const DeductmoneyfromWallet = async (
    amount,
    payoutBatchId,
    transactionId,
    paymentStatus
  ) => {
    try {
      let userData, brandData;

      // Withdraw from user wallet
      userData = await withdrawmoneytoUserWallet(
        id,
        amount,
        payoutBatchId,
        transactionId,
        paymentStatus
      );

      // Withdraw from brand wallet
      brandData = await withdrawmoneytoBrandWallet(
        id,
        amount,
        payoutBatchId,
        transactionId,
        paymentStatus
      );

      let data;
      if (userData?.status) {
        data = userData;
      } else if (brandData?.status) {
        data = brandData;
      } else {
        throw new Error("Withdrawal failed");
      }

      let walletBalance;
      if (userData?.data?.walletBalance) {
        walletBalance = userData.data.walletBalance;
      } else if (brandData?.data?.walletBalance) {
        walletBalance = brandData.data.walletBalance;
      } else {
        throw new Error(
          "Withdrawal failed: Invalid response from the wallet API"
        );
      }

      settotalcost(walletBalance);
      setAmount("");
      toast.success(`Withdrawal successful! 💰 Amount: ${amount}`);
    } catch (error) {
      toast.error(error.message || "Failed to deduct balance");
    }
  };

  // Payout Details By Batch Id
  const getbatchid = async (payoutBatchId) => {
    setTimeout(async () => {
      const data = await GetPayoutDetailsByBatchId(payoutBatchId);

      let pyamentDetails = data?.payoutItem?.items[0];

      if (pyamentDetails?.transaction_status === "SUCCESS") {
        DeductmoneyfromWallet(
          data?.payoutItem?.batch_header?.amount?.value,
          pyamentDetails?.payout_batch_id,
          pyamentDetails?.transaction_id,
          pyamentDetails?.transaction_status
        );
      }
    }, 1400);
  };

  // paypal payout
  const payout = async (payerId, amount) => {
    try {
      const data = await InitialPaypalPayout(payerId, amount);

      if (data?.success) {
        getbatchid(data.payout.batch_header.payout_batch_id);
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error("Failed to check wallet balance");
    }
  };

  //  Add paypal account Link
  const handlePaypalAdd = async () => {
    try {
      const response = await fetch(
        "https://app.mybrand.exchange:5048/get-authorizeurl?type=web"
      );
      const data = await response.json();
      window.location.href = data.userLoginLink; // Redirect the user to the PayPal login page
    } catch (error) {
      // console.log(error);
    }
  };

  //userInfo using authorization code
  // const getUserInfoWithAuthorizationCode = async (authorizationCode) => {
  //   try {
  //     if (!authorizationCode) {
  //       throw new Error('Authorization code is required');
  //     }
  //     const response = await fetch(`https://app.mybrand.exchange:5048/paypal-user-info/${authorizationCode}`);
  //     const data = await response.json();
  //      if(data.status===200){
  //       addpaypaltouserwallet(data.UserInfo.payer_id, data.UserInfo.email);
  //       setPaypalEmail(data.UserInfo.email);
  //     }else{
  //     }
  //   } catch (error) {
  //   }
  // };

  const getUserInfoWithAuthorizationCode = async (authorizationCode) => {
    try {
      if (!authorizationCode) {
        throw new Error("Authorization code is required");
      }
      const response = await fetch(
        `https://app.mybrand.exchange:5048/paypal-user-info/${authorizationCode}`
      );
      const data = await response.json();
      if (data.status === 200) {
        addpaypaltouserwallet(data.UserInfo.payer_id, data.UserInfo.email);
        setPaypalEmail(data.UserInfo.email);
        if (localStorage.getItem("brandType")) {
          const brandData = await addpaypaltouserwallet(
            data.UserInfo.payer_id,
            data.UserInfo.email
          );
          // Handle the response and update the brand's wallet if needed
        }
      } else {
        // Handle the error
      }
    } catch (error) {
      // Handle the error
    }
  };

  // const addpaypaltouserwallet=async (payerId,email)=>{
  //   try{
  //     const data = await addUserPaypalAccounttoWallet(id,payerId,email )
  //   }catch(error){

  //   }
  // }

  const addpaypaltouserwallet = async (payerId, email) => {
    try {
      if (localStorage.getItem("userType")) {
        const userData = await addUserPaypalAccounttoWallet(id, payerId, email);
        // Handle the response and update the user's wallet if needed
      } else if (localStorage.getItem("brandType")) {
        const brandData = await addUbrandPaypalAccounttoWallet(
          id,
          payerId,
          email
        );
        // Handle the response and update the brand's wallet if needed
      }
    } catch (error) {
      // Handle the error
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get("code");

  return (
    <>
      <Headers />
      <section className="main-wrapper-loginn">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 paymentPagee">
              <div className="Login-wrapperr paymentTopp">
                <div className="login-wrap-logoo text-center mt-4">
                  <h4>Withdraw Balance</h4>
                </div>
              </div>
              <div className="Login-wrapperr paymentTopp">
                <div className="login-wrap-logoo text-center">
                  <h6>Available Balance: $ {availableBalance}</h6>
                </div>
              </div>
              <div className="Login-wrapperr">
                <div className="login-wrap-logoo text-center">
                  <h4>Enter Amount</h4>
                  <input
                    type="number"
                    placeholder="Please Enter"
                    name="amount"
                    className="text-center"
                    style={{
                      width: "200px",
                      bottom: "25px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="Login-wrapperr">
                <div
                  className="login-wrap-logoo text-center"
                  style={{ position: "relative" }}
                >
                  <h4>Enter Banking Email</h4>
                  <input
                    type="email"
                    placeholder="Please Enter Bank Email"
                    name="bankemailId"
                    className="text-center"
                    style={{
                      width: "200px",
                      bottom: "25px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                      height: "40px",
                      marginLeft: "23px",
                    }}
                    value={bankemailId}
                    onChange={(e) => setBankemailId(e.target.value)}
                  />
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>Update your bank email id.</Tooltip>
                    )}
                    placement="bottom"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle infoicon ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </OverlayTrigger>
                </div>
                {bankEmailError && (
                  <div
                    className="text-center"
                    style={{ color: "red", marginTop: "2px" }}
                  >
                    {bankEmailError}
                  </div>
                )}
              <small className="text-center d-block"><strong>Note:</strong> This transaction will be initiated within 24 working hours.</small>
              </div>
              {/* <div className="Login-wrapperr">
                <div className="login-wrap-logoo text-center">
                  <h4>Withdrawable Balance: $ {totalcost}</h4>
                </div>
              </div>
              <div className="Login-wrapperr">
                <div className="login-wrap-logoo">
                  <h4>Paypal Account Details</h4>
                  <p>Email: {paypalEmail}</p>
                </div>
                <button

                  style={{
                    marginTop: "15px",
                    marginLeft: "5px",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <FaEdit onClick={handlePaypalAdd} />

                </button>
              </div> */}
              <div className="login-loginn">
                <button
                  type="submit"
                  className="btn btn-login cursor-pointerr"
                  onClick={handleSubmit}
                >
                  Withdraw Amount
                </button>
              </div>
            </div>
          </div>
          <Modal
            centered
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <EditModal
              userId={id}
              userType={userType}
              brandType={brandType}
              amount={amount}
              bankemailId={bankemailId}
              visible={isModalVisible}
              setVisible={setIsModalVisible}
              setAmounts={setAmount}
            />
          </Modal>
        </div>
      </section>
    </>
  );
}
export default UserWallet;

import React, { useEffect, useState } from "react";
import { Avatar, Pagination } from "antd";
import CommonTable from "../../common/Table";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";

const ModalTransaction = ({ details, transactionRecords, onSubmit }) => {
  const onChange = async (e) => {
    onSubmit(e);
  };

  const columns2 = [
    {
      title: "No Of Share",
      dataIndex: "share",
      key: "share",
      width: "18%",
    },
    {
      title: "Cost Price Per Share",
      dataIndex: "costshare",
      key: "costshare",
      width: "25%",
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      width: "20%",
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "cost",
      width: "18%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "18%",
    },
  ];

  const data2 = details?.transactionData?.Data?.map((key, index) => {
    return {
      key: index,
      share: key?.numberOfShare,
      costshare: key?.costPricePerShare,
      type:
        key?.sellerId === details?.id
          ? "Seller"
          : key?.buyerId === details?.id
          ? "Buyer"
          : "N/A",
      cost: key?.totalCost,
      date: moment(key.createdAt).format("LL"),
    };
  });

  return (
    <div className="container">
      <div className="heading">
        <div
          style={{
            marginLeft: "185px",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <b>{details?.name}</b>
        </div>
      </div>
      <div className="contentTable">
        <div className="contentTable_inner">
          <CommonTable
            columns={columns2}
            data={data2}
            onChange={(e) => onChange(e)}
            total={transactionRecords}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalTransaction;

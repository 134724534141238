import React, { useEffect, useState } from "react";
import { Avatar, Image, Input, Table } from "antd";
import CommonTable from "../../common/Table";
import "./style.scss";
import {
  currentbrandvalue,
  currentbrandvaluewithShowData,
  BASE_URL
} from "../../utils/Api";
import moment from "moment";

const Current_brand_value = () => {
  const [currentBrandData, setcurrentBrandData] = useState();
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  const [pageSize, setPageSize] = useState("");
  let search = "";

  const columns = [
    {
      title: " Brands",
      dataIndex: "Brands",
      key: "Brands",
      width: "16%",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: "12%",
    },
    {
      title: "Re-Valuations",
      dataIndex: "AdjustedValuation",
      key: "AdjustedValuation",
      width: "15%",
    },
    {
      title: "Re-Val Share Price",
      dataIndex: "AdjustedShare",
      key: "AdjustedShare",
      width: "15%",
    },
    {
      title: "Share Price",
      dataIndex: "PerUnitSharePrice",
      key: "PerUnitSharePrice",
      width: "10%",
    },
    {
      title: "Target Valuations",
      dataIndex: "TotalValueOfholdings",
      key: "TotalValueOfholdings",
      width: "15%",
    },
    {
      title: "Created Date",
      dataIndex: "Date",
      key: "Date",
      width: "30%",
    },
  ];
  const getcurrentbrandvalue = async () => {
    const res = await currentbrandvalue({ search: search, pageno: pageno });
    setcurrentBrandData(res.data.Brandlist);
    settotal(res.data.total);
  };

  const handlePageData = async (e, page) => {
    const res = await currentbrandvaluewithShowData(pageno, e);
    if (res?.status === 200) {
      setcurrentBrandData(res?.data?.Brandlist);
      settotal(res?.data?.total);
    }
  };

  useEffect(() => {
    getcurrentbrandvalue();
  }, [pageno]);

  const currentvaluedata =
    currentBrandData?.map((key, index) => {
      return {
        key: index,
        displayPicture: key?.brandImage,
        Brands: key?.brandUserName,
        Status: key?.brandstatus,
        AdjustedValuation: key.brandAdjustedValution
          ? key.brandAdjustedValution
          : "----",
        AdjustedShare: key?.brandAdjustedSharePrice
          ? key.brandAdjustedSharePrice
          : "----",
        Date: moment(key.createdAt).format("LL"),
        PerUnitSharePrice: key?.brandSellingPrice,
        TotalValueOfholdings: key?.brandValue,
      };
    }) || [];

  const onChange = async (e) => {
    setpageno(e);
  };

  const handleSearch = async (nameSearch) => {
    const res = await currentbrandvalue({ search: nameSearch, pageno: pageno });
    setcurrentBrandData(res.data.Brandlist);
  };

  return (
    <div className="current-value">
      <div className="current-value-label">
        <h4>Current Brand Value</h4>
      </div>
      <div className="current_brand_value_table">
        <div className="" style={{ display: "flex", flexShrink: "0" }}>
          <div style={{ width: "100%" }}>
            <Input.Search
              allowClear
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* <div>
            <select
              className=""
              style={{
                marginLeft: "30px",
                marginTop: "",
                width: "150px",
                height: "30px",
              }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handlePageData(e.target.value);
              }}
            >
              {[10, 50, 100, 200].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={currentvaluedata}
          pagination={{
            className: "customPagination",
            showQuickJumper: {
              goButton: <button className="go_button">Go</button>,
            },
            defaultCurrent: 1,
            onChange: (page, pageSize) => {
              setpageno(page);
              handlePageData(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "200"],
            total: total,
            // showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: 500 }}
          className="oe"
        />
      </div>
    </div>
  );
};

export default Current_brand_value;

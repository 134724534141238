import React, { useState, useEffect } from "react";
import { Avatar, Image, Input, Table } from "antd";
import CommonTable from "../../common/Table";
import "./style.scss";
import {
  currentbrandvalue,
  totalplatformvalue,
  totalplatformvaluewithShowData,
  BASE_URL
} from "../../utils/Api";

const Total_value_of_platforms = () => {
  const [totalBrandData, settotalBrandData] = useState();
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  const [totalValueOfPlatform, setTotalValueOfPlatform] = useState();

  var search = "";

  const gettotalbrandvalue = async () => {
    const res = await totalplatformvalue({ search: search, pageno: pageno });
    settotalBrandData(res.data.brandList);
    settotal(res?.data?.totalRecords);
    const convertedValue = res?.data?.totalvalueOfHoldings.toLocaleString();
    setTotalValueOfPlatform(convertedValue);
  };

  useEffect(() => {
    gettotalbrandvalue();
  }, [pageno, total]);

  const columns = [
    {
      title: "Active Brands",
      dataIndex: "ActiveBrands",
      key: "ActiveBrands",
      width: "25%",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Total Value Of Shares",
      dataIndex: "TotalNumberOfShares",
      key: "TotalNumberOfShares",
      width: "25%",
    },
    {
      title: "Adjusted Share Price",
      dataIndex: "AdjustPrice",
      key: "AdjustPrice",
      width: "25%",
    },
    {
      title: "Original Target value",
      dataIndex: "OriginalValue",
      key: "OriginalValue",
      width: "25%",
    },
  ];

  const totalvaluedata =
    totalBrandData?.map((key, index) => {
      return {
        key: index,
        displayPicture: key?.brandImage,
        ActiveBrands: key?.brandUserName,
        TotalNumberOfShares: key?.brandAdjustedValution,
        OriginalValue: key?.brandValue,
        AdjustPrice: key?.brandAdjustedSharePrice,
      };
    }) || [];

  const handlePageData = async (e, page) => {
    const res = await totalplatformvaluewithShowData(pageno, e);
    if (res?.status === 200) {
      settotalBrandData(res?.data?.brandList);
      settotal(res?.data?.totalRecords);
    }
  };

  const onChange = async (e) => {
    setpageno(e);
  };
  const handleSearch = async (nameSearch) => {
    const res = await currentbrandvalue({ search: nameSearch, pageno: pageno });
    settotalBrandData(res.data.Brandlist);
  };
  return (
    <div className="value_container ">
      <div className="heading ">
        <h4>
          Total Value of Platforms{" "}
          <span className="totalnumber">${totalValueOfPlatform}</span>
        </h4>
      </div>
      <div className="contentTable  ">
        <Input.Search
          allowClear
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Table
          columns={columns}
          dataSource={totalvaluedata}
          pagination={{
            className: "customPagination",
            showQuickJumper: {
              goButton: <button className="go_button">Go</button>,
            },
            defaultCurrent: 1,
            onChange: (page, pageSize) => {
              setpageno(page);
              handlePageData(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "200"],
            total: total,
            // showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: 500 }}
          className="oe"
        />
      </div>
    </div>
  );
};

export default Total_value_of_platforms;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  message,
  Pagination,
  Image,
  Input,
  Menu,
  Space,
  Dropdown,
  Button,
  Modal,
  Table,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  FacebookFilled,
  YoutubeFilled,
  InstagramFilled,
} from "@ant-design/icons";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import CommonTable from "../../common/Table";
import {
  brandProfileList,
  DownloadEmailpdf,
  brandProfileListwithShowData,
  getBrandProfileToExcel,
  BASE_URL,
} from "../../utils/Api";

import "./style.scss";
import ModalUse from "./ModalUse";

const BrandProfile = () => {
  const [userdata, setuserData] = useState();
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  const [id, setId] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [social, setSocial] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState();
  const [viewId, setViewId] = useState("");

  let search = "";

  const getuserlisting = async (searchData, page) => {
    const res = await brandProfileList({ search: searchData, pageno: page });
    if (res.status === 200) {
      setuserData(res.data.userlist);
      settotal(res.data.total);
    }
  };

  const adminToken = localStorage.getItem("token");
  const ExportToExcel = async () => {
    const res = await getBrandProfileToExcel(adminToken);
  };

  useEffect(() => {
    getuserlisting(search, pageno);
  }, [pageno]); //total removed

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  const getRowData = (record) => {
    setId(record?.id);
  };

  const getEditData = async (v) => {
    setModalData(v);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePageData = async (e, page) => {
    // setPageData(e);
    // getuserlisting()
    //
    const res = await brandProfileListwithShowData(pageno, e);
    if (res?.status === 200) {
      setuserData(res?.data?.userlist);
      settotal(res?.data?.total);
    }
  };

  const socialData = userdata?.map((key, index) => {
    return {
      // key: key?.brandSocialBrand?.socialType,
      name: key?.brandSocialBrand,
    };
  });

  let emptyArr = [];

  for (let i = 0; i < userdata?.length; i++) {
    emptyArr.push({
      name: userdata[i].brandSocialBrand,
    });
  }

  const menu = (
    <Menu
    // items={[
    //   {
    //     label: "Instagram",
    //     key: "1",
    //     icon: <InstagramFilled />,
    //   },
    //   {
    //     label: "Facebook",
    //     key: "2",
    //     icon: <FacebookFilled />,
    //   },
    //   {
    //     label: "YouTube",
    //     key: "3",
    //     icon: <YoutubeFilled />,
    //   },
    // ]}
    />
  );

  let items = [];

  const data =
    userdata?.map((key, index) => {
      return {
        key: index,
        displayPicture: key?.brandImage,
        name: key?.brandUserName,
        email: key?.brandEmail,
        Address:
          key?.brandStreet +
          ", " +
          key?.brandCity +
          ", " +
          key?.brandState +
          ", " +
          key?.brandZipCode,
        SSN: key?.brandSSN,
        SocialMedia: key?.brandSocialBrand?.map((res, i) => {
          return res?.socialType === 1
            ? `Instagram \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ${res.userName}`
            : res?.socialType === 2
            ? `Facebook \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ${res.userName}`
            : res?.socialType === 3
            ? `Youtube \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ${res.userName}`
            : "N/A";
        }),
      };
    }) || [];

  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "SSN",
      dataIndex: "SSN",
      key: "SSN",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => getEditData(record)}>View Social Media</Button>
        </Space>
      ),
    },
  ];

  const handleSearch = async (nameSearch) => {
    const res = await brandProfileList({
      search: nameSearch,
      pageno: pageno,
    });
    setuserData(res.data.userlist);
  };

  const onChange = async (e) => {
    // e.preventDefault()
    setpageno(e);
  };
  return (
    <div className="userProfile">
      <div className="userprofile_label">
        <h4>Brand Profile </h4>
        <button className="exportdetail" onClick={() => ExportToExcel()}>
          Export Details
        </button>
      </div>
      <div className="userTable">
        <div className="" style={{ display: "flex", flexShrink: "0" }}>
          <div style={{ width: "100%" }}>
            <Input.Search
              allowClear
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* <div>
            <select
              className=""
              style={{
                marginLeft: "30px",
                marginTop: "",
                width: "150px",
                height: "30px",
              }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handlePageData(e.target.value);
              }}
            >
              {[10, 50, 100, 200].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            className: "customPagination",
            showQuickJumper: {
              goButton: <button className="go_button">Go</button>,
            },
            defaultCurrent: 1,
            onChange: (page, pageSize) => {
              setpageno(page);
              handlePageData(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "200"],
            total: total,
            // showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: 500 }}
          className="oe"
        />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        // className="user-profile-modal modal-height-fix"
      >
        <ModalUse details={modalData} />
      </Modal>
    </div>
  );
};

export default BrandProfile;

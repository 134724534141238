import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../../Assets/MBXlogo.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./style.scss";
import { LOGIN } from "../../utils/Api";

function Login() {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const login = async (e) => {
    e.preventDefault();
    const response = await LOGIN(values);
    if (response?.data.success) {
      navigate("/manageuser");
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem(
        "resObj",
        JSON.stringify(response?.data?.data?.user_info)
      );
      toast.success(response?.data?.message);
      window.location.reload();
      navigate("/manageuser");
    } else {
      toast.error(response?.data?.message);
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  return (
    <>
      <section className="main-wrapper-login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="Login-wrapper">
                <div className="login-wrap-logo text-center">
                  <img src={Logo} alt="Logo" />
                </div>
                <div className="loginbody--form">
                  <Form onSubmit={login}>
                    <Row>
                      <Col xs="12">
                        <Form.Control
                          type="email"
                          className="input-login-control"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs="12">
                        <div className="form-group">
                          <Form.Control
                            type={!isShowPassword ? "password" : "text"}
                            className="input-login-control mb-4"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                          />
                          <span>
                            {!isShowPassword ? (
                              <FaEye
                                onClick={(e) => handleShowPassword(e, "show")}
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={(e) => handleShowPassword(e, "hide")}
                              />
                            )}
                          </span>
                        </div>
                      </Col>

                      <Col xs="6">
                        <div className="remember-me mb-5">
                          <span>
                            <input type="checkbox" />
                            <label className="ms-2 cursor-pointer">
                              Remember Me
                            </label>
                          </span>
                        </div>
                      </Col>

                      <Col xs="6">
                        <div className="forgot--txtlink text-end mb-5">
                          <span
                            className="link-forgot cursor-pointer"
                            onClick={() => {
                              navigate("/forgetpassword");
                            }}
                          >
                            Forgot password?
                          </span>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="login-login">
                          <button
                            type="submit"
                            className="btn btn-login cursor-pointer"
                          >
                            Login
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;

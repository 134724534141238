
import { Navigate, useRoutes } from "react-router";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserLogin from '../UserLogin';
import UserWallet from '../UserWallet';
import { useLocation,useParams } from 'react-router';


const Route = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const {code} = useParams()
        // console.log(code);
        if (code) {
            localStorage.setItem('code', code);
            // setIsLogged(true);
          }
        
    const [isLogged, setislogged] = useState("");
    useEffect(() => {
        navigate('/userwallet')

    },[] );
  
return(
    <div>
    </div>
)
}
export default Route

import axios from "axios";
import { toast } from "react-toastify";

// export const BASE_URL = "http://localhost:5048/";
// export const BASE_URL = "https://mean.stagingsdei.com:454/";

export const BASE_URL = "https://app.mybrand.exchange:5048/";

export async function LOGIN(values) {
  const url = BASE_URL + "adminlogin";
  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function USERLOGIN(values) {
  const url = BASE_URL + "login";

  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })

    .catch((err) => {
      // toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}
//??
export async function BrandLOGIN(values) {
  const url = BASE_URL + "brandLogin";
  return await axios
    .post(url, values)
    .then((res) => {
      console.log(res, "res1");
      return res;
    })

    .catch((err) => {
      // toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function forgetPassword(values) {
  const url = BASE_URL + "admin/forgot-password";
  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function changePassword(values, authToken) {
  const url = BASE_URL + "changeAdminPassword";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function resetPassword(values, resetToken) {
  const url = BASE_URL + `admin/reset-password?token=${resetToken}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function UserResetPassword(id, password, confirmPassword) {
  const url = BASE_URL + `reset-password/${id}`;

  const data = {
    password,
    confirmPassword,
  };

  return await axios
    .post(url, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function UserforgetPassword(values) {
  const url = BASE_URL + "forgot-password";
  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function UserVerifyOtp(values) {
  const url = BASE_URL + `otp/${values.userId}`;
  return await axios
    .patch(url, { otp: values.otp })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function BrandforgetPassword(values) {
  const url = BASE_URL + "brand/forgot-password";
  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function userlisting(pageno, pageData) {
  const url = BASE_URL + `adminuserlisting?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandlisting(pageno, pageData) {
  //console.log(pageno , "brandlisting")
  const url =
    BASE_URL + `adminbrandlisting?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function UserProfileListing({ search, pageno }) {
  const url = BASE_URL + `UserProfileList?search=${search}&page=${pageno}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function UserProfileListingwithShowData(pageno, pageData) {
  const url = BASE_URL + `UserProfileList?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandProfileList({ search, pageno }) {
  const url = BASE_URL + `brandProfileList?search=${search}&page=${pageno}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandProfileListwithShowData(pageno, pageData) {
  const url = BASE_URL + `brandProfileList?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function currentbrandvalue({ search, pageno }) {
  const url = BASE_URL + `currentbrandvalue?search=${search}&page=${pageno}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function currentbrandvaluewithShowData(pageno, pageData) {
  const url =
    BASE_URL + `currentbrandvalue?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandUnderwritingList() {
  const url = BASE_URL + `brandUnderwritingList`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function userCount() {
  const url = BASE_URL + `usersCount`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function brandCount() {
  const url = BASE_URL + `brandsCount`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function butsharebrandCount() {
  const url = BASE_URL + `buyshareCount`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function DownloadEmailpdf() {
  const url = BASE_URL + `buy-shares-list`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function buysharebrandlisting() {
  const url = BASE_URL + `buysharebrandlist`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "response");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function EnableDisableUser(values) {
  // console.log(values);
  const url = BASE_URL + "decativateUser";
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function haltBrand(values) {
  // console.log("halt values78: ", values);
  const url = BASE_URL + "haltBrandTrading";
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res78:");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserById(id) {
  const url = BASE_URL + `viewUserProfile/${id}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getBrandById(id) {
  const url = BASE_URL + `viewBrandProfile/${id}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function getBrandByName({ nameSearch, pageno, perPage = 10 }) {
  const url =
    BASE_URL +
    `adminbrandlisting?page=${pageno}&per_page=${perPage}&search=${nameSearch}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserByName({ nameSearch, pageno }) {
  const url = BASE_URL + `adminuserlisting?page=${pageno}&search=${nameSearch}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandListWithActiveStatus(search, page) {
  const url = BASE_URL + `active-brand-list?search=${search}&page=${page}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandListActiveStatuswithShowData(pageno, pageData) {
  const url =
    BASE_URL + `active-brand-list?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandListWithUnderwritingStatus(search, page) {
  const url =
    BASE_URL + `underwriting-brand-list?search=${search}&page=${page}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandListUnderwritingStatuswithShowData(
  pageno,
  pageData
) {
  const url =
    BASE_URL + `underwriting-brand-list?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function shortSellersList(search, page) {
  const url = BASE_URL + `short-sellers-list?search=${search}&page=${page}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getMBX() {
  const url = BASE_URL + `admin/getapplembxcommision`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url)
    .then((res) => {
      console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function shortSellersListwithShowData(pageno, pageData) {
  const url =
    BASE_URL + `short-sellers-list?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function fullValueBrandList(search, page) {
  const url = BASE_URL + `fullValueBrandList?search=${search}&page=${page}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function fullValueBrandListwithShowData(pageno, pageData) {
  const url =
    BASE_URL + `fullValueBrandList?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getStaticPage(type) {
  const url = BASE_URL + `getContentByType?type=${type}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function updateStaticPage(id, data) {
  const url = BASE_URL + `updateContent/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .put(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function openSellList(search, pageno) {
  const url = BASE_URL + `openSellTrades?search=${search}&page=${pageno}`; //?search=${search}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function completedSellList(search, pageno) {
  const url = BASE_URL + `completedSellTrades?search=${search}&page=${pageno}`; //?search=${search}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function openBuyList(search, pageno) {
  const url = BASE_URL + `openBuyTrades?search=${search}&page=${pageno}`; //?search=${search}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function completedBuyList(search, pageno) {
  const url = BASE_URL + `completedBuyTrades?search=${search}&page=${pageno}`; //?search=${search}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserHoldings(id, modalPageNo) {
  const url = BASE_URL + `getUserHolding/${id}?per_page=10&page=${modalPageNo}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserTransactions(id, traModalPageNo) {
  const url =
    BASE_URL + `getuserTradingHistory/${id}?per_page=10&page=${traModalPageNo}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserTransactionsHis(data) {
  const url = BASE_URL + `users-transactions-history`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getBrandTransactionsHis(data) {
  const url = BASE_URL + `brands-transactions-history`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
const adminToken = localStorage.getItem("token");
export async function UnsettledBrandList({ search, pageno, authToken }) {
  const url =
    BASE_URL + `brandListForSettlement?search=${search}&page=${pageno}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getBrandHoldings(id, modalPageNo) {
  const url =
    BASE_URL +
    `getbrandListingShareHolders/${id}?per_page=10&page=${modalPageNo}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function makeListingPayements(values, authToken, id) {
  console.log("---->>>", id.id, values, authToken);
  console.log("id 7895: ", id);
  const url = BASE_URL + `makeListingPaymentToBrand/${id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  console.log("--------------------------");
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      console.log("---------------------------: ", res);
      if (res.data.status === 200) {
        // console.log("response 90: ",res)
        toast.success(res.data.message);
      } else if (res.data.status === 400) {
        toast.error(res.data.message);
      }
    })
    // .catch((err) => {
    //   console.log(err, "error");
    // });
    .catch((err) => {
      toast.error(err.response.data.message);
    });
}

export async function getShareHoldersToExcel(id) {
  console.log("id899:", id);
  const url = BASE_URL + `listingShareHoldersToExcel/${id}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, {
      responseType: "arraybuffer",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No share holders found"));
}

export async function getBrandListingToExcel(id) {
  console.log("id56:", id);
  const url = BASE_URL + `exportActiveBrandShareHoldersToExcel/${id}`;

  return await axios
    .get(url, {
      responseType: "arraybuffer",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No share holders found"));
}

export async function getAdminNotifications(modalPageNo) {
  const url =
    BASE_URL + `getAdminNotifications?per_page=10&page=${modalPageNo}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function sendEmailToAllUsers(values, authToken) {
  const url = BASE_URL + "sendEmailToAllUsers";
  // const headers = {
  //   Authorization: adminToken,
  // };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}
export async function brandUnderwritingListWithDates(values) {
  const url =
    BASE_URL +
    `brandUnderwritingList?fromDate=${values.fromDate}&toDate=${values.toDate}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  // console.log("values: ", values);
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getUserProfileToExcel() {
  const url = BASE_URL + "userEmailpdf";
  // console.log("Admin token:", adminToken);
  return await axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No share holders found"));
}

export async function getBrandProfileToExcel() {
  const url = BASE_URL + "brandInfoToExcel";
  // console.log("Admin token:", adminToken);
  return await axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No share holders found"));
}

export async function brandListWithActiveStatusWithDates(values) {
  const url =
    BASE_URL +
    `active-brand-list?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}&per_page=${values.pageActiveData}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  // console.log("values: ", values);
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function brandListWithUnderwritingStatusWithDates(values) {
  const url =
    BASE_URL +
    `underwriting-brand-list?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}&per_page=${values.pageActiveData}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  // console.log("values: ", values);
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function shortSellersListWithDates(values) {
  const url =
    BASE_URL +
    `short-sellers-list?search=&page=1&fromDate=${values.fromDate}&toDate=${values.toDate}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function fullValueBrandListWithDates(values) {
  const url =
    BASE_URL +
    `fullValueBrandList?search=&page=1&fromDate=${values.fromDate}&toDate=${values.toDate}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function cancelOpenOrders(values) {
  const url = BASE_URL + `cancel-open-order`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function openSellListWithDates(values) {
  const url =
    BASE_URL +
    `openSellTrades?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      // console.log(err, "error");
    });
}

export async function openBuyListWithDates(values) {
  const url =
    BASE_URL +
    `openBuyTrades?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function completedBuyListWithDates(values) {
  const url =
    BASE_URL +
    `completedBuyTrades?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function completedSellListWithDates(values) {
  const url =
    BASE_URL +
    `completedSellTrades?search=&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function sendEmailToAllBrands(values, authToken) {
  const url = BASE_URL + "sendEmailToAllBrands";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function sendNotificationToAllUsers(values, authToken) {
  const url = BASE_URL + "sendNotificationsToAllUsers";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function sendNotificationToAllBrands(values, authToken) {
  const url = BASE_URL + "sendNotificationsToAllBrands";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function totalplatformvalue({ search, pageno }) {
  const url = BASE_URL + `totalValuePlatform?search=${search}&page=${pageno}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function totalplatformvaluewithShowData(pageno, pageData) {
  const url =
    BASE_URL + `totalValuePlatform?page=${pageno}&per_page=${pageData}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function cardUnderwritingAmount() {
  const url = BASE_URL + `underwritingAmount`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getTradingHistory({ search, pageno }) {
  const url = BASE_URL + `trading-history?search=${search}&page=${pageno}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getTradingHistoryWithDates(values) {
  const url =
    BASE_URL +
    `trading-history?search=${values.search}&fromDate=${values.fromDate}&toDate=${values.toDate}&page=${values.datePageNo}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getTradingHistoryPDF(id) {
  const url = BASE_URL + `exportTransactionPdf/${id}`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Disposition": "attachment; filename=template.pdf",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.pdf");
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function updateAdminProfile(values, authToken) {
  const url = BASE_URL + "updateAdminProfile";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function getAdminDetails(authToken) {
  const url = BASE_URL + "getAdminProfile";
  const headers = {
    Authorization: authToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

// export async function walletbeforewithdraw(authToken) {
//   const url = BASE_URL + "check-user-wallet/${id}";
//   const headers = {
//     Authorization: authToken,
//   };

//   return await axios
//     .get(url, { headers: headers })
//     .then((res) => {
//       // console.log(res, "res");
//       return res.data;
//     })
//     .catch((err) => {
//       console.log(err, "error");
//     });
// }
export async function addCommission(values, authToken) {
  const url = BASE_URL + "admin/addapplembxcommission";
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return await axios
    .post(url, values, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

export async function SocialLOGIN(values) {
  const url = BASE_URL + "userSocialLogin";
  return await axios
    .post(url, values)
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
      console.log(err, "error");
    });
}

let token = localStorage.getItem("token");

export async function getUserWalletbyId(id) {
  let id1 = id.replace(/"/g, "");
  const url = BASE_URL + `user-wallet/${id1}`;
  const headers = {
    Authorization: token,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      console.log(err, "error");
    });
}
export async function getBrandWalletbyId(id) {
  let id1 = id.replace(/"/g, "");
  const url = BASE_URL + `brand-wallet/${id1}`;
  const headers = {
    Authorization: token,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      console.log(err, "error");
    });
}

//after all payment api this api run to withdraw money from userwalletbyID
export async function withdrawmoneytoUserWallet(
  id,
  amount,
  payoutBatchId,
  transactionId,
  paymentStatus
) {
  // console.log(id, "id");
  let id1 = id.replace(/"/g, "");

  const url = BASE_URL + `withdraw-user-wallet/${id1}`;
  const headers = {
    Authorization: token,
  };
  const data = {
    amount: amount,
    payoutBatchId: payoutBatchId,
    transactionId: transactionId,
    paymentStatus: paymentStatus,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "resDeductmoney");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function withdrawmoneytoBrandWallet(
  id,
  amount,
  payoutBatchId,
  transactionId,
  paymentStatus
) {
  // console.log(id, "id");
  let id1 = id.replace(/"/g, "");

  const url = BASE_URL + `withdraw-brand-wallet/${id1}`;
  const headers = {
    Authorization: token,
  };
  const data = {
    amount: amount,
    payoutBatchId: payoutBatchId,
    transactionId: transactionId,
    paymentStatus: paymentStatus,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "resDeductmoney");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

// export async function paypalPayout(payerId, amount) {

//   const url = BASE_URL + "paypalPayout";

//   const data = {
//     payerId: payerId,
//     amount: amount,
//   };
//   return await axios
//     .post(url,data)
//     .then((res) => {
//       // console.log(res, "res");
//       return res.data;
//     })
//     .catch((err) => {
//       console.log(err);
//       console.log(err, "error");
//     });
// }

// export async function Usewalletbeforwithdraw(id,amount) {
//   let id1= id.replace(/"/g, "")

//   const url = BASE_URL + `check-user-wallet/${id1}`;
//   const headers = {
//     Authorization: token,
//   };

//   const data = {
//     amount: amount,

//   };
//   return await axios
//     .post(url,data,{ headers: headers })
//     .then((res) => {
//       // console.log(res, "res");
//       return res.data;
//     })
//     .catch((err) => {
//       console.log(err, "error");
//     });
// }

export async function ChecuserWalletBeforeWithdraw(id, amount,userType,brandType) {
  let id1 = id.replace(/"/g, "");
let url = ""
if(userType?.replace(/"/g, "") === "auth"){
 url = BASE_URL + `check-user-wallet/${id1}`;
}
else if(brandType?.replace(/"/g, "") === "brand"){
  url = BASE_URL + `check-brand-wallet/${id1}`;
}
  const headers = {
    Authorization: token,
  };
  const data = {
    amount: amount,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function CheckbrandWalletBeforeWithdraw(id, amount) {
  let id1 = id.replace(/"/g, "");

  const url = BASE_URL + `check-brand-wallet/${id1}`;
  const headers = {
    Authorization: token,
  };
  const data = {
    amount: amount,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function InitialPaypalPayout(payerId, amount) {
  const url = BASE_URL + "paypalPayout";

  const data = {
    payerId: payerId,
    amount: amount,
  };
  // console.log(data)
  return await axios
    .post(url, data)
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function GetPayoutDetailsByBatchId(payoutBatchId) {
  const url = BASE_URL + `get-Payout-by-batchId/${payoutBatchId}`;

  return await axios
    .get(url)
    .then((res) => {
      // console.log(res, "payoutdetailsbyBatchId");
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      console.log(err, "error");
    });
}

//userpaypal account add
export async function addUserPaypalAccounttoWallet(id, payerId, email) {
  let id1 = id.replace(/"/g, "");

  const url = BASE_URL + `add-user-paypal-account/${id1}`;
  const headers = {
    Authorization: token,
  };
  const data = {
    payerId: payerId,
    email: email,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      // console.log(err, "error");
    });
}

//brand paypal account add

export async function addUbrandPaypalAccounttoWallet(id, payerId, email) {
  let id1 = id.replace(/"/g, "");

  const url = BASE_URL + `add-brand-paypal-account/${id1}`;
  const headers = {
    Authorization: token,
  };
  const data = {
    payerId: payerId,
    email: email,
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res.data;
    })
    .catch((err) => {
      // console.log(err, "error");
    });
}

//Withdraw Payment Request
export async function withdrawrequestpayment(userType,brandType, id, amount,bankemailId) {
  let id1 = id.replace(/"/g, "");
  let userType1 = userType?.replace(/"/g, "");
 
  let url = ""
  if(userType1 === "auth"){
  url = BASE_URL + `withdraPaymentRequest`;
  }
  else if(brandType?.replace(/"/g, "") === "brand"){
    url = BASE_URL + `brandwithdrawPaymentRequest`;
  }
  const headers = {
    Authorization: token,
  };
  // console.log("id", id1);
  const data = {
    withdrableamount: amount,
    bankingEmail: bankemailId,
    userId: id1,
    userType: userType1,
    brandType:brandType?.replace(/"/g, ""),
    paymentStatus: "Processing",
  };
  return await axios
    .post(url, data, { headers: headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function Getlistingofwithdrawalrequest(pageno,searchData,fromDt,endDt,paymentsStatus,perPage) {
  // console.log("values", values)
  // const url = BASE_URL + "getWithdraPaymentRequest";
  // console.log("perPage", perPage)
  const url =
    BASE_URL +
    `getWithdraPaymentRequest?page=${pageno}&search=${searchData}&startDate=${fromDt}&endDate=${endDt}&paymentStatus=${paymentsStatus}&per_page=${perPage}`;
  const headers = {
    "Content-Type": "application/json",

    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log("API Response:", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("API Error:", err);
    });
}

//update payment status

export async function updatePaymentStatus(id1, selectedStatus,comment) {
  let id2 = id1.replace(/"/g, "");
  const url = BASE_URL + `updateWithdrawalStatus`;
  const data = {
    userId: id2,
    paymentStatus: selectedStatus,
    comments: comment,
  };
  const headers = {
    Authorization: token,
  };
  return await axios
    .patch(url, data, { headers: headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      console.log(err, "error");
    });
}
//excel 

export async function getWithdrawalRequestToExcel(fromDt,endDt,paymentsStatus) {
  const url = BASE_URL + `withdrwalRequestToExcel?startDate=${fromDt}&endDate=${endDt}&paymentStatus=${paymentsStatus}`;
  return await axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No Withdrawal Request found"));
}

//get available balance for withdraw

export async function getwithdrawalBalance(id,userType) {
  let id1 = id.replace(/"/g, "");
  // console.log(userType)

  let url = ""
  if(userType?.replace(/"/g, "") === "auth"){
  url = BASE_URL + `user-wallet/${id1}`;
  }
  else{
    url = BASE_URL + `brand-wallet/${id1}`;
  }
  const headers = {
    Authorization: token,
  };
  return await axios
    .get(url,{ headers: headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}


export async function requestCount() {
  const url = BASE_URL + `requestCount`;
  const headers = {
    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log(res, "res");
      return res;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}


export async function getUserDetails(id,userType,brandType) {
  let id1 = id.replace(/"/g, "");

  // console.log(userType)
  let url = ""
  if(userType?.replace(/"/g, "") === "auth"){
  url = BASE_URL + `getUserProfile/${id1}`;
  }
  else if(brandType?.replace(/"/g, "") === "brand"){
    url = BASE_URL + `getBrandProfile/${id1}`;
  }
  return await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function updateBankEmail(id,bankemail,userType,brandType) {
  // console.log(userType)
  let id1 = id.replace(/"/g, "");
  let url = ""
  if(userType?.replace(/"/g, "") === "auth"){
  url = BASE_URL + `editBankEmailId/${id1}`;
  }
  else if(brandType?.replace(/"/g, "") === "brand"){
    url = BASE_URL + `editbrandBankEmailId/${id1}`;
  }
  const data = {
    updatedemailid: bankemail,
  };
  return await axios
    .patch(url,data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err, "error");
    });
}

export async function Getlistingofbrandwithdrawalrequest(pageno,searchData,fromDt,endDt,paymentsStatus,perPage) {
  // console.log("values", values)
  // const url = BASE_URL + "getWithdraPaymentRequest";
  // console.log("perPage", perPage)
  const url =
    BASE_URL +
    `getBrandWithdrawPaymentRequest?page=${pageno}&search=${searchData}&startDate=${fromDt}&endDate=${endDt}&paymentStatus=${paymentsStatus}&per_page=${perPage}`;
  const headers = {
    "Content-Type": "application/json",

    Authorization: adminToken,
  };
  return await axios
    .get(url, { headers: headers })
    .then((res) => {
      // console.log("API Response:", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("API Error:", err);
    });
}


export async function updateBrandPaymentStatus(id1, selectedStatus,comment) {
  let id2 = id1.replace(/"/g, "");
  const url = BASE_URL + `updateBrandWithdrawalStatus`;
  const data = {
    userId: id2,
    paymentStatus: selectedStatus,
    comments: comment,
  };
  const headers = {
    Authorization: token,
  };
  return await axios
    .patch(url, data, { headers: headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      console.log(err, "error");
    });
}

export async function getBrandWithdrawalRequestToExcel(fromDt,endDt,paymentsStatus) {
  const url = BASE_URL + `brandwithdrwalRequestToExcel?startDate=${fromDt}&endDate=${endDt}&paymentStatus=${paymentsStatus}`;
  return await axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Disposition": "attachment; filename=template.xlsx",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: adminToken,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => toast.error("No Withdrawal Request found"));
}
import React, { useState, useEffect, useRef } from "react";
import Logo from "../../Assets/logo.png";
import user from "../../Assets/Ellipse.png";
import message from "../../Assets/message.png";
import bell from "../../Assets/bell.png";
import LogoutIcon from "../../Assets/Vector.svg";
import { Routes, Route, Link } from "react-router-dom";
import {
  FaBell,
  FaFileMedicalAlt,
  FaKey,
  FaMobileAlt,
  FaSignInAlt,
  FaEdit,
  FaCog
} from "react-icons/fa";
// import "./style.scss";
import { Avatar, Badge, Modal, Image } from "antd";
import { toast } from "react-toastify";



const Headers = () => {
  const myRef = useRef();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("resObj");
    localStorage.removeItem("userType");
    localStorage.removeItem("brandType");
    localStorage.removeItem("code");
    localStorage.removeItem("paypalpayerid");


    

    window.location.reload();
    toast.success("You have been logged out");
  };

  return (
    <>
      <section className="header-section">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            {/* <a class="navbar-brand" href="#">
              <img src={Logo} />
            </a> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarSupportedContent"
            >
              <div className="d-flex">
                <div>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <div className="profile-container">
                    </div>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "10%",
                    marginLeft: 48,
                    alignItems: "center",
                  }}
                ></div>
              </div>

              <div
                className="notifi_box"
                ref={myRef}
              >
                <li className="nav-item">
                  <a className="nav-link notifi_icon" onClick={logout}>
                    <span
                      className="left"
                    >
                      <FaSignInAlt />
                    </span>{" "}
                  </a>
                </li>
              </div>
            </div>
            <div
              className="logoutbutton" 
            >
            </div>
          </div>

          <div
            id="sidebar_wrapper"
          >
            <div
              className="sidebar_box"
             
            >
            </div>
          </div>

          <div
       
            id="sidebar_wrapper"
          >
            <div
              className="sidebar_box"
        
            >
              <ul className="sidebar_menu">
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
export default Headers;


import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../../Assets/MBXlogo.svg";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { UserforgetPassword } from "../../utils/Api";
import { toast } from "react-toastify";

function ForgotPasswordUser() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };

    const res = await UserforgetPassword(data);
    if (res?.data?.status === 200) {
      toast.success(res?.data.message);
      const userId = res?.data?.Data.id; 
      navigate(`/verifyotp/${userId}`);
    } else {
      toast.error(res?.data.message);
    }
  };

  const handleEmailData = (v) => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (v === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(v)) {
      setEmailError("Please enter valid email");
    } else {
      setEmail(v);
      setEmailError("");
    }
  };

  return (
    <>
    <section className="main-wrapper-login">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="Login-wrapper">
              <div className="login-wrap-logo text-center">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="forgt-txt-head text-center">
                <h1>Forgot Password</h1>
                <p className="forgot_para">
                  Please enter your email address. A link will be sent on the
                  email for password recovery.
                </p>
              </div>
              <div className="loginbody--form">
                <Form>
                  <Row>
                    <Col xs="12">
                      <div className="form-group">
                        <Form.Control
                          type="email"
                          className="input-login-control mb-4"
                          placeholder="Email Address"
                          onChange={(e) => {
                            handleEmailData(e.target.value);
                          }}
                        />
                        {emailError && (
                          <div className="invalid-feedback">{emailError}</div>
                        )}
               
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="login-login">
                        <button
                          type="submit"
                          className="btn btn-login"
                          onClick={(e) => {
                            handleForgetPassword(e);
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
export default ForgotPasswordUser;


import { Avatar, Button } from "antd";
import React from "react";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import CommonTable from "../../common/Table";
import "./style.scss";

const Refund_request = () => {
  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar src="https://joeschmoe.io/api/v1/random" /> {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },

    {
      title: "Refund Amount",
      dataIndex: "RefundAmount",
      key: "RefundAmount",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="action_button">
          <Button style={{ backgroundColor: "#00CC52", borderRadius: "8px" }}>
            Approve
          </Button>
          <Button style={{ backgroundColor: "#E10000", borderRadius: "8px" }}>
            Decline
          </Button>
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "laws_of_ux",
      Date: "April 12 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "2",
      name: "sales&marketing",
      Date: "April 10 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "3",
      name: "design.sprint",
      Date: "April 8 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "4",
      name: "laws_of_ux",
      Date: "April 12 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "5",
      name: "sales&marketing",
      Date: "April 10 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "6",
      name: "design.sprint",
      Date: "April 8 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "7",
      name: "laws_of_ux",
      Date: "April 12 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "8",
      name: "sales&marketing",
      Date: "April 10 2022",
      RefundAmount: "$56,652",
      UnderwritingValues: "Pending",
      FinalValues: "$56,652",
    },
    {
      key: "9",
      name: "design.sprint",
      Date: "April 8 2022",
      RefundAmount: "$56,652",
      Status: "Pending",
      FinalValues: "$56,652",
    },
  ];

  return (
    <div className="refund_container">
      <div className="refund_label">
        <h4>Refund_request</h4>
        <CommanCalender />
      </div>
      <div className="refund_table">
        <CommonTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default Refund_request;

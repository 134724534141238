import React, { useEffect, useState } from "react";
import { Avatar, Pagination } from "antd";
import CommonTable from "../../common/Table";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";

const ModalUse = ({ details, totalRecords, onSubmit }) => {
  const onChange = async (e) => {
    onSubmit(e);
  };

  const columns = [
    {
      title: "Brand User Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "No Of Share",
      dataIndex: "share",
      key: "share",
      width: "25%",
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "cost",
      width: "25%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "25%",
    },
  ];

  const data = details?.holdingsData?.Data?.map((key, index) => {
    return {
      key: index,
      name: key?.brandId?.brandUserName,
      share: key?.numberOfShare,
      cost: key?.totalCost,
      date: moment(key.createdAt).format("LL"),
    };
  });

  return (
    <div className="container">
      <div className="heading">
        <div
          style={{
            marginLeft: "185px",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <b>{details?.name}</b>
        </div>
      </div>
      <div className="contentTable box-body">
        <CommonTable
          columns={columns}
          data={data}
          onChange={(e) => onChange(e)}
          total={totalRecords}
        />
      </div>
    </div>
  );
};

export default ModalUse;

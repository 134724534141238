import React, { useState, useEffect, useRef } from "react";
import Logo from "../../Assets/logo.png";
import user from "../../Assets/Ellipse.png";
import message from "../../Assets/message.png";
import bell from "../../Assets/bell.png";
import LogoutIcon from "../../Assets/Vector.svg";
import { Routes, Route, Link } from "react-router-dom";
import { addCommission, getMBX } from "../utils/Api";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import SettingModal from "./SettingModal";


// import Modal from 'react-modal';

import {
  FaBell,
  FaFileMedicalAlt,
  FaKey,
  FaMobileAlt,
  FaSignInAlt,
  FaEdit,
  FaCog

} from "react-icons/fa";
import "./style.scss";
import { Avatar, Badge, Modal, Image } from "antd";
import { toast } from "react-toastify";
import { getAdminNotifications, getAdminDetails } from "../utils/Api";
import ModalUse from "./ModalUse";
import ProfileUpdate from "./ProfileUpdate";
import EmailModal from "./ModalEmail";
import BrandEmailModal from "./ModalBrandEmail";
import UserNotificationModal from "./ModalUserNotification";
import BrandNotificationModal from "./ModalBrandNotification";
import { BASE_URL } from "../utils/Api";
import Setting from "../Pages/Settings";

const Header = () => {
  const [show, setshow] = useState(true);
  let [menu, setMenu] = useState(false);
  let [notifica, setNotifica] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState();
  const [isAModalVisible, setIsAModalVisible] = useState(false);
  const [isBrandModalVisible, setIsBrandModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isBrandNotModalVisible, setIsBrandNotModalVisible] = useState(false);
  const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);


  const [modalTotalRecord, setModalTotalRecord] = useState();
  const [modalPageNo, setModalPageNo] = useState("1");
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  //profile update
  const [getProImage, setGetProImage] = useState("");
  const [getProName, setGetProName] = useState("");
  const [getProEmail, setGetProEmail] = useState("");

  const modalRef = useRef();

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setNotifica(false);
    }
  };

  const handleClickOutsideMenu = (e) => {
    if (!modalRef.current.contains(e.target)) {
      setMenu(false);
    }
  };

  const handleClickInside = () => setNotifica(true);

  const handleClickInsideMenu = () => setMenu(true);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideMenu);
  }, []);

  useEffect(() => {
    handleMenuClick();
  }, []);

  useEffect(() => {
    getAdminNotifications(modalPageNo)
      .then((res) => {
        const data = {
          name: "My Notification",
          type: 1,
          holdingsData: res,
        };
        setModalData(data);
        setModalTotalRecord(data?.holdingsData?.totalRecords);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [modalPageNo]);

  const logout = () => {
    localStorage.removeItem("token");
    // sessionStorage.removeItem('set')
    localStorage.removeItem("resObj");

    window.location.reload();
    toast.success("You have been logged out");
  };

  const handleNotification = () => {
    getAdminNotifications();
  };

  const adminToken = localStorage.getItem("token");
  const getAdminData = async () => {
    const res = await getAdminDetails(adminToken);
    if (res?.status === 200) {
      setGetProName(res?.data?.name);
      setGetProEmail(res?.data?.email);
      setGetProImage(res?.data?.profileImage);
    } else {
      toast.warning("Somting went wrong");
    }
  };

  useEffect(() => {
    getAdminData();
  }, []);

  const handleMenuClick = async () => {
    const res = await getAdminNotifications(modalPageNo);
    if (res?.status === 200) {
      const data = {
        name: "My Notification",
        type: 1,
        holdingsData: res,
      };
      setModalData(data);
      setModalTotalRecord(data?.holdingsData?.totalRecords);
    } else {
      toast.warning("Somting went wrong");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setModalPageNo("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsProfileModalVisible(false);
    setModalPageNo("");
  };

  //profile
  const showProfileModal = () => {
    setIsProfileModalVisible(true);
  };

  const handleProfileOk = () => {
    setIsProfileModalVisible(false);
  };

  const handleProfileCancel = () => {
    setIsProfileModalVisible(false);
  };

  const showSettingModal = () => {
    setIsSettingModalVisible(true);
  };
  const handleSettingCancel = () => {
    setIsSettingModalVisible(false);
  };

  

  //

  const showAModal = () => {
    setIsAModalVisible(true);
  };

  const handleAOk = () => {
    setIsAModalVisible(false);
  };

  const handleACancel = () => {
    setIsAModalVisible(false);
  };
  //
  const showBrandModal = () => {
    setIsBrandModalVisible(true);
  };

  const handleBrandOk = () => {
    setIsBrandModalVisible(false);
  };

  const handleBrandCancel = () => {
    setIsBrandModalVisible(false);
  };

  const showUserModal = () => {
    setIsUserModalVisible(true);
  };

  const handleUserOk = () => {
    setIsUserModalVisible(false);
  };

  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };

  const showBrandNotModal = () => {
    setIsBrandNotModalVisible(true);
  };

  const handleBrandNotOk = () => {
    setIsBrandNotModalVisible(false);
  };

  const handleBrandNotCancel = () => {
    setIsBrandNotModalVisible(false);
  };

  //

  const getPageData = (data) => {
    // setModalPageNo(data);
    setModalPageNo(data);
  };

  return (
    <>
      <section className="header-section">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            {/* <a class="navbar-brand" href="#">
              <img src={Logo} />
            </a> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarSupportedContent"
            >
              <div className="d-flex">
                <div>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <div className="profile-container">
                      <div className="profile-image">
                        {/* <img src={`http://54.190.192.105:5048/images/${getProImage}`} type="button" onClick={showProfileModal}/> */}
                        <Avatar
                          src={
                            <Image
                              src={BASE_URL + `/images/${getProImage}`}
                            />
                          }
                        />
                      </div>
                      <div>
                        <div className="profile-user">{getProName}</div>

                        <div className="profile-role">Administrator</div>
                      </div>
                      <button
                        onClick={showProfileModal}
                        style={{
                          marginTop: "15px",
                          marginLeft: "5px",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <FaEdit />
                      </button>
                    </div>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "10%",
                    marginLeft: 48,
                    alignItems: "center",
                  }}
                ></div>
              </div>

              <div
                className="notifi_box"
                ref={myRef}
                onClick={handleClickInside}
              >
                <li className="nav-item">
                  <a className="nav-link notifi_icon">
                    <span
                      className="left"
                      onClick={(e) => {
                        setNotifica((notifica = !notifica));
                        // handleMenuClick();
                      }}
                    >
                      <FaBell />
                    </span>{" "}
                    <span className="alert_message"></span>
                  </a>
                </li>
              </div>
            </div>
            <div
              className="logoutbutton"
              ref={modalRef}
              onClick={handleClickInsideMenu}
            >
              <li className="nav-item">
                <a
                  className={
                    "nav-link hamburger" + (menu === true ? " active" : "")
                  }
                  onClick={(e) => {
                    setMenu((menu = !menu));
                  }}
                  id="sidebar-toggle"
                  type="button"
                  data-bs-toggle="toggle"
                  aria-expanded="false"
                >
                  <span></span>
                </a>
              </li>
            </div>
          </div>

          <div
            className={"right_sidebar" + (menu === true ? " add_sidebar" : "")}
            id="sidebar_wrapper"
          >
            <div
              className="sidebar_box"
              ref={modalRef}
              onClick={handleClickInsideMenu}
            >
              <ul className="sidebar_menu">
                <li>
                  <Link className="sidebar-item" to="/changepassword">
                    <span className="left">
                      <FaKey />
                    </span>
                    Change Password
                  </Link>
                </li>

                <li>

                  {/* <Link className="sidebar-item" to="/setting" >
                    <span className="left">
                      <FaCog />
                    </span>
                    Settings
                  </Link> */}

                  <a className="sidebar-item" type="button" onClick={showSettingModal}>
                    <span className="left">
                    <FaCog />
                    </span>
                    Settings
                  </a>



                </li>
                <li>
                  <a className="sidebar-item" type="button" onClick={logout}>
                    <span className="left">
                      <FaSignInAlt />
                    </span>
                    Log Out
                  </a>
                </li>

              </ul>
            </div>
          </div>

          <div
            className={
              "right_sidebar" + (notifica === true ? " add_sidebar" : "")
            }
            id="sidebar_wrapper"
          >
            <div
              className="sidebar_box"
              ref={myRef}
              onClick={(e) => {
                handleClickInside();
                handleMenuClick();
              }}
            >
              <ul className="sidebar_menu">
                <li>
                  <a className="sidebar-item" type="button" onClick={showModal}>
                    <span className="left">
                      <FaBell />
                    </span>
                    My Notification
                  </a>
                </li>
                <li>
                  <a
                    className="sidebar-item"
                    type="button"
                    onClick={showAModal}
                  >
                    <span className="left">
                      <FaMobileAlt />
                    </span>
                    Send Email to Users
                  </a>
                </li>
                <li>
                  <a
                    className="sidebar-item"
                    type="button"
                    onClick={showBrandModal}
                  >
                    <span className="left">
                      <FaMobileAlt />
                    </span>
                    Send Email to Brands
                  </a>
                </li>
                <li>
                  <a
                    className="sidebar-item"
                    type="button"
                    onClick={showUserModal}
                  >
                    <span className="left">
                      <FaMobileAlt />
                    </span>
                    Send Notification to Users
                  </a>
                </li>
                <li>
                  <a
                    className="sidebar-item"
                    type="button"
                    onClick={showBrandNotModal}
                  >
                    <span className="left">
                      <FaMobileAlt />
                    </span>
                    Send Notification to Brands
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="user-profile-modal"
          >
            <ModalUse
              details={modalData}
              totalRecords={modalTotalRecord}
              onSubmit={getPageData}
            />
          </Modal>

          <Modal
            visible={isProfileModalVisible}
            onOk={handleProfileOk}
            onCancel={handleProfileCancel}
            className="user-profile-modal"
          >
            <ProfileUpdate
              proImage={getProImage}
              proName={getProName}
              proEmail={getProEmail}
            />
          </Modal>

          <Modal
            visible={isAModalVisible}
            onOk={handleAOk}
            onCancel={handleACancel}
          >
            <EmailModal />
          </Modal>
          <Modal
            visible={isBrandModalVisible}
            onOk={handleBrandOk}
            onCancel={handleBrandCancel}
          >
            <BrandEmailModal />
          </Modal>

          <Modal
            visible={isSettingModalVisible}
            onOk={handleBrandOk}
            onCancel={handleSettingCancel}
          >
            <SettingModal />

          </Modal>
          <Modal
            visible={isUserModalVisible}
            onOk={handleUserOk}
            onCancel={handleUserCancel}
          >
            <UserNotificationModal />
          </Modal>
          <Modal
            visible={isBrandNotModalVisible}
            onOk={handleBrandNotOk}
            onCancel={handleBrandNotCancel}
          >
            <BrandNotificationModal />
          </Modal>
          {/* <Modal
            visible={isSettingModalVisible}
            onOk={handleBrandNotOk}
            onCancel={handleBrandNotCancel}
          >
            <Setting />
          </Modal> */}
        </nav>
      </section>
    </>
  );
};

export default Header;

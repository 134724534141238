import React, { useState } from "react";
import "./style.scss";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import { toast } from "react-toastify";
import { brandUnderwritingListWithDates } from "../../utils/Api";

const CommanCalender = () => {
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  const handleDate = async () => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
    };

    const response = await brandUnderwritingListWithDates(requestedData);

    if (response?.status === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <div className="mainCalender">
        <div className="date_pick_box">
          <label>
            <b>Select Date</b>
          </label>
          <label className="input_label">From</label>
          <DatePicker className="dateInput" onChange={onChangeStart} />
          <label className="input_label">To</label>
          <DatePicker className="dateInput" onChange={onChangeEnd} />
          <button className="buttonMain" onClick={handleDate}>
            View
          </button>
        </div>
      </div>
    </>
  );
};
export default CommanCalender;

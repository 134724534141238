// import React, { useState, useEffect } from "react";
// import Logo from "../../Assets/logo.png";
// import "antd/dist/antd.css";
// // import './index.css';
// import { Layout, Menu, Breadcrumb } from "antd";

// import user from "../../Assets/Group.svg";
// import Refund from "../../Assets/refund.svg";
// import DataAnalytics from "../../Assets/Frame.svg";
// // import StaticIcon from "../../Assets/staticpage.svg"
// // import { ReactComponent as UpArrow } from "../../Assets/UpArrow.svg";
// // import { ReactComponent as downArrow } from "../../Assets/downArrow.svg";
// import "./style.scss";
// import CommonMenu from "../common/menu";
// import AddManageUser from "../Pages/AddManageUser";
// import AddManageBrand from "../Pages/AddManageBrand";
// import Header from "../Header/Header";
// import DataNetProcess from "../Pages/Data_Net_Process";
// import UserProfile from "../Pages/UserProfile";
// import BrandProfile from "../Pages/BrandProfile";
// import Current_brand_value from "../Pages/Current_Brand_Value";
// import Total_value_of_platforms from "../Pages/Total_value_of_platform";
// import Brand_Distribution_list from "../Pages/Brand_Distribution_List";
// import Transaction_History from "../Pages/Transaction_History";
// import Brand_listing from "../Pages/Brand_Listing";
// import Short_seller from "../Pages/Short Sellers";
// import Full_Value_Brands from "../Pages/Full Value Brands";
// import Refund_request from "../Pages/Refund Request";
// import Static_page from "../Pages/StaticPage";
// import ChangePass from "../Pages/ChangePassword";
// import About from "../Pages/About";
// import CoreValues from "../Pages/CoreValues";
// import ReadBuyingSelling from "../Pages/ReadBuyingSelling";
// import WhyListYourBrand from "../Pages/WhyListYourBrand";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";
// import Support from "../Pages/Support";
// import UserAgreement from "../Pages/UserAgreement";
// import OpenSell_Trades from "../Pages/OpenSell_Trades";
// import CompletedSell_Trades from "../Pages/CompletedSell_Trades";
// import OpenBuy_Trades from "../Pages/OpenBuy_Trades";
// import CompletedBuy_Trades from "../Pages/CompletedBuy_Trades";
// import UnsettledBrand from "../Pages/UnsettledBrand";
// import WithdrawRequest from "../Pages/WithdrawRequest";


// const { Content, Sider } = Layout;

// const sideBarOptions = [
//   {
//     key: "1",
//     icon: <img src={user} />,
//     title: "User Management",
//     menuItem: [
//       {
//         key: "1",
//         name: "Manage User",
//         routepath: "/",
//       },
//       {
//         key: "2",
//         name: "Manage Brand ",
//         routepath: "/managebrand",
//       },
//     ],
//   },
//   {
//     key: "2",
//     icon: <img src={DataAnalytics} />,
//     title: "Data Analytics",
//     menuItem: [
//       {
//         key: "1",
//         name: "Net Process Per User",
//         routepath: "/datanetprocess",
//       },
//       {
//         key: "2",
//         name: "Underwriting ProceedsPer Brand ",
//         routepath: "/datanetprocess",
//       },
//       {
//         key: "3",
//         name: "User Profiles ",
//         routepath: "/userProfile",
//       },
//       {
//         key: "14",
//         name: "Brand Profiles ",
//         routepath: "/brandProfile",
//       },
//       {
//         key: "4",
//         name: "Current Brand Value",
//         routepath: "/currentbrandvalue",
//       },
//       {
//         key: "5",
//         name: "Total Value of Platform",
//         routepath: "/totalvalueofplatform",
//       },
//       {
//         key: "6",
//         name: "Brand Distribution List",
//         routepath: "/branddistributionlist",
//       },
//       {
//         key: "7",
//         name: "Trading History",
//         routepath: "/transactionhistory",
//       },
//     ],
//   },
//   {
//     key: "7",
//     icon: <img src={user} />,
//     title: "Underwriting Admin",
//     menuItem: [
//       {
//         key: "1",
//         name: "Brand Listing",
//         routepath: "/brandlist",
//       },
//       {
//         key: "2",
//         name: "Short Sellers ",
//         routepath: "/shortseller",
//       },
//       {
//         key: "3",
//         name: "Full Value Brands",
//         routepath: "/fullvaluebrand",
//       },
//     ],
//   },
//   {
//     key: "4",
//     icon: <img src={Refund} />,
//     title: "Refund Requests",
//     menuItem: [],
//   },
//   {
//     key: "5",
//     icon: <img src={Refund} />,
//     title: "Static Pages",
//     menuItem: [
//       {
//         key: "1",
//         name: "About",
//         routepath: "/about",
//       },
//       {
//         key: "2",
//         name: "Core Values",
//         routepath: "/coreValues",
//       },
//       {
//         key: "3",
//         name: "Read Buying & Selling",
//         routepath: "/readbuyingselling",
//       },
//       {
//         key: "4",
//         name: "Read Why List Your Brand",
//         routepath: "/whylistyourbrand",
//       },
//       {
//         key: "5",
//         name: "Privacy Policy",
//         routepath: "/privacypolicy",
//       },
//       {
//         key: "6",
//         name: "Support",
//         routepath: "/support",
//       },
//       {
//         key: "7",
//         name: "User Agreement",
//         routepath: "/useragreement",
//       },
//     ],
//   },
//   //added
//   {
//     key: "6",
//     icon: <img src={user} />,
//     title: "Manage Trades",
//     menuItem: [
//       {
//         key: "1",
//         name: "Open Sell Trades",
//         routepath: "/opensell",
//       },
//       {
//         key: "2",
//         name: "Completed Sell Trades",
//         routepath: "/completedsell",
//       },
//       {
//         key: "3",
//         name: "Open Buy Trades",
//         routepath: "/openbuy",
//       },
//       {
//         key: "4",
//         name: "Completed Buy Trades",
//         routepath: "/completedbuy",
//       },
//     ],
//   },
//   {
//     key: "8",
//     icon: <img src={user} />,
//     title: "Brand Payments",
//     menuItem: [
//       {
//         key: "1",
//         name: "Unsettled Brand List",
//         routepath: "/unsettledbrandlist",
//       },
//     ],
//   },
//   // {
//   //   key: "9",
//   //   icon: <img src={user} />,
//   //   title: "Withdraw Request",
//   //   menuItem: [],
//   // },
//   {
//     key: "9",
//     icon: <img src={user} />,
//     title: "Withdraw Request",
//     menuItem: [
//       {
//         key: "1",
//         name: "Withdraw Request",
//         routepath: "/withdrawpaymentrequest",
//       },
//     ],
//   },
//   //end
// ];

// const Sidebar = () => {
//   const [tab, settab] = useState(1);

//   // function getItem(label, key, icon, children, type) {
//   //   return {
//   //     key,
//   //     icon,
//   //     children,
//   //     label,
//   //     type,
//   //   };
//   // }

//   const href = window.location.pathname;
//   useEffect(() => {
//     pathhref(href);
//   }, [href]);

//   const pathhref = (path) => {
//     switch (path) {
//       case "/manageuser":
//         return settab(1);
//       case "/managebrand":
//         return settab(2);
//       case "/datanetprocess":
//         return settab(3);
//       case "/userProfile":
//         return settab(4);
//       case "/currentbrandvalue":
//         return settab(5);
//       case "/totalvalueofplatform":
//         return settab(6);
//       case "/branddistributionlist":
//         return settab(7);
//       case "/transactionhistory":
//         return settab(8);
//       case "/brandlist":
//         return settab(9);
//       case "/shortseller":
//         return settab(10);
//       case "/fullvaluebrand":
//         return settab(11);
//       case "/refundrequest":
//         return settab(12);
//       case "/about":
//         return settab(13);
//       // case "/changepassword":
//       //   return settab(14);
//       case "/brandProfile":
//         return settab(14);
//       case "/coreValues":
//         return settab(15);
//       case "/readbuyingselling":
//         return settab(16);
//       case "/whylistyourbrand":
//         return settab(17);
//       case "/privacypolicy":
//         return settab(18);
//       case "/support":
//         return settab(19);
//       case "/useragreement":
//         return settab(20);
//       case "/opensell":
//         return settab(21);
//       case "/completedsell":
//         return settab(22);
//       case "/openbuy":
//         return settab(23);
//       case "/completedbuy":
//         return settab(24);
//       case "/unsettledbrandlist":
//         return settab(25);
//       case "/withdrawpaymentrequest":
//         return settab(31);
        
//       default:
//         return settab(1);
//     }
//   };

//   // const setURL = (path)=>{
//   //   switch(path) {
//   //     case "1":return "/"
//   //     case "2":return "/managebrand"
//   //     case "/datanetprocess":return settab(3)
//   //     case "/userProfile":return settab(4)
//   //     case "/currentbrandvalue":return settab(5)
//   //     case "/totalvalueofplatform":return settab(6)
//   //     case "/branddistributionlist":return settab(7)
//   //     case "/transactionhistory":return settab(8)
//   //     case "/brandlist":return settab(9)
//   //     case "/shortseller":return settab(10)
//   //     case "/fullvaluebrand":return settab(11)
//   //     default: return settab(1)

//   //   }
//   // };

//   const renderComponent = (tab) => {
//     switch (tab) {
//       case 1:
//         return <AddManageUser />;
//       case 2:
//         return <AddManageBrand />;
//       case 3:
//         return <DataNetProcess />;
//       // case 3:
//       //   return <DataNetProcess />;
//       case 4:
//         return <UserProfile />;
//       case 5:
//         return <Current_brand_value />;
//       case 6:
//         return <Total_value_of_platforms />;
//       case 7:
//         return <Brand_Distribution_list />;
//       case 8:
//         return <Transaction_History />;
//       case 9:
//         return <Brand_listing />;
//       case 10:
//         return <Short_seller />;
//       case 11:
//         return <Full_Value_Brands />;
//       case 12:
//         return <Refund_request />;
//       case 13:
//         return <About />;
//       // case 14:
//       //   return <ChangePass />;
//       case 14:
//         return <BrandProfile />;
//       case 15:
//         return <CoreValues />;
//       case 16:
//         return <ReadBuyingSelling />;
//       case 17:
//         return <WhyListYourBrand />;
//       case 18:
//         return <PrivacyPolicy />;
//       case 19:
//         return <Support />;
//       case 20:
//         return <UserAgreement />;
//       case 21:
//         return <OpenSell_Trades />;
//       case 22:
//         return <CompletedSell_Trades />;
//       case 23:
//         return <OpenBuy_Trades />;
//       case 24:
//         return <CompletedBuy_Trades />;
//         case 25:
//           return <UnsettledBrand />;
//         case 31:
//         return <WithdrawRequest />;
  

//       default:
//         return <AddManageUser />;
//     }
//   };

//   return (
//     <div className="sidebar-container">
//       <Layout>
//         {/* <Header /> */}
//       </Layout>
//       <Layout>
//         <Sider width={300} className="site-layout-background">
//           <div className="sidebar-section-box">
//             <a className="navbar-brand" href="#">
//                 <img src={Logo} />
//             </a>
//             <div className="sidebar-menu-section">
//               <CommonMenu
//                 tab={tab}
//                 settab={settab}
//                 menuItem={sideBarOptions}
//                 pathhref={href}
//               />
//             </div>
//           </div>
//         </Sider>
//         <Layout className="content-layout">
//           <Header />
//           {renderComponent(tab)}
//         </Layout>
//       </Layout>
//     </div>
//   );
// };

// export default Sidebar;



import React, { useState, useEffect } from "react";
import Logo from "../../Assets/logo.png";
import "antd/dist/antd.css";
// import './index.css';
import { Layout, Menu, Breadcrumb } from "antd";

import user from "../../Assets/Group.svg";
import Refund from "../../Assets/refund.svg";
import DataAnalytics from "../../Assets/Frame.svg";
// import StaticIcon from "../../Assets/staticpage.svg"
// import { ReactComponent as UpArrow } from "../../Assets/UpArrow.svg";
// import { ReactComponent as downArrow } from "../../Assets/downArrow.svg";
import "./style.scss";
import CommonMenu from "../common/menu";
import AddManageUser from "../Pages/AddManageUser";
import AddManageBrand from "../Pages/AddManageBrand";
import Header from "../Header/Header";
import DataNetProcess from "../Pages/Data_Net_Process";
import UserProfile from "../Pages/UserProfile";
import BrandProfile from "../Pages/BrandProfile";
import Current_brand_value from "../Pages/Current_Brand_Value";
import Total_value_of_platforms from "../Pages/Total_value_of_platform";
import Brand_Distribution_list from "../Pages/Brand_Distribution_List";
import Transaction_History from "../Pages/Transaction_History";
import Brand_listing from "../Pages/Brand_Listing";
import Short_seller from "../Pages/Short Sellers";
import Full_Value_Brands from "../Pages/Full Value Brands";
import Refund_request from "../Pages/Refund Request";
import Static_page from "../Pages/StaticPage";
import ChangePass from "../Pages/ChangePassword";
import About from "../Pages/About";
import CoreValues from "../Pages/CoreValues";
import ReadBuyingSelling from "../Pages/ReadBuyingSelling";
import WhyListYourBrand from "../Pages/WhyListYourBrand";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Support from "../Pages/Support";
import UserAgreement from "../Pages/UserAgreement";
import OpenSell_Trades from "../Pages/OpenSell_Trades";
import CompletedSell_Trades from "../Pages/CompletedSell_Trades";
import OpenBuy_Trades from "../Pages/OpenBuy_Trades";
import CompletedBuy_Trades from "../Pages/CompletedBuy_Trades";
import UnsettledBrand from "../Pages/UnsettledBrand";
import WithdrawRequest from "../Pages/WithdrawRequest";
import { Link } from "react-router-dom";
import BrandWithdrawRequest from "../Pages/Brand_withdrawal_List";

const { Content, Sider } = Layout;

const sideBarOptions = [
  {
    key: "1",
    icon: <img src={user} />,
    title: "User Management",
    menuItem: [
      {
        key: "1",
        name: "Manage User",
        routepath: "/",
      },
      {
        key: "2",
        name: "Manage Brand ",
        routepath: "/managebrand",
      },
    ],
  },
  {
    key: "2",
    icon: <img src={DataAnalytics} />,
    title: "Data Analytics",
    menuItem: [
      {
        key: "1",
        name: "Net Process Per User",
        routepath: "/datanetprocess",
      },
      {
        key: "2",
        name: "Underwriting ProceedsPer Brand ",
        routepath: "/datanetprocess",
      },
      {
        key: "3",
        name: "User Profiles ",
        routepath: "/userProfile",
      },
      {
        key: "14",
        name: "Brand Profiles ",
        routepath: "/brandProfile",
      },
      {
        key: "4",
        name: "Current Brand Value",
        routepath: "/currentbrandvalue",
      },
      {
        key: "5",
        name: "Total Value of Platform",
        routepath: "/totalvalueofplatform",
      },
      {
        key: "6",
        name: "Brand Distribution List",
        routepath: "/branddistributionlist",
      },
      {
        key: "7",
        name: "Trading History",
        routepath: "/transactionhistory",
      },
    ],
  },
  {
    key: "7",
    icon: <img src={user} />,
    title: "Underwriting Admin",
    menuItem: [
      {
        key: "1",
        name: "Brand Listing",
        routepath: "/brandlist",
      },
      {
        key: "2",
        name: "Short Sellers ",
        routepath: "/shortseller",
      },
      {
        key: "3",
        name: "Full Value Brands",
        routepath: "/fullvaluebrand",
      },
    ],
  },
  {
    key: "4",
    icon: <img src={Refund} />,
    title: "Refund Requests",
    menuItem: [],
  },
  {
    key: "5",
    icon: <img src={Refund} />,
    title: "Static Pages",
    menuItem: [
      {
        key: "1",
        name: "About",
        routepath: "/about",
      },
      {
        key: "2",
        name: "Core Values",
        routepath: "/coreValues",
      },
      {
        key: "3",
        name: "Read Buying & Selling",
        routepath: "/readbuyingselling",
      },
      {
        key: "4",
        name: "Read Why List Your Brand",
        routepath: "/whylistyourbrand",
      },
      {
        key: "5",
        name: "Privacy Policy",
        routepath: "/privacypolicy",
      },
      {
        key: "6",
        name: "Support",
        routepath: "/support",
      },
      {
        key: "7",
        name: "User Agreement",
        routepath: "/useragreement",
      },
    ],
  },
  //added
  {
    key: "6",
    icon: <img src={user} />,
    title: "Manage Trades",
    menuItem: [
      {
        key: "1",
        name: "Open Sell Trades",
        routepath: "/opensell",
      },
      {
        key: "2",
        name: "Completed Sell Trades",
        routepath: "/completedsell",
      },
      {
        key: "3",
        name: "Open Buy Trades",
        routepath: "/openbuy",
      },
      {
        key: "4",
        name: "Completed Buy Trades",
        routepath: "/completedbuy",
      },
    ],
  },
  {
    key: "8",
    icon: <img src={user} />,
    title: "Brand Payments",
    menuItem: [
      {
        key: "1",
        name: "Unsettled Brand List",
        routepath: "/unsettledbrandlist",
      },
    ],
  },
  {
    key: "9",
    icon: <img src={user} />,
    title: "User Withdrawal Request",
    menuItem: [
      {
        key: "1",
        name: "User Withdrawal Request",
        routepath: "/userwithdrawalrequest",
      },
    ],
  },

  {
    key: "10",
    icon: <img src={user} />,
    title: "Brand Withdrawal Request",
    menuItem: [
      {
        key: "1",
        name: "Brand Withdrawal Request",
        routepath: "/brandwithdrawalrequest",
      },
    ],
  },
  //end
];

const Sidebar = () => {
  const [tab, settab] = useState(1);

  // function getItem(label, key, icon, children, type) {
  //   return {
  //     key,
  //     icon,
  //     children,
  //     label,
  //     type,
  //   };
  // }

  const href = window.location.pathname;
  useEffect(() => {
    pathhref(href);
  }, [href]);

  const pathhref = (path) => {
    switch (path) {
      case "/manageuser":
        return settab(1);
      case "/managebrand":
        return settab(2);
      case "/datanetprocess":
        return settab(3);
      case "/userProfile":
        return settab(4);
      case "/currentbrandvalue":
        return settab(5);
      case "/totalvalueofplatform":
        return settab(6);
      case "/branddistributionlist":
        return settab(7);
      case "/transactionhistory":
        return settab(8);
      case "/brandlist":
        return settab(9);
      case "/shortseller":
        return settab(10);
      case "/fullvaluebrand":
        return settab(11);
      case "/refundrequest":
        return settab(12);
      case "/about":
        return settab(13);
      // case "/changepassword":
      //   return settab(14);
      case "/brandProfile":
        return settab(14);
      case "/coreValues":
        return settab(15);
      case "/readbuyingselling":
        return settab(16);
      case "/whylistyourbrand":
        return settab(17);
      case "/privacypolicy":
        return settab(18);
      case "/support":
        return settab(19);
      case "/useragreement":
        return settab(20);
      case "/opensell":
        return settab(21);
      case "/completedsell":
        return settab(22);
      case "/openbuy":
        return settab(23);
      case "/completedbuy":
        return settab(24);
      case "/unsettledbrandlist":
        return settab(25);
      case "/userwithdrawalrequest":
        return settab(26);
        case "/brandwithdrawalrequest":
          return settab(27);
      default:
        return settab(1);
    }
  };

  // const setURL = (path)=>{
  //   switch(path) {
  //     case "1":return "/"
  //     case "2":return "/managebrand"
  //     case "/datanetprocess":return settab(3)
  //     case "/userProfile":return settab(4)
  //     case "/currentbrandvalue":return settab(5)
  //     case "/totalvalueofplatform":return settab(6)
  //     case "/branddistributionlist":return settab(7)
  //     case "/transactionhistory":return settab(8)
  //     case "/brandlist":return settab(9)
  //     case "/shortseller":return settab(10)
  //     case "/fullvaluebrand":return settab(11)
  //     default: return settab(1)

  //   }
  // };

  const renderComponent = (tab) => {
    switch (tab) {
      case 1:
        return <AddManageUser />;
      case 2:
        return <AddManageBrand />;
      case 3:
        return <DataNetProcess />;
      // case 3:
      //   return <DataNetProcess />;
      case 4:
        return <UserProfile />;
      case 5:
        return <Current_brand_value />;
      case 6:
        return <Total_value_of_platforms />;
      case 7:
        return <Brand_Distribution_list />;
      case 8:
        return <Transaction_History />;
      case 9:
        return <Brand_listing />;
      case 10:
        return <Short_seller />;
      case 11:
        return <Full_Value_Brands />;
      case 12:
        return <Refund_request />;
      case 13:
        return <About />;
      // case 14:
      //   return <ChangePass />;
      case 14:
        return <BrandProfile />;
      case 15:
        return <CoreValues />;
      case 16:
        return <ReadBuyingSelling />;
      case 17:
        return <WhyListYourBrand />;
      case 18:
        return <PrivacyPolicy />;
      case 19:
        return <Support />;
      case 20:
        return <UserAgreement />;
      case 21:
        return <OpenSell_Trades />;
      case 22:
        return <CompletedSell_Trades />;
      case 23:
        return <OpenBuy_Trades />;
      case 24:
        return <CompletedBuy_Trades />;
      case 25:
        return <UnsettledBrand />;
      case 26:
        return <WithdrawRequest />;
        case 27:
          return <BrandWithdrawRequest />;

      default:
        return <AddManageUser />;
    }
  };

  return (
    <div className="sidebar-container">
      <Layout>{/* <Header /> */}</Layout>
      <Layout>
        <Sider width={300} className="site-layout-background">
          <div className="sidebar-section-box">
            <Link className="navbar-brand" to="manageuser">
              <img src={Logo} />
            </Link>
            <div className="sidebar-menu-section">
              <CommonMenu
                tab={tab}
                settab={settab}
                menuItem={sideBarOptions}
                pathhref={href}
              />
            </div>
          </div>
        </Sider>
        <Layout className="content-layout">
          <Header />
          {renderComponent(tab)}
        </Layout>
      </Layout>
    </div>
  );
};
export default Sidebar;
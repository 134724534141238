import React, { useEffect, useState } from "react";
import profile from "../../../Assets/profile.png";
import profiletwo from "../../../Assets/profiletwo.png";
import profilethree from "../../../Assets/profilethree.png";
import profilefour from "../../../Assets/profilefour.png";
import { Card } from "antd";
import "./style.scss";
import { AreaChart, Area, Tooltip } from "recharts";
import {
  brandCount,
  userCount,
  butsharebrandCount,
  cardUnderwritingAmount,
  requestCount,
} from "../../utils/Api";
import { Link } from "react-router-dom";
const Cards = () => {
  const [totaluser, settotaluser] = useState();
  const [grapusercount, setgrapusercount] = useState();
  const [graphrequestcount, setGraphrequestcount] = useState();
  const [totalbrand, settotalbrand] = useState();
  const [totalrequest, setTotalrequest] = useState();
  const [graphbrandcount, setgraphbrandcount] = useState();
  const [totalbuybrand, setbuytotalbrand] = useState("");
  const [graphbuybrandcount, setgraphbuybrandcount] = useState();
  const [totalUnderwriting, setTotalUnderwriting] = useState("");
  const [graphUnderwritingAmount, setGraphUnderwritingAmount] = useState();

  const totalusers = async () => {
    const res = await userCount();
    settotaluser(res.data.totalCount);
    setgrapusercount(res.data.userCount);
  };
  const totalrequests = async () => {
    const res = await requestCount();
    setTotalrequest(res.data.totalCount);
    setGraphrequestcount(res.data.userCount);
  };
  const totalbrands = async () => {
    const res = await brandCount();
    settotalbrand(res.data.totalCount);
    setgraphbrandcount(res.data.userCount);
  };
  const totalbuybrandshare = async () => {
    const res = await butsharebrandCount();
    setbuytotalbrand(res.data.totalCount);
    setgraphbuybrandcount(res.data.BuyShare);
  };

  const changeBuyToMillion = totalbuybrand?.slice(1, 50);
  const buyinMillionValues = (changeBuyToMillion / 1000000).toFixed(2) + "M";

  const totalUnderwritingAmount = async () => {
    const res = await cardUnderwritingAmount();
    setTotalUnderwriting(res.totalCount);
    setGraphUnderwritingAmount(res.underwritingTransData);
  };

  const changeToMillion = totalUnderwriting?.slice(1, 50);
  const inMillionValues = (changeToMillion / 1000000).toFixed(2) + "M";

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
          <p className="label">{`Date: ${payload[0].payload.Date_id}`}</p>
          <p className="label">{`Value: ${payload[0].payload.Total_Value}`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    totalbrands();
    totalusers();
    totalbuybrandshare();
    totalUnderwritingAmount();
    totalrequests();
  }, []);

  const data = [
    {
      id: 1,
      title: "Total User",
      icon: profile,

      views: totaluser,
      //image: imageone,

      graph: grapusercount?.map((index, key) => {
        return {
          Total_Value: index.count,
          Date_id: index._id,
        };
      }),

      color: "#F48327",
    },
    {
      id: 2,
      title: "Total Brands",
      icon: profiletwo,
      views: totalbrand,
      //image: imagetwo,
      marginLeft: 0,
      graph: graphbrandcount?.map((index, key) => {
        return {
          Total_Value: index.count,
          Date_id: index._id,
        };
      }),

      color: "#0082CC",
    },
    {
      id: 3,
      title: "Trading Transactions",
      icon: profilethree,
      views: buyinMillionValues,
      //image: imagethree,
      graph: graphbuybrandcount?.map((index, key) => {
        return {
          Total_Value: index.totalBuyingAmt,
          Date_id: index._id,
        };
      }),

      color: "#E10000",
    },
    {
      id: 4,
      title: "Underwriting Transactions",
      icon: profilefour,
      views: inMillionValues,
      //image: imagefour,
      graph: graphUnderwritingAmount?.map((index, key) => {
        return {
          Total_Value: index.totalBuyingAmt,
          Date_id: index._id,
        };
      }),
      color: "#27C41F",
    },
    {
      id: 5,
      title: "Withdrwal Request",
      icon: profilefour,
      views: totalrequest,
      //image: imagefour,
      graph: graphrequestcount?.map((index, key) => {
        return {
          Total_Value: index.count,
          Date_id: index._id,
        };
      }),
      color: "#27C41F",
    },
  ];
  return (
    <>
      <div className="card_container">
        <div className="card_wrapper">
          {data.map((item) => {
            return (
              <Card className="card_subwrapper">
                <img src={item.icon} />
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 25,
                    marginTop: -22,
                  }}
                >
                  {item.title}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: 26,
                    color: "#000000",
                    zIndex: "10",
                  }}
                >
                  {item.views}
                </p>
                <AreaChart
                  style={{ marginLeft: -20, marginTop: -73, zIndex: "1" }}
                  width={258}
                  height={130}
                  data={item.graph}
                >
                  <Tooltip
                    position={{ x: 100, y: 20 }}
                    content={<CustomTooltip />}
                  />
                  <Area
                    dataKey="Total_Value"
                    stackId="1"
                    stroke={item.color}
                    fill={item.color}
                    fillOpacity={1}
                  />
                  <Area
                    dataKey="Total_Brand"
                    stackId="1"
                    stroke={item.color}
                    fill={item.color}
                    fillOpacity={1}
                  />
                  <Area
                    dataKey="Buy_Share"
                    stackId="1"
                    stroke={item.color}
                    fill={item.color}
                    fillOpacity={1}
                  />
                  <Area
                    dataKey="Underwriting_Amount"
                    stackId="1"
                    stroke={item.color}
                    fill={item.color}
                    fillOpacity={1}
                  />
                <Link to="manageuser">   <Area
                    dataKey="Total_Request"
                    stackId="1"
                    stroke={item.color}
                    fill={item.color}
                    fillOpacity={1}
                  /></Link> 
                </AreaChart>
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Cards;

import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import CommonTable from "../common/Table";
// import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";
import user from "../../Assets/Ellipse.png";
import { updateAdminProfile } from "../utils/Api";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/Api.js";

const ProfileUpdate = ({ proImage, proName, proEmail }) => {
  console.log("proImage 45: ", proImage);

  const [profileName, setProfileName] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  console.log("selected image", selectedFile);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setProfileImage(proImage);
    setPreviewUrl(BASE_URL + `/images/${proImage}`);
    setProfileName(proName);
    setProfileEmail(proEmail);
  }, []);

  const adminToken = localStorage.getItem("token");

  const handleProfileUpdate = async () => {
    // event.preventDefault();
    const formData = new FormData();
    formData.append("name", profileName);
    formData.append("email", profileEmail);
    formData.append("file", selectedFile);

    const response = await updateAdminProfile(
      // requestedData,
      formData,
      adminToken
    );

    if (response?.status === 200) {
      toast.success(response.message);
      window.location.reload();
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="container">
      <h2>Profile</h2>
      <div className="row">
        <div className="col-md-6">
          <div class="profile_pic_box">
            <img src={previewUrl} alt="Selected Image" />
          </div>

          <input type="file" onChange={handleFileChange} />
          {/* <img src={previewUrl} alt="Selected Image" /> */}
        </div>
        <div className="col-md-6">
          <div className="edit_profile_btn_box text-end">
            <button className="save_profile" onClick={handleProfileUpdate}>
              Save Profile
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <input
          className="form-control"
          placeholder="Name"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          placeholder="Email"
          value={profileEmail}
          onChange={(e) => setProfileEmail(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ProfileUpdate;

import React, { useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router";
import AddManageUser from "./components/Pages/AddManageUser";
import AddManageBrand from "./components/Pages/AddManageBrand";
import Sidebar from "./components/Sidebar/index";
import DataNetProcess from "./components/Pages/Data_Net_Process";
import UserProfile from "./components/Pages/UserProfile";
import BrandProfile from "./components/Pages/BrandProfile";
import Current_brand_value from "./components/Pages/Current_Brand_Value";
import Total_value_of_platforms from "./components/Pages/Total_value_of_platform";
import Brand_Distribution_list from "./components/Pages/Brand_Distribution_List";
import Transaction_History from "./components/Pages/Transaction_History";
import Brand_listing from "./components/Pages/Brand_Listing";
import Short_seller from "./components/Pages/Short Sellers";
import Login from "./components/Pages/Login/Index";
import Full_Value_Brands from "./components/Pages/Full Value Brands";
import Refund_request from "./components/Pages/Refund Request";
import ForgotPassword from "./components/Pages/ForgetPassword";
import ResetPassword from "./components/Pages/Reset_Password/Index";
import ChangePassword from "./components/Pages/ChangePassword";
import Static_page from "./components/Pages/StaticPage";
import About from "./components/Pages/About";
import CoreValues from "./components/Pages/CoreValues";
import ReadBuyingSelling from "./components/Pages/ReadBuyingSelling";
import WhyListYourBrand from "./components/Pages/WhyListYourBrand";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import Support from "./components/Pages/Support";
import UserAgreement from "./components/Pages/UserAgreement";
import OpenSell_Trades from "./components/Pages/OpenSell_Trades";
import CompletedSell_Trades from "./components/Pages/CompletedSell_Trades";
import OpenBuy_Trades from "./components/Pages/OpenBuy_Trades";
import CompletedBuy_Trades from "./components/Pages/CompletedBuy_Trades";
import UnsettledBrand from "./components/Pages/UnsettledBrand";
import Setting from "./components/Pages/Settings";
import UserLogin from "./components/Pages/UserLogin";
import UserWallet from "./components/Pages/UserWallet";
import Headers from "./components/UserHeader/Headers";
import ForgotPasswordUser from "./components/Pages/Forget-passwordUser";
import Route from "./components/Pages/UserRoute/route";
import UserOtpVerify from "./components/Pages/UserOtpVerify";
import ResetPasswordUser from "./components/Pages/ResetpasswordUser";
import WithdrawRequest from "./components/Pages/WithdrawRequest";
import BrandWithdrawRequest from "./components/Pages/Brand_withdrawal_List";



const Router = () => {
  const [isLogged, setislogged] = useState("");
  useEffect(() => {
    if (localStorage.getItem("token")) return setislogged(true);
    return setislogged(false);
  }, [localStorage]);

  const routes = useRoutes([
    {
      element: isLogged ? <Navigate to="/manageuser" /> : <Login />,
      children: [
        { path: "/", element: <Login /> },

        // { path: "/forgetpassword", element: <ForgotPassword /> },
        // { path: "/resetpassword", element: <ResetPassword /> },
        // { path: "/changepassword", element: <ChangePassword /> },
      ],
    },
    { path: "/forgetpassword", element: <ForgotPassword /> },
    { path: "/resetpassword", element: <ResetPassword /> },
    { path: "/changepassword", element: <ChangePassword /> },
    { path: "/setting", element: <Setting /> },
    { path: "/headers", element: <Headers /> },

    { path: "/paypalwallet/:code", element: <Route /> },


    // { path: "/user", element: <UserLogin /> },
    // { path: "/userwallet/:code", element: <UserWallet /> },

    // userlogin

    // {
    //   element: !isLogged ? <Navigate to="/user" /> : <Navigate to="/userwallet/:code" />,

    // },
    // // {
    // //   element: <Navigate to="/userwallet/" />,
    // //   children: [
    // //     { path: "/user", element: <UserLogin /> },
    // //   ],
    // // },
    // // { path: "/userwallet", element: <UserWallet /> },
    // // { path: "/brandprofile", element: <BrandProfile /> },

    { path: "/forget-password", element: <ForgotPasswordUser /> },

    { path: "/verifyotp/:userId", element: <UserOtpVerify /> },

    { path: "/reset-password/:id", element: <ResetPasswordUser /> },



    {
      element: isLogged ? <Navigate to="/userwallet" /> : <UserLogin />,
      children:
        [
          { path: "/user", element: <UserLogin /> }
          ,],
    },
    {
      element: !isLogged ? <Navigate to="/user" /> : <UserWallet />,
      children:
        [
          {
            path: "/userwallet", element: <UserWallet />
          },
        ],
    },

    {
      element: !isLogged ? <Navigate to="/" /> : <Sidebar />,
      children: [
        // { path: "/setting", element: <Setting /> },

        { path: "/manageuser", element: <AddManageUser /> },
        { path: "/managebrand", element: <AddManageBrand /> },
        { path: "/datanetprocess", element: <DataNetProcess /> },
        { path: "/userprofile", element: <UserProfile /> },
        { path: "/brandprofile", element: <BrandProfile /> },
        { path: "/currentbrandvalue", element: <Current_brand_value /> },
        {
          path: "/totalvalueofplatform",
          element: <Total_value_of_platforms />,
        },
        {
          path: "/branddistributionlist",
          element: <Brand_Distribution_list />,
        },
        { path: "/transactionhistory", element: <Transaction_History /> },
        { path: "/brandlist", element: <Brand_listing /> },
        { path: "/shortseller", element: <Short_seller /> },
        { path: "/fullvaluebrand", element: <Full_Value_Brands /> },
        { path: "/refundrequest", element: <Refund_request /> },
        { path: "/about", element: <About /> },
        { path: "/coreValues", element: <CoreValues /> },
        { path: "/readbuyingselling", element: <ReadBuyingSelling /> },
        { path: "/whylistyourbrand", element: <WhyListYourBrand /> },
        { path: "/privacypolicy", element: <PrivacyPolicy /> },
        { path: "/support", element: <Support /> },
        { path: "/useragreement", element: <UserAgreement /> },
        { path: "/opensell", element: <OpenSell_Trades /> },
        { path: "/completedsell", element: <CompletedSell_Trades /> },
        { path: "/openbuy", element: <OpenBuy_Trades /> },
        { path: "/completedbuy", element: <CompletedBuy_Trades /> },
        { path: "/unsettledbrandlist", element: <UnsettledBrand /> },
        { path: "/userwithdrawalrequest", element: <WithdrawRequest /> },
        { path: "/brandwithdrawalrequest", element: <BrandWithdrawRequest /> },

        // { path: "/setting", element: <Setting /> },

      ],
    },
  ]);

  return routes;
};

export default Router;

import React from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Button,
  Menu,
  message,
  Pagination,
} from "antd";
import "./style.scss";
import "antd/dist/antd.css";
import CommanPagination from "../CommanPagination/CommanPagination";

const CommonTable = (props) => {
  const { columns, data, onChange, total, totalPages } = props;
  const defaultPageSize = 10;

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        className="oe"
      />
      <CommanPagination
        onChange={onChange}
        total={total}
        totalPages={totalPages}
        defaultPageSize={defaultPageSize}
      />
    </>
  );
};

export default CommonTable;

import React from "react";
import "./style.scss";
import { Pagination } from "antd";
import DraftEditorCompositionHandler from "draft-js/lib/DraftEditorCompositionHandler";

const CommanPagination = (props) => {
  const { total, onChange, defaultPageSize } = props;

  // console.log("common pagination total", total)
  // console.log("common pagination onChange", onChange)
  // console.log("common pagination default", defaultPageSize)

  return (
    <div className="mainPage">
      <span>
        <Pagination
          showQuickJumper={{
            goButton: <button className="go_button">Go</button>,
          }}
          total={total}
          onChange={onChange}
          defaultPageSize={defaultPageSize}
          defaultCurrent={1}
        />
      </span>
    </div>
  );
};

export default CommanPagination;


import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../../Assets/MBXlogo.svg";
// import "./style.scss";
import { useNavigate } from "react-router-dom";
import { UserVerifyOtp } from "../../utils/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


function UserOtpVerify() {
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
const { userId } = useParams();
const navigate = useNavigate();


  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    const data = {
      otp: otp,
      userId: userId
    };

    const res = await UserVerifyOtp(data);
    if (res?.data?.status === 200) {
      toast.success(res?.data.message);
      navigate(`/reset-password/${userId}`);
    } else {
      toast.error(res?.data.message);
    }
  };

    return (
        <>
        <section className="main-wrapper-login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="Login-wrapper">
                <div className="login-wrap-logo text-center">
                  <img src={Logo} alt="Logo" />
                </div>
                <div className="forgt-txt-head text-center">
                  <h1>OTP Verification</h1>
                  <p className="forgot_para">
                    Please enter the OTP sent to your email address.
                  </p>
                </div>
                <div className="loginbody--form">
                  <form>
                    <div className="form-group">
                    <input
                    type="text"
                    placeholder="Enter received otp"
                    className={`form-control input-login-control mb-4 ${
                      otpError ? "is-invalid" : ""
                    }`}
                    value={otp}
                    onChange={(e) => {
                      setOTP(e.target.value);
                      setOTPError("");
                    }}
                    />
                    {otpError && (
                      <div className="invalid-feedback">{otpError}</div>
                    )}
                    </div>
                    <div className="login-login">
                      <button
                        type="submit"
                        className="btn btn-login"
                        onClick={handleVerifyOTP}
                      >
                        Verify OTP
                      </button>
                    </div>
                  </form>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
        </>
    )
}
export default UserOtpVerify;
import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Button,
  Menu,
  Select,
  message,
  Pagination,
  Tabs,
  Input,
  Modal,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import CommonTable from "../../common/Table/tradingTable";
import "./style.scss";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import moment from "moment";
import EditModal from "./Editmodal";
import {
  getTradingHistory,
  getTradingHistoryWithDates,
  getTradingHistoryPDF,
  BASE_URL,
  Getlistingofwithdrawalrequest,
  getUserProfileToExcel,
  getWithdrawalRequestToExcel,
  Getlistingofbrandwithdrawalrequest,
  getBrandWithdrawalRequestToExcel,
} from "../../utils/Api";
import { toast } from "react-toastify";

const { TabPane } = Tabs;
const { Option } = Select;
const BrandWithdrawRequest = () => {
  const [userTransactions, setUserTransactions] = useState([]);
  const [userdata, setUserdata] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [brandTransactions, setBrandTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageno, setpageno] = useState("1");
  const [datePageNo, setDatePageNo] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewId, setViewId] = useState("");
  const today = new Date();
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(
    moment().add(1, "days").format("yyyy-MM-DD")
  );
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  let search = "";
  const getwithdrawallist = async (
    page,
    searchData,
    fromDt,
    endDt,
    paymentsStatus,
    pageSize
  ) => {
    const pageNo = page ? page : pageno;
    const res = await Getlistingofbrandwithdrawalrequest(
      pageNo,
      searchData,
      fromDt,
      endDt,
      paymentsStatus,
      pageSize
    );

    if (res?.status === 200) {
      setUserTransactions(res?.data.listofWithdrawalRequest);
      setTotal(res?.total);
      setTotalPages(res?.totalPages);
      setPerPage(res?.per_page);
      setCurrentPage(res?.current_page);
    }
  };
  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const getEditData = (userTransaction) => {
    setUserdata(userTransaction);
    showModal();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleViewButtonClick = () => {
    if (fromDt && endDt) {
      getwithdrawallist(1, "", fromDt, endDt,selectedPaymentStatus, perPage);
    }
  };

  const handleFilterByPaymentStatus = (paymentsStatus) => {
    setSelectedPaymentStatus(paymentsStatus);
    getwithdrawallist(1, "", "", "", paymentsStatus, perPage);
  };

  // const getwithdrawallist = async () => {
  //   const response = await Getlistingofwithdrawalrequest();
  //   if (response?.status === 200) {
  //     // toast.success(response.message);
  //     setUserTransactions(response.data.listofWithdrawalRequest);
  //     setTotal(response?.total);
  //     setTotalPages(response?.totalPages);
  //     setPerPage(response?.per_page);
  //   } else {
  //     toast.error(response?.message);
  //   }
  // };

  useEffect(() => {
    getwithdrawallist();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
    },
    {
      title: "brandName",
      dataIndex: "brandName",
      key: "brandName",
      width: "15%",
    },
    // {
    //   title: "Email",
    //   dataIndex: "Email",
    //   key: "Email",
    //   width: "15%",
    // },
    {
      title: "Banking Email",
      dataIndex: "BankingEmail",
      key: "BankingEmail",
      width: "50%",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: "15%",
      render: (text, record) => (        
         <span className={text === 'Rejected' ? 'red-text' : text === 'Transaction Successful' ? 'green-text' : 'gray-text'}>    
        {text}   
        </span>      
      ), 
    },
    {
    title: "Comment",
    dataIndex: "Comment",
    key: "Comment",
    width: "15%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, userTransactions) => (
        <Space size="middle">
          <Button
            onClick={() => getEditData(userTransactions)}
            disabled={
              userTransactions?.Status === "Rejected" ||
              userTransactions?.Status === "Transaction Successful" ||
              userTransactions?.Status === "Transaction Failed"
            }
            style={{ backgroundColor: "#f48327", color: "white" }}
          >
            Change Status
          </Button>
        </Space>
      ),
    },
  ];
  // console.log("user transaction", userTransactions)
  const data =
    userTransactions?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        brandName: key?.brandName,
        Email: key?.email,
        BankingEmail: key?.bankingEmail,
        Amount: key?.withdrableamount,
        Status: key?.paymentStatus,
        Comment: key?.comment,
        isUpdated: key?.isUpdated,
        createdAt: moment(key.createdAt).format("LL"),
      };
    }) || [];

  // const handleSearch = async (nameSearch) => {
  //   const response = await Getlistingofwithdrawalrequest({
  //     search: nameSearch,
  //   });

  //   if (response?.status === 200) {
  //     setUserTransactions(response.data.listofWithdrawalRequest);
  //     setTotal(response?.total);
  //     setTotalPages(response?.totalPages);
  //     setPerPage(response?.per_page);
  //   } else {
  //     toast.error(response.message);
  //   }
  // };

  const adminToken = localStorage.getItem("token");
  const ExportToExcel = async (
    fromDt,
    endDt,
    paymentsStatus) => {
    const res = await getBrandWithdrawalRequestToExcel(fromDt,endDt,paymentsStatus);
  };
  function onChange(e) {
    setpageno(e);
    getwithdrawallist();
  }
  return (
    <div className="Brand_listing">
      <div className="label_brand_listing">
        <h4> Withdraw Request </h4>
        <div className="payment-status-filter">
          <label>
            <b>Filter by Payment Status: </b>
          </label>
          <Select
            className="select-style"
            value={selectedPaymentStatus}
            onChange={(value) => handleFilterByPaymentStatus(value)}
          >
            <Option value="">All</Option>
            <Option value="Processing">Processing</Option>
            <Option value="Transaction Successful">Transaction Successful</Option>
            <Option value="Transaction Failed">Transaction Failed</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
        </div>
        <div className="mainCalender">
          <div className="date_pick_box">
            <label>
              <b>Select Date</b>
            </label>
            <label className="input_label">From</label>
            <DatePicker className="dateInput" onChange={onChangeStart} />
            <label className="input_label">To</label>
            <DatePicker className="dateInput" onChange={onChangeEnd} />
            <button className="buttonMain" onClick={handleViewButtonClick}>
              View
            </button>
            <button className="buttonMain" onClick={() => ExportToExcel(fromDt,endDt,selectedPaymentStatus)}>
              {" "}
              Export
            </button>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="1" centered>
        <TabPane key="1">
          <div className="branding_list">
            <Input.Search
              allowClear
              placeholder="Search"
              onChange={(event) =>
                getwithdrawallist(1, event.target.value, "", "", "", perPage)
              }
            />
            <div className="transaction_table_sec">
              <Table
                columns={columns}
                dataSource={data}
                pagination={{
                  className: "customPagination",
                  showQuickJumper: {
                    goButton: <button className="go_button">Go</button>,
                  },
                  defaultCurrent: 1,
                  onChange: (page, pageSize) => {
                    setpageno(page);
                    setPerPage(pageSize);
                    getwithdrawallist(
                      page,
                      "",
                      fromDt,
                      endDt,
                      selectedPaymentStatus,
                      pageSize
                    );
                  },
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "50", "100", "200"],
                  total: total,
                }}
                scroll={{ y: 500 }}
                className="oe"
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <EditModal
          key={userdata?._id}
          userTransactions={userTransactions}
          setUserTransactions={setUserTransactions}
          userTransaction={userdata}
          visible={isModalVisible}
          setVisible={setIsModalVisible}
        />
      </Modal>
    </div>
  );
};

export default BrandWithdrawRequest;

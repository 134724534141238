import { Button, Modal } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { sendEmailToAllBrands } from "../utils/Api";

const BrandEmailModal = (id) => {
  const mainId = id;

  const [error, setError] = useState("");
  const [faqNameerror, setFaqNameError] = useState("");
  const [varError, setVarError] = useState("");
  const [faqNameValue, setFaqNameValue] = useState("");
  const [varNameValue, setVarNameValue] = useState("");
  const [subCatData, setSubCatData] = useState("");

  const handleChange = (value, type) => {
    if (type === "name") {
      if (value === "") {
        setFaqNameError("Faq Type should not be blank.");
      } else {
        setFaqNameError("");
      }
      setSubCatData(value);
    } else if (type === "attname") {
      if (value === "") {
        setError("Question should not be blank.");
      } else {
        setError("");
      }
      setFaqNameValue(value);
    } else if (type === "varname") {
      if (value === "") {
        setVarError("Answer should not be blank.");
      } else {
        setVarError("");
      }
      setVarNameValue(value);
    }
  };

  const BASE_URL = "http://54.190.192.105:5048/";
  const adminToken = localStorage.getItem("token");

  const handleFaq = async () => {
    const requestedData = {
      subject: subCatData,
      title: faqNameValue,
      body: varNameValue,
    };

    const response = await sendEmailToAllBrands(requestedData, adminToken);

    if (response?.status === 200) {
      toast.success(response.message);
      setFaqNameValue("");
      setVarNameValue("");
      setSubCatData("");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <div>
        <label style={{ marginBottom: "10px", color: "orange" }}>Subject</label>
        <div className="inputBoxCategoryCss">
          <input
            type="text"
            placeholder="Please Enter"
            name="varname"
            value={subCatData}
            onChange={(e) => handleChange(e.target.value, "name")}
            className="inputFields"
            style={{
              width: "450px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
              height: "40px",
            }}
          />
        </div>
      </div>
      <div>
        <label style={{ marginBottom: "10px", color: "orange" }}>Title</label>
        <div className="">
          <input
            type="text"
            placeholder="Please Enter"
            name="varname"
            value={faqNameValue}
            onChange={(e) => handleChange(e.target.value, "attname")}
            className="inputFields"
            style={{
              width: "450px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
              height: "40px",
            }}
          />
        </div>
      </div>
      <div>
        <label style={{ marginBottom: "10px", color: "orange" }}>Body</label>
        <div className="inputBoxCategoryCss">
          <input
            type="text"
            placeholder="Please Enter"
            name="varname"
            value={varNameValue}
            onChange={(e) => handleChange(e.target.value, "varname")}
            className="inputFields"
            style={{
              width: "450px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
              height: "40px",
            }}
          />
        </div>
      </div>

      <button
        onClick={() => handleFaq()}
        style={{
          margin: "10px",
          marginLeft: "380px",
          borderRadius: "5px",
          backgroundColor: "orange",
        }}
      >
        Send Mail
      </button>
    </>
  );
};
export default BrandEmailModal;

import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Button,
  Menu,
  message,
  Pagination,
  Tabs,
  Input,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import CommonTable from "../../common/Table/tradingTable";
import "./style.scss";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import moment from "moment";
import {
  getTradingHistory,
  getTradingHistoryWithDates,
  getTradingHistoryPDF,
  BASE_URL
} from "../../utils/Api";
import { toast } from "react-toastify";

const { TabPane } = Tabs;

const Transaction_History = () => {
  const [userTransactions, setUserTransactions] = useState([]);
  const [brandTransactions, setBrandTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageno, setpageno] = useState("1");
  const [datePageNo, setDatePageNo] = useState("");
  const [perPage, setPerPage] = useState(50);
  const today = new Date();
  const [fromDate, setFromDate] = useState(moment().format("yyyy-MM-DD"));
  const [toDate, setToDate] = useState(
    moment().add(1, "days").format("yyyy-MM-DD")
  );
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  let search = "";

  const userTransHistory = async (searchData, page) => {
    const res = await getTradingHistory({ search: searchData, pageno: page });
    if (res?.status === 200) {
      setUserTransactions(res?.List);
      setTotal(res?.totalRecords);
      setPerPage(res?.per_page);
    }
  };

  const ExportToPDF = async (id) => {
    const res = await getTradingHistoryPDF(id);
  };

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  const handleDate = async (searchData) => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
      datePageNo: pageno,
      search: searchData,
    };

    const response = await getTradingHistoryWithDates(requestedData);

    if (response?.status === 200) {
      // toast.success(response.message);
      setUserTransactions(response?.List);
      setTotal(response?.totalRecords);
    } else {
      toast.error(response.message);
    }
  };

  // useEffect(() => {
  //   userTransHistory(search, pageno);
  //   // handleDate()
  // }, [pageno, perPage]);

  useEffect(() => {
    handleDate();
  }, [pageno]);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "18%",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      width: "15%",
    },
    {
      title: "Seller",
      dataIndex: "seller",
      key: "seller",
      width: "15%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Share Price",
      dataIndex: "sharePrice",
      key: "sharePrice",
      width: "10%",
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => ExportToPDF(record.id)}
            style={{ backgroundColor: "#f48327", color: "white" }}
          >
            Export PDF
          </Button>
        </Space>
      ),
    },
  ];

  const data =
    userTransactions?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        buyer: key?.buyerId.userName || key?.buyerId.brandUserName,
        seller: key?.sellerId.userName || key?.sellerId.brandUserName,
        quantity: key?.numberOfShare,
        sharePrice: key?.costPricePerShare,
        createdAt: moment(key.createdAt).format("LL"),
        totalCost: key?.totalCost,
      };
    }) || [];

  const handleSearchUnderwriting = async (nameSearch) => {
    // getUnderwritingStatus(nameSearch, page);
  };

  const handleSearch = async (nameSearch) => {
    // getActiveStatus(nameSearch);
  };

  function onChange(e) {
    setpageno(e);
    // setDatePageNo(e)
  }
  return (
    <div className="Brand_listing">
      <div className="label_brand_listing">
        <h4> Trading History </h4>
        <div className="mainCalender">
          <div className="date_pick_box">
            <label>
              <b>Select Date</b>
            </label>
            <label className="input_label">From</label>
            <DatePicker className="dateInput" onChange={onChangeStart} />
            <label className="input_label">To</label>
            <DatePicker className="dateInput" onChange={onChangeEnd} />
            <button className="buttonMain" onClick={handleDate}>
              View
            </button>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="1" centered>
        <TabPane key="1">
          <div className="branding_list">
            <div className="transaction_table_sec">
              <CommonTable
                data={data}
                columns={columns}
                onChange={(e) => onChange(e)}
                total={total}
                perPage={perPage}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Transaction_History;

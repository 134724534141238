import React, { useEffect, useState } from "react";
import {
  Avatar,
  message,
  Pagination,
  Image,
  Input,
  Menu,
  Space,
  Dropdown,
  Button,
  Modal,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import CommonTable from "../../common/Table";
import {
  UserProfileListing,
  DownloadEmailpdf,
  getUserHoldings,
  getUserTransactions,
  //
  UnsettledBrandList,
  getBrandHoldings,
  makeListingPayements,
  BASE_URL
} from "../../utils/Api";
import ModalUse from "./ModalUse";
import PaymentModal from "./PaymentModal";

import "./style.scss";
import { toast } from "react-toastify";
import moment from "moment";

const UnsettledBrand = () => {
  const [userdata, setuserData] = useState();
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  // const [Items, setItem] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");

  const [modalData, setModalData] = useState();
  const [modalTotalRecord, setModalTotalRecord] = useState();
  const [modalPageNo, setModalPageNo] = useState("1");

  let search = "";

  //

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showAModal = () => {
    setIsModalOpen(true);
  };
  const handleAOk = () => {
    setIsModalOpen(false);
  };
  const handleACancel = () => {
    setIsModalOpen(false);
  };

  //

  const getuserlisting = async (searchData, page) => {
    const res = await UnsettledBrandList({ search: searchData, pageno: page });
    setuserData(res.data.Brandlist);
    settotal(res.data.total);
  };

  useEffect(() => {
    getuserlisting(search, pageno);
  }, [pageno, total]);

  useEffect(() => {
    getBrandHoldings(id, modalPageNo)
      .then((res) => {
        const data = {
          name: "Underwriting Share Holders List",
          type: 1,
          holdingsData: res,
        };
        setModalData(data);
        setModalTotalRecord(data?.holdingsData?.totalRecords);
        return res.data;
      })
      .catch((err) => {});
  }, [modalPageNo]);

  // const getTransactionData = async (id) => {
  //   const res = await getUserTransactions(id);
  //   if (res?.status === 200) {
  //     setModalData({
  //       name: "User Transactions",
  //       Transactiondata: res?.data,
  //       type: 2,
  //       id: id,
  //     });
  //   } else {
  //     toast.warning("Somting went wrong");
  //   }
  // };

  const handleMenuClick = async (e) => {
    if (e?.key === "1") {
      const res = await getBrandHoldings(id, modalPageNo);
      if (res?.status === 200) {
        const data = {
          name: "Underwriting Share Holders List",
          type: 1,
          holdingsData: res,
        };
        setModalData(data);
        setModalTotalRecord(data?.holdingsData?.totalRecords);
        showModal();
      } else {
        toast.warning(
          "THis brand have no users who have bought it's listed tokens"
        );
      }
    } else {
      // const res = await getUserTransactions(id);
      // if (res?.status === 200) {
      const tData = {
        name: "Make Brand Payment",
        // transactionData: res,
        type: 2,
        id: id,
      };
      setModalData(tData);
      // } else {
      //   toast.warning("Somting went wrong");
      // }
      showAModal();
    }
  };
  const getRowData = (record) => {
    setId(record?.id);
    setSelectedRecord(record)
    console.log(record,"record?.setSelectedRecord>>>>>>>");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "View Share Holders",
          key: "1",
          // icon: <UserOutlined />,
        },
        {
          label: "Make Payment",
          key: "2",
          // icon: <UserOutlined />,
        },
      ]}
    />
  );
  const columns = [
    {
      title: "Brand",
      dataIndex: "brandUserName",
      key: "brandUserName",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Re-valuated Worth",
      dataIndex: "brandAdjustedValution",
      key: "brandAdjustedValution",
    },
    {
      title: "Listed Worth",
      dataIndex: "brandValue",
      key: "brandValue",
    },
    {
      title: "Adjusted Token Price",
      dataIndex: "brandAdjustedSharePrice",
      key: "brandAdjustedSharePrice",
    },
    {
      title: "Unsold Tokens",
      dataIndex: "totalNumberOfShares",
      key: "totalNumberOfShares",
    },
    {
      title: "Account Settled",
      dataIndex: "isAccountSettled",
      key: "isAccountSettled",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              onClick={() => {
                getRowData(record);
              }}
            >
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const data =
    userdata?.map((key, index) => {
      return {
        id: key?.id,
        key: index,
        displayPicture: key?.brandImage,
        brandUserName: key?.brandUserName,
        createdAt: moment(key.createdAt).format("LL"),
        brandAdjustedValution: key?.brandAdjustedValution,
        brandValue: key?.brandValue,
        brandAdjustedSharePrice: key?.brandAdjustedSharePrice,
        totalNumberOfShares: key?.totalNumberOfShares,
        isAccountSettled: key?.isAccountSettled === false ? "False" : "True",
      };
    }) || [];

  const handleSearch = async (nameSearch) => {
    const res = await UserProfileListing({
      search: nameSearch,
      pageno: pageno,
    });
    setuserData(res.data.userlist);
  };

  const onChange = async (e) => {
    // e.preventDefault()
    setpageno(e);
  };

  const getPageData = (data) => {
    // setModalPageNo(data);
    setModalPageNo(data);
  };

  return (
    <div className="userProfile">
      <div className="userprofile_label">
        <h4>Unsettled Brand List </h4>
      </div>
      <div className="userTable">
        <Input.Search
          placeholder="Search"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
        />
        <CommonTable
          columns={columns}
          data={data}
          onChange={(e) => onChange(e)}
          total={total}
        />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="user-profile-modal"
      >
        <ModalUse
          details={modalData}
          id={id}
          totalRecords={modalTotalRecord}
          onSubmit={getPageData}
        />
      </Modal>
      <Modal
        title="Make Brand Payment"
        visible={isModalOpen}
        onOk={handleAOk}
        onCancel={handleACancel}
      >
        <PaymentModal id={id} selectedRecord={selectedRecord}/>
      </Modal>
    </div>
  );
};

export default UnsettledBrand;

import { Button, Modal } from "antd";
import axios from "axios";
import { sendEmailToAllBrands } from "../utils/Api";
import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../Assets/MBXlogo.svg";
import { addCommission, getMBX } from "../utils/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./style.scss"

const SettingModal = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    appleCommission: '',
    mbxCommission: '',
  });
  const [mbxData, setMbxData] = useState(null);

  useEffect(() => {

    async function fetchData() {
      const data = await getMBX();
      setMbxData(data.data);
      console.log(data.data, "data>>>>>>>")
      setValues({
        appleCommission: data?.data?.appleCommission,
        mbxCommission: data?.data?.mbxCommission
      })
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };



  const appleCommisiom = async (e) => {
    e.preventDefault();
    const response = await addCommission(values);
    if  (response?.status === 200) {
      console.log(response.status,"response123")
      navigate("/manageuser");
      toast.success(response?.status?.message);
      window.location.reload();
      navigate("/manageuser");
    } else {
      toast.error(response?.status?.message);
    }
  };

  return (
    <>
<section className="main-wrapper-setting"  >
<div className="container" >
    <div className="row justify-content-center">
      <div className="login-wrap-logo text-center">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="loginbody--form" >
        <Form onSubmit={appleCommisiom}>
          <Row>
            <Col xs="12">
              <div className="form-group">
              <label style={{ marginBottom: "10px", color: "orange" }}>Apple Commission</label>
                <Form.Control
                
                  type="number"
                  className="input-applecom-control"
                  placeholder="Apple commision"
                  name="appleCommission"
                  onChange={handleChange}
                  defaultValue={mbxData?.appleCommission}
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="form-group">
              <label style={{ marginBottom: "10px", color: "orange" }}>MBX Commission</label>
                <Form.Control
                  type="number"
                  className="input-nbccom-control"
                  placeholder="MBX commision"
                  name="mbxCommission"
                  onChange={handleChange}
                  defaultValue={mbxData?.mbxCommission}
                />
              </div>
            </Col>


            <Col >
              <div className="save-save">
                <button
                  type="submit"
                  className="btn btn-login"

                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>



    </div>

    </div>

    </section>

    </>

  );
};
export default SettingModal;

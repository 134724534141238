import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import CommonTable from "../../common/Table";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";

const ModalUse = ({ details }) => {
  const columns = [
    {
      title: "Social Media",
      dataIndex: "Susername",
      key: "Susername",
      width: "25%",
    },
  ];

  const data = details?.SocialMedia?.map((res) => {
    return {
      Susername: res,
    };
  });

  return (
    <div className="container">
      <div className="heading">
        <div
          style={{
            marginLeft: "185px",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <b>{details?.name}</b>
        </div>
      </div>
      <div className="contentTable box-body">
        <CommonTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default ModalUse;

import React, { useEffect, useState } from "react";
import UploadIcon from "../../../Assets/uploadIcon.svg";
import "./view.scss";
import { getBrandById, BASE_URL } from "../../utils/Api";
import { Avatar, Image } from "antd";
import { Button, Form } from "react-bootstrap";

const ViewModal = ({ id, visible }) => {
  const [data, setData] = useState("");
  useEffect(() => {
    const getData = async () => {
      const data = await getBrandById(id);
      setData(data.Data);
    };
    getData();
  }, [id]);

  return (
    <div className="managuser_modal">
      <div className="container">
        <div className="modal_imgBox">
          <Avatar
            style={{ width: "100px", height: "100px" }}
            src={
              <Image
                src={`${BASE_URL}/images/${data.brandImage}`}
              />
            }
          />
        </div>

        <div className="modal_content_box">
          <div className="header_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Brand Name
              </div>
              <div className="col-lg-6">{data.brandUserName}</div>
            </div>
          </div>
          <div className="row_outerBox">
            <div className="row_box">
              <div className="row">
                <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                  Email
                </div>
                <div className="col-lg-6">{data.brandEmail}</div>
              </div>
            </div>
            <div className="row_box">
              <div className="row">
                <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                  Address
                </div>
                <div className="col-lg-6">
                  {data.brandStreet} {data.brandCity} {data.brandState}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;

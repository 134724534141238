import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import CommonTable from "../../common/Table";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import {BASE_URL} from "./../../utils/Api"

const ModalUse = ({ brandDetails }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const data = brandDetails?.usersList?.map((key, index) => {
    return {
      key: index,
      displayPicture: key?.image || key.brandImage,
      name: key.userName || key.brandUserName,
      email: key.email || key.brandEmail,
    };
  });

  return (
    <div className="container">
      <div className="heading">
        <div style={{ marginLeft: "200px", marginTop: "-0px" }}>
          {" "}
          <Avatar icon={<Youtube />} />{" "}
        </div>
        <div
          style={{
            marginLeft: "185px",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <b>{brandDetails?.brandId?.brandUserName}</b>
        </div>
      </div>
      <div className="contentTable">
        <CommonTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default ModalUse;

import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import CommonTable from "../../common/Table";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";
import { getShareHoldersToExcel, BASE_URL } from "../../utils/Api";
import axios from "axios";

const ModalUse = ({ details, id, totalRecords, onSubmit }) => {
  const mainId = id;

  const onChange = async (e) => {
    onSubmit(e);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "No Of Shares",
      dataIndex: "share",
      key: "share",
      width: "20%",
    },
    {
      title: "Price per share",
      dataIndex: "pershare",
      key: "pershare",
      width: "20%",
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "cost",
      width: "20%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "20%",
    },
  ];

  const data = details?.holdingsData?.Brandlist?.map((key, index) => {
    return {
      key: index,
      name: key?.userId?.userName || key?.userId?.brandUserName,
      share: key?.numberOfShare,
      pershare: key?.listingPricePerShare,
      cost: key?.totalCost,
      date: moment(key.createdAt).format("LL"),
    };
  });

  // const BASE_URL = "http://54.190.192.105:5048/";
  const adminToken = localStorage.getItem("token");

  const holdingsToExcel = async () => {
    const res = await getShareHoldersToExcel(mainId);
  };

  return (
    <div className="container">
      <div className="heading">
        <div
          style={{
            marginLeft: "85px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <b>{details?.name}</b>
        </div>
      </div>
      <button
        className="exportdetail"
        onClick={holdingsToExcel}
        style={{
          marginLeft: "auto",
          backgroundColor: "orange",
          borderRadius: "5px",
          display: "block",
          marginBottom: "10px",
        }}
      >
        Export Details
      </button>
      <div className="contentTable">
        <CommonTable
          columns={columns}
          data={data}
          onChange={(e) => onChange(e)}
          total={totalRecords}
        />
      </div>
    </div>
  );
};

export default ModalUse;

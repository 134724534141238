import React, { useEffect, useState } from "react";
import UploadIcon from "../../../Assets/uploadIcon.svg";
import { getUserById, BASE_URL, updateBankEmail, withdrawrequestpayment } from "../../utils/Api";
import { Avatar, Image } from "antd";
import { Button, Form } from "react-bootstrap";
import {toast} from "react-toastify"

const EditModal = ({ userId, userType, amount, bankemailId, visible, setVisible,setAmounts,brandType}) => {
  const updateBankingEmailHandler = async function () {
    try {
      const res = await updateBankEmail(userId, bankemailId,userType,brandType);
      if (res?.status == 200) {
        console.log("successssssssssssssssssssss")
        const generateWithdrawReq = await withdrawrequestpayment(
          userType,
          brandType,
          userId,
          amount,
          bankemailId
        );
        if (generateWithdrawReq?.status === 200) {
          toast.success(generateWithdrawReq?.message);
          setAmounts("")
        } else {
          toast.error("Invalid amount.");
        }
        setVisible(false);
      } else {
        toast.error("Internal server error.");
      }
    } catch (error) {
      // Handle error
      console.error("Error updating bank email id:", error);
    }
  };

  const closeModalHandler = function () {
    setVisible(false);
  };

  return (
    <div className="managuser_modal">
      <div className="container">
        <div className="modal_content_box">
          <div className="d-block w-100 fs-5">
            Do you want to proceed with this bank email id{" "}
            <span className="fw-bold">{bankemailId}</span>?
            <div className="d-flex mt-4 justify-content-center align-items-center">
              <button className="btn btn-primary"  onClick={updateBankingEmailHandler}>Yes</button>
              <button className="mx-4 btn btn-danger" onClick={closeModalHandler}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

import { Button, Modal } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { makeListingPayements } from "../../utils/Api";

const PaymentModal = ({ id, selectedRecord }) => {

  let brandAmount;


  const mainId = id;
  console.log(selectedRecord, "iddddd")
  const [error, setError] = useState("");
  const [faqNameerror, setFaqNameError] = useState("");
  const [varError, setVarError] = useState("");
  const [faqNameValue, setFaqNameValue] = useState("");
  const [varNameValue, setVarNameValue] = useState("");
  const [subCatData, setSubCatData] = useState("");
  const [remenning, setRemening] = useState({
    remening:"",
    brandAmount:""
  })
  let soldShare = 1000000 - selectedRecord?.totalNumberOfShares
  
  const handleChange = (value, type) => {

    if (type === "name") {
      if (value === "") {
        setFaqNameError("Faq Type should not be blank.");
      } else {
        setFaqNameError("");
      }
      setSubCatData(value);
    } else if (type === "attname") {
      if (value === "") {
        setError("Question should not be blank.");
      } else {
        setError("");
      }
      setFaqNameValue(value);
    } else if (type === "varname") {
      if (value === "") {
        setVarError("Answer should not be blank.");
      } else {
        setVarError("");
      }
      setVarNameValue(value);


    }
    if (type === "amounttowithdraw") {
      if (value === "") {
        setRemening({
          remening: "",
          brandAmount: ""
        });
      } else {
        const remening = soldShare - value;
        const brandAmount = selectedRecord?.brandAdjustedSharePrice * value;
        setRemening({
          remening: remening,
          brandAmount: brandAmount
        });
      }
    } else {
      // Handle other input changes as before
      // ...
    }
    // if (type === "amounttowithdraw") {

    //   const remening = soldShare - value
    //    brandAmount  = selectedRecord?.brandAdjustedSharePrice * value;
    //   console.log(brandAmount, "remening")
    //   setRemening({
    //     remening:remening,
    //     brandAmount:brandAmount

    //   })

    // }
  };

  // const BASE_URL = "http://54.190.192.105:5048/";
  const adminToken = localStorage.getItem("token");

  // const handleFaq = async () => {
  //   const requestedData = {
  //     underwritingAmount: subCatData, //nameValue,
  //     adminFee: faqNameValue,
  //     brandAmount: varNameValue,
  //   };

  //   const response = await makeListingPayements(
  //     requestedData,
  //     adminToken,
  //     mainId
  //   );
  //   if (response?.data?.code === 200) {
  //     toast.success(response.data.message);
  //   } else {
  //     toast.error(response?.data?.message);
  //   }
  // };


  const handleFaq = async () => {
    // Get the values entered by the user
    const underwritingAmount = subCatData;
    const adminFee = (parseFloat(faqNameValue) / 100) * parseFloat(subCatData);


    if (remenning.remening < 0) {
      toast.error("Cannot proceed with payment. Insufficient remaining tokens.");
      return;
    }
    // Calculate the brand amount
    // const brandAmount = underwritingAmount - adminFee;
    // const brandAmount = selectedRecord?.brandAdjustedSharePrice * soldShare;

    console.log(brandAmount, "brandAmountv")

    setVarNameValue(brandAmount)
    // Create the request data object
    const requestedData = {
      underwritingAmount: selectedRecord?.brandAdjustedSharePrice,
      adminFee: soldShare,
      brandAmount: remenning.brandAmount,
    };

    const response = await makeListingPayements(
      requestedData,
      adminToken,
      mainId
    );

    if (response?.data?.code === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response?.data?.message);
    }
  };


  const userTransHistory = async () => {
    const data = {
      underwritingAmount: subCatData, //nameValue,
      adminFee: faqNameValue,
      brandAmount: varNameValue,
    };
    const res = await makeListingPayements(data);
    if (res?.status === 200) {
      //   setUserTransactions(res?.List);
    }
  };
  





  return (
    <>
      <div>
        <label style={{ marginBottom: "10px" }}>
          {/* Amount generated in Underwriting process */}
          Token Price at the time of Underwriting process
        </label>
        <div className="inputBoxCategoryCss">
          <input
            type="text"
            readOnly
            placeholder="Please Enter"
            name="varname"
            // value={subCatData}
            value={selectedRecord?.brandAdjustedSharePrice}


            onChange={(e) => handleChange(e.target.value, "name")}
            className="inputFields"
            style={{
              width: "350px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
      <div>
        {/* <label style={{ marginBottom: "10px" }}>MBX fee deduction</label> */}
        <label style={{ marginBottom: "10px" }}>No. of Sold Shares</label>
        <div className="">
          <input
            type="text"
            readOnly
            placeholder="Please Enter"
            name="varname"
            // value={faqNameValue}
            value={soldShare}

            onChange={(e) => handleChange(e.target.value, "attname")}
            className="inputFields"
            style={{
              width: "350px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>

      <div>
        {/* <label style={{ marginBottom: "10px" }}>MBX fee deduction</label> */}
        <label style={{ marginBottom: "10px" }}>No. of token amount to withdraw</label>
        <div className="">
          <input
            type="number"
            placeholder="Please Enter"
            name="amounttowithdraw"
            // value={faqNameValue}

            onChange={(e) => handleChange(e.target.value, "amounttowithdraw")}
            className="inputFields"
            style={{
              width: "350px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          />
   
          <span>
         / {remenning.remening}
          </span>
        </div>
      </div>
      <div>
        <label style={{ marginBottom: "10px" }}>
          {/* Amount transferable to the brand after deduction */}
          Amount eligible to withdraw
        </label>
        <div className="inputBoxCategoryCss">
          <input
            type="text"
            readOnly
            placeholder="Please Enter"
            name="varname"
            value={remenning.brandAmount}
            onChange={(e) => handleChange(e.target.value, "varname")}
            className="inputFields"
            style={{
              width: "350px",
              bottom: "25px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>

      <button
        onClick={() => handleFaq()}
        style={{
          margin: "10px",
          marginLeft: "350px",
          borderRadius: "5px",
          backgroundColor: "orange",
        }}
      >
        Make Payment
      </button>
      {/* </Modal> */}
    </>
  );
};
export default PaymentModal;

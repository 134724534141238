import React from "react";
import {
  Table,
} from "antd";
import "./style.scss";
import "antd/dist/antd.css";
import CommanPagination from "../CommanPagination/CommanPagination";

const CommonTable = (props) => {
  const { columns, data, onChange, total, totalPages, perPage } = props;


  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 500 }}
        className="oe"
      />
      <CommanPagination
        onChange={onChange}
        total={total}
        totalPages={totalPages}
        defaultPageSize={perPage}
      />
    </>
  );
};

export default CommonTable;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  message,
  Pagination,
  Image,
  Input,
  Menu,
  Space,
  Dropdown,
  Button,
  Modal,
  Table,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import CommonTable from "../../common/Table";
import {
  UserProfileListing,
  DownloadEmailpdf,
  getUserHoldings,
  getUserTransactions,
  getUserProfileToExcel,
  UserProfileListingwithShowData,
  BASE_URL
} from "../../utils/Api";
import ModalUse from "./ModalUse";
import ModalTransaction from "./ModalTransaction";

import "./style.scss";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [userdata, setuserData] = useState();
  const [pageno, setpageno] = useState("1");
  const [modalPageNo, setModalPageNo] = useState("1");
  const [traModalPageNo, setTraModalPageNo] = useState("1");
  const [total, settotal] = useState();
  // const [Items, setItem] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAModalVisible, setIsAModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [modalData, setModalData] = useState();
  const [modalTransactionData, setModalTransactionData] = useState();
  const [pageSize, setPageSize] = useState("");
  const [totalPages, settotalPages] = useState("");
  const [pageData, setPageData] = useState("");
  const [modalTotalRecord, setModalTotalRecord] = useState();
  const [modalTransactionRecord, setModalTransactionRecord] = useState();

  const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  let search = "";
  let pageNo = 1;

  const getuserlisting = async (searchData, page) => {
    const res = await UserProfileListing({ search: searchData, pageno: page });
    setuserData(res.data.userlist);
    settotal(res.data.total);
    settotalPages(res.data.totalPages);
  };

  useEffect(() => {
    getuserlisting(search, pageno);
  }, [pageno]); // removed total dependency

  useEffect(() => {
    getUserHoldings(id, modalPageNo)
      .then((res) => {
        const data = {
          name: "User Holdings",
          type: 1,
          holdingsData: res,
        };
        setModalData(data);
        setModalTotalRecord(data?.holdingsData?.totalRecords);
        return res.data;
      })
      .catch((err) => {});
  }, [modalPageNo]);

  useEffect(() => {
    getUserTransactions(id, traModalPageNo)
      .then((res) => {
        const tData = {
          name: "User Transactions",
          transactionData: res,
          type: 2,
          id: id,
        };
        setModalTransactionData(tData);
        setModalTransactionRecord(tData?.transactionData?.totalRecords);
        return res.data;
      })
      .catch((err) => {});
  }, [traModalPageNo]);

  // const getTransactionData = async (id) => {
  //   const res = await getUserTransactions(id);
  //   if (res?.status === 200) {
  //     setModalData({
  //       name: "User Transactions",
  //       Transactiondata: res?.data,
  //       type: 2,
  //       id: id,
  //     });
  //   } else {
  //     toast.warning("Somting went wrong");
  //   }
  // };

  const handleMenuClick = async (e) => {
    // message.info("Click on menu item.");
    if (e?.key === "1") {
      const res = await getUserHoldings(id, modalPageNo);
      if (res?.status === 200) {
        const data = {
          name: "User Holdings",
          type: 1,
          holdingsData: res,
        };
        // modalData.push(data);
        setModalData(data);
        setModalTotalRecord(data?.holdingsData?.totalRecords);
        showModal();
      } else {
        toast.warning("Somting went wrong");
      }
    } else {
      const res = await getUserTransactions(id, modalPageNo);
      if (res?.status === 200) {
        const tData = {
          name: "User Transactions",
          transactionData: res,
          type: 2,
          id: id,
        };
        setModalTransactionData(tData);
        setModalTransactionRecord(tData?.transactionData?.totalRecords);
        showAModal();
      } else {
        toast.warning("Somting went wrong");
      }
    }
    // showAModal();
  };
  const getRowData = (record) => {
    setId(record?.id);
  };

  const handlePageData = async (e, page) => {
    setPageData(e);
    // getuserlisting()
    //
    const res = await UserProfileListingwithShowData(pageno, e);
    if (res?.status === 200) {
      setuserData(res?.data?.userlist);
      // settotal(res?.data?.totalPages);
      settotal(res.data.total);
      // settotalPages(res?.data?.totalPages);
    }
  };

  const getPageData = (data) => {
    // setModalPageNo(data);
    setTraModalPageNo(data);
  };

  const getHoldingPageData = (data) => {
    setModalPageNo(data);
    // setTraModalPageNo(data);
  };

  const getPageSize = (data) => {
    setPageSize(data);
    // setTraModalPageNo(data);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setModalPageNo("");
    setModalTotalRecord("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalPageNo("");
    setModalTotalRecord("");
  };

  //

  const showAModal = () => {
    setIsAModalVisible(true);
  };

  const handleAOk = () => {
    setIsAModalVisible(false);
    setTraModalPageNo("");
    setModalTransactionRecord("");
  };

  const handleACancel = () => {
    setIsAModalVisible(false);
    setTraModalPageNo("");
    setModalTransactionRecord("");
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "View Holding",
          key: "1",
          // icon: <UserOutlined />,
        },
        {
          label: "View Transactions",
          key: "2",
          // icon: <UserOutlined />,
        },
      ]}
    />
  );
  const columns = [
    {
      title: " Username",
      dataIndex: "name",
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "SSN",
      dataIndex: "SSN",
      key: "SSN",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              onClick={() => {
                getRowData(record);
              }}
            >
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const data =
    userdata?.map((key, index) => {
      return {
        id: key?.id,
        key: index,
        displayPicture: key?.image,
        name: key?.userName,
        email: key?.email,
        Address:
          key?.street +
          ", " +
          key?.city +
          ", " +
          key?.state +
          ", " +
          key?.zipCode,
        SSN: key?.security_number,
      };
    }) || [];

  const handleSearch = async (nameSearch) => {
    const res = await UserProfileListing({
      search: nameSearch,
      pageno: pageno,
    });
    setuserData(res.data.userlist);
  };

  const adminToken = localStorage.getItem("token");
  const ExportToExcel = async () => {
    const res = await getUserProfileToExcel(adminToken);
  };

  const onChange = async (e) => {
    // e.preventDefault()
    setpageno(e);
    sessionStorage.setItem("p", e);
    pageNo = e;
    getuserlisting(e);
  };
  return (
    <div className="userProfile">
      <div className="userprofile_label">
        <h4>User Profile </h4>
        <button className="exportdetail" onClick={() => ExportToExcel()}>
          Export Details
        </button>
      </div>
      <div className="userTable">
        <div className="" style={{ display: "flex", flexShrink: "0" }}>
          <div style={{ width: "100%" }}>
            <Input.Search
              allowClear
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* <div> */}
          {/* <select
              className=""
              style={{
                marginLeft: "30px",
                marginTop: "",
                width: "150px",
                height: "30px",
              }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handlePageData(e.target.value);
              }}
            >
              {[10, 50, 100, 200].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          {/* </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            className: "customPagination",
            showQuickJumper: {
              goButton: <button className="go_button">Go</button>,
            },
            defaultCurrent: 1,
            onChange: (page, pageSize) => {
              setpageno(page);
              handlePageData(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "200"],
            total: total,
            // showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: 500 }}
          className="oe"
        />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="user-profile-modal modal-height-fix"
      >
        <ModalUse
          details={modalData}
          totalRecords={modalTotalRecord}
          onSubmit={getHoldingPageData}
        />
      </Modal>
      <Modal
        visible={isAModalVisible}
        onOk={handleAOk}
        onCancel={handleACancel}
        className="user-profile-modal modal-height-fix"
      >
        <ModalTransaction
          details={modalTransactionData}
          transactionRecords={modalTransactionRecord}
          onSubmit={getPageData}
        />
      </Modal>
    </div>
  );
};

export default UserProfile;

import React, { useEffect, useState } from "react";
import { Tabs, Avatar, Button, Input, Image, DatePicker } from "antd";
import CommonTable from "../../common/Table/tradingTable";
// import "./style.scss";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import { completedSellList, completedSellListWithDates, BASE_URL } from "../../utils/Api";
import moment from "moment";
import { toast } from "react-toastify";

const { TabPane } = Tabs;

const CompletedSell_Trades = () => {
  const [brandlist, setbrandlist] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [activeStatusData, setActiveStatusData] = useState([]);
  const [underwritingStatusData, setUnderwritingStatusData] = useState([]);
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");
  const [pageno, setpageno] = useState("1");
  const [perPage, setPerPage] = useState(50);


  useEffect(() => {
    getActiveStatus();
    // getUnderwritingStatus(search, page);
  }, []);

  useEffect(() => {
    handleDate();
  }, [pageno]);

  const getActiveStatus = async (searchTerm, pageno) => {
    const response = await completedSellList(searchTerm, 1); //(searchTearm);
    if (response?.status === 200) {
      if (response.List && response.List.length !== 0) {
        for (let i = 0; i < response.List.length; i++) {
          let element = response.List[i];
          response.List[i]["displayStatus"] =
            response.List[i].isCancelled === false ? (
              <label className="">Active</label>
            ) : (
              <label className="">Cancelled</label>
            );
          response.List[i]["displayName"] =
            response.List[i].brandId.brandUserName;
        }
      }
      setActiveStatusData(response?.List);
      setTotal(response?.totalRecords);
      setPerPage(response?.per_page)
    }
  };

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  const handleDate = async () => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
      datePageNo: pageno,
      // search: search,
    };

    const response = await completedSellListWithDates(requestedData);

    if (response?.status === 200) {
      if (response.List && response.List.length !== 0) {
        for (let i = 0; i < response.List.length; i++) {
          let element = response.List[i];
          response.List[i]["displayStatus"] =
            response.List[i].isCancelled === false ? (
              <label className="">Active</label>
            ) : (
              <label className="">Cancelled</label>
            );
          response.List[i]["displayName"] =
            response.List[i].brandId.brandUserName;
        }
      }
      // toast.success(response.message);
      setActiveStatusData(response?.List);
      setTotal(response?.totalRecords);
    } else {
      toast.error(response.message);
    }
  };

  function onChange(e) {
    setpageno(e);
  }

  const columns = [
    {
      title: "Brand",
      dataIndex: "displayName",
      key: "displayName",
      width: "15%",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Order State",
      dataIndex: "statusType",
      key: "statusType",
      width: "12%",
    },
    {
      title: "Order Status",
      dataIndex: "displayStatus",
      key: "displayStatus",
      width: "12%",
    },
    {
      title: "Price Per Share",
      dataIndex: "costPricePerShare",
      key: "costPricePerShare",
      width: "12%",
    },
    {
      title: "Executed Quantity",
      dataIndex: "executedQuantity",
      key: "executedQuantity",
      width: "10%",
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      width: "12%",
    },
    {
      title: "Number of Shares",
      dataIndex: "numberOfShare",
      key: "numberOfShare",
      width: "12%",
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      with: "10%",
    },
  ];

  const data =
    activeStatusData?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.brandId?.brandImage,
        displayName: key?.displayName,
        statusType: key?.statusType,
        displayStatus: key?.displayStatus,
        costPricePerShare: key?.costPricePerShare,
        executedQuantity: key?.executedQuantity,
        totalCost: key?.totalCost,
        numberOfShare: key?.numberOfShare,
        createdAt: moment(key.createdAt).format("LL"),
      };
    }) || [];

  const handleSearch = async (nameSearch) => {
    getActiveStatus(nameSearch);
  };

  return (
    <div className="Brand_listing">
      <div className="label_brand_listing">
        <h4> Completed_Sell_Trades </h4>
        {/* <CommanCalender /> */}
        <div className="mainCalender">
          <div className="date_pick_box">
            <label>
              <b>Select Date</b>
            </label>
            <label className="input_label">From</label>
            <DatePicker className="dateInput" onChange={onChangeStart} />
            <label className="input_label">To</label>
            <DatePicker className="dateInput" onChange={onChangeEnd} />
            <button className="buttonMain" onClick={handleDate}>
              View
            </button>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="1" centered>
        {/* <TabPane tab="Completed Sell Trades" key="1"> */}
        <div className="branding_list">
          <Input.Search
            allowClear
            placeholder="Search Brand Listed"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className="transaction_table_sec">
          <CommonTable
            data={data}
            columns={columns}
            onChange={(e) => onChange(e)}
            total={total}
            perPage={perPage}
          />
        </div>
        </div>
        {/* </TabPane> */}
      </Tabs>
    </div>
  );
};

export default CompletedSell_Trades;

import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.scss";
import { getStaticPage, updateStaticPage } from "../../utils/Api";
import { toast } from "react-toastify";

const Support = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    getStaticPageData();
  }, []);

  const getStaticPageData = async () => {
    const res = await getStaticPage(6);
    if (res?.status === 200) {
      setTitle(res?.data?.title);
      setId(res?.data?._id);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(`${res?.data?.content}`)
          )
        )
      );
    } else {
      toast.error("Something went wrong! please try again");
    }
  };

  const handleUpdate = async () => {
    if (convertedContent === null) {
      toast.warning("Please enter some content");
      return;
    }
    const data = {
      content: convertedContent,
    };
    setLoading(true);
    const res = await updateStaticPage(id, data);
    if (res?.status === 200) {
      setLoading(false);
      getStaticPageData();
      toast.success("Updated successfully");
    } else {
      setLoading(false);
      toast.error("Something went wrong! please try again");
    }
  };

  return (
    <div className="static-container">
      <div className="static-label">
        <h4>Static Page</h4>
      </div>
      <div className="page_title">
        <text>Page Title</text>
        <br />
        <input
          type="text"
          value={title}
          readOnly
          placeholder="Enter page title"
        />
      </div>
      <div className="editor_state">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          placeholder="Type Here........."
        />
      </div>
      <button
        className="saveButton"
        disabled={loading}
        type="button"
        onClick={() => {
          handleUpdate();
        }}
      >
        {loading ? "Please wait..." : "Save"}
      </button>
    </div>
  );
};

export default Support;

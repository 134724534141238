import React, { useEffect, useState } from "react";
import { Avatar, Input, DatePicker, Image, Table } from "antd";
import CommonTable from "../../common/Table";
import "./style.scss";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import {
  fullValueBrandList,
  fullValueBrandListWithDates,
  fullValueBrandListwithShowData,
  BASE_URL
} from "../../utils/Api";
import moment from "moment";
import { toast } from "react-toastify";

const Full_Value_Brands = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [fullValueBrandData, setFullValueBrandData] = useState([]);
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [pageno, setpageno] = useState("1");

  useEffect(() => {
    getfullValueBrand(search, pageno);
  }, [pageno]);

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  const handleDate = async () => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
    };

    const response = await fullValueBrandListWithDates(requestedData);

    if (response?.status === 200) {
      toast.success(response.message);
      setFullValueBrandData(response.Brandlist);
    } else {
      toast.error(response.message);
    }
  };

  const handlePageData = async (e, page) => {
    const res = await fullValueBrandListwithShowData(pageno, e);
    if (res?.status === 200) {
      setFullValueBrandData(res?.data?.Brandlist);
      setTotal(res?.data?.total);
    }
  };

  const getfullValueBrand = async (searchTearm, pageNo) => {
    const response = await fullValueBrandList(searchTearm, pageNo);
    if (response?.status === 200) {
      setFullValueBrandData(response?.Brandlist);
      setTotal(response?.totalRecords);
    }
  };

  const columns = [
    {
      title: "Brands",
      dataIndex: "name",
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },

    {
      title: "Original Target Values",
      dataIndex: "OriginalTargetValues",
      key: "OriginalTargetValues",
    },
    {
      title: "Underwriting Values",
      dataIndex: "UnderwritingValues",
      key: "UnderwritingValues",
    },
    {
      title: "Final Values",
      dataIndex: "FinalValues",
      key: "FinalValues",
    },
  ];
  const data =
    fullValueBrandData?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.brandImage,
        name: key?.brandUserName,
        OriginalTargetValues: `$ ${key?.brandValue}`,
        UnderwritingValues: `$ ${key?.brandValue}`,
        FinalValues: `$ ${key?.brandValue}`,
        Date: moment(key.createdAt).format("LL"),
      };
    }) || [];
  // const onChange = async (e) => {
  //   setPage(e);
  //   getfullValueBrand(search, e);
  // };

  const onChange = async (e) => {
    // e.preventDefault()
    // setPage(e);
    setpageno(e);
    getfullValueBrand(search, e);
  };

  const handleSearch = async (nameSearch) => {
    getfullValueBrand(nameSearch, page);
  };
  return (
    <div className="full_value_container">
      <div className="label_ValueBrands">
        <h4>Full Value Brands</h4>
        {/* <CommanCalender /> */}
        <div className="mainCalender">
          <div className="date_pick_box">
            <label>
              <b>Select Date</b>
            </label>
            <label className="input_label">From</label>
            <DatePicker className="dateInput" onChange={onChangeStart} />
            <label className="input_label">To</label>
            <DatePicker className="dateInput" onChange={onChangeEnd} />
            <button className="buttonMain" onClick={handleDate}>
              View
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <div className="table">
          <div className="branding_list">
            <div className="" style={{ display: "flex", flexShrink: "0" }}>
              <div style={{ width: "100%" }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* <div>
                <select
                  className=""
                  style={{
                    marginLeft: "30px",
                    marginTop: "",
                    width: "150px",
                    height: "30px",
                  }}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handlePageData(e.target.value);
                  }}
                >
                  {[10, 50, 100, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              className: "customPagination",
              showQuickJumper: {
                goButton: <button className="go_button">Go</button>,
              },
              defaultCurrent: 1,
              onChange: (page, pageSize) => {
                setpageno(page);
                handlePageData(pageSize);
              },
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "200"],
              total: total,
              // showTotal: (total) => `Total ${total} items`,
            }}
            scroll={{ y: 500 }}
            className="oe"
          />
        </div>
      </div>
    </div>
  );
};
export default Full_Value_Brands;

import React, { useEffect, useState } from "react";
import UploadIcon from "../../../Assets/uploadIcon.svg";
import "./edit.scss";
import { getUserById, BASE_URL } from "../../utils/Api";
import { Avatar, Image } from "antd";
import { Button, Form } from "react-bootstrap";

const EditModal = ({ id, visible }) => {
  const [data, setData] = useState("");
  useEffect(() => {
    const getData = async () => {
      const data = await getUserById(id);
      setData(data.Data);
    };
    getData();
  }, [id]);

  return (
    <div className="managuser_modal">
      <div className="container">
        <div className="modal_imgBox">
          <Avatar
            src={
              <Image src={`${BASE_URL}/images/${data.image}`} />
            }
          />
        </div>
        <div className="modal_content_box">
          <div className="header_box">
            <div className="row">
              <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                Username
              </div>
              <div className="col-lg-6">{data.userName}</div>
            </div>
          </div>

          <div className="row_outerBox">
            <div className="row_box">
              <div className="row">
                <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                  Email
                </div>
                <div className="col-lg-6">{data.email}</div>
              </div>
            </div>

            <div className="row_box">
              <div className="row">
                <div className="col-lg-6" style={{ fontWeight: "bold" }}>
                  Address
                </div>
                <div className="col-lg-6">
                  {data.street} {data.city} {data.state} {data.zipCode}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

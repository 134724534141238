import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { ReactComponent as Dotimage } from "../../../Assets/Dot.svg";
import { useLocation, useNavigate } from "react-router";
import user from "../../../Assets/Group.svg";
import Refund from "../../../Assets/refund.svg";
import DataAnalytics from "../../../Assets/Frame.svg";
import StaticIcon from "../../../Assets/staticpage.svg";
import "./style.scss";

const CommonMenu = (props) => {
  let navigate = useNavigate();
  const [submenu, setSubmenu] = useState([]);

  const { menuItem, tab, settab, pathhref } = props;

  function getItem(label, key, icon, children, type, route, disabled) {
    return {
      key,
      icon,
      children,
      label,
      type,
      route,
      disabled,
    };
  }

  const RefundRequest = (navigate) => {
    return (
      <span
        onClick={() => navigate(`/refundrequest`)}
        className={
          pathhref == "/refundrequest" ? "label-color_active" : "label-color"
        }
      >
        Refund Requests
      </span>
    );
  };
  const staticPage = (navigate) => {
    return (
      <span
        onClick={() => navigate(`/staticpage`)}
        className={
          pathhref == "/staticpage" ? "label-color_active" : "label-color"
        }
      >
        Static Page
      </span>
    );
  };

  const changePass = (navigate) => {
    return (
      <span
        onClick={() => navigate(`/changepassword`)}
        className={
          pathhref == "/changepassword" ? "label-color_active" : "label-color"
        }
      >
        Change Password
      </span>
    );
  };

  const items = (navigate) => [
    getItem("User Management", "sub1", <img src={user} />, [
      getItem("Manage User", "1", <Dotimage />, null, null, "/manageuser"),
      getItem("Manage Brand", "2", <Dotimage />, null, null, "/managebrand"),
    ]),
    getItem(" Data Analytics", "sub2", <img src={DataAnalytics} />, [
      getItem(
        "Net Process Per User",
        "3",
        <Dotimage />,
        null,
        null,
        "/datanetprocess"
      ),
      // getItem(
      //   "Underwriting Proceeds Per Brand",
      //   "3",
      //   <Dotimage />,
      //   null,
      //   null,
      //   "/datanetprocess"
      // ),
      getItem("User Profiles", "4", <Dotimage />, null, null, "/userProfile"),
      getItem(
        "Brand Profiles",
        "14",
        <Dotimage />,
        null,
        null,
        "/brandProfile"
      ),
      getItem(
        "Current Brand Value",
        "5",
        <Dotimage />,
        null,
        null,
        "/currentbrandvalue"
      ),
      getItem(
        "Total Value of Platform",
        "6",
        <Dotimage />,
        null,
        null,
        "/totalvalueofplatform"
      ),
      getItem(
        "Brand Distribution List",
        "7",
        <Dotimage />,
        null,
        null,
        "/branddistributionlist"
      ),
      getItem(
        "Transaction History",
        "8",
        <Dotimage />,
        null,
        null,
        "/transactionhistory"
      ),
    ]),
    getItem("Underwriting Admin", "sub3", <img src={user} />, [
      getItem("Brand Listing", "9", <Dotimage />, null, null, "/brandlist"),
      getItem("Short Sellers", "10", <Dotimage />, null, null, "/shortseller"),
      getItem(
        "Full Value Brands",
        "11",
        <Dotimage />,
        null,
        null,
        "/fullvaluebrand"
      ),
    ]),

    getItem(
      RefundRequest(navigate),
      "12",
      <img src={Refund} />,
      [],
      null,
      "/refundrequest",
      true
    ),
    getItem("Static Page", "sub4", <img src={StaticIcon} />, [
      getItem("About", "13", <Dotimage />, null, null, "/about"),
      getItem("Core Values", "15", <Dotimage />, null, null, "/coreValues"),
      getItem(
        "Read Buying & Selling",
        "16",
        <Dotimage />,
        null,
        null,
        "/readbuyingselling"
      ),
      getItem(
        "Read Why List Your Brand",
        "17",
        <Dotimage />,
        null,
        null,
        "/whylistyourbrand"
      ),
      getItem(
        "Privacy Policy",
        "18",
        <Dotimage />,
        null,
        null,
        "/privacypolicy"
      ),
      getItem("Support", "19", <Dotimage />, null, null, "/support"),
      getItem(
        "User Agreement",
        "20",
        <Dotimage />,
        null,
        null,
        "/useragreement"
      ),
    ]),
    // added
    //
    getItem("Manage Trades", "sub5", <img src={user} />, [
      getItem("Open Sell Trades", "21", <Dotimage />, null, null, "/opensell"),
      getItem(
        "Completed Sell Trades",
        "22",
        <Dotimage />,
        null,
        null,
        "/completedsell"
      ),
      getItem("Open Buy Trades", "23", <Dotimage />, null, null, "/openbuy"),
      getItem(
        "Completed Buy Trades",
        "24",
        <Dotimage />,
        null,
        null,
        "/completedbuy"
      ),
    ]),
    //
    getItem("Brand Payments", "sub8", <img src={user} />, [
      getItem(
        "Unsettled Brand List",
        "25",
        <Dotimage />,
        null,
        null,
        "/unsettledbrandlist"
      ),
    ]),
    getItem("User Withdrawal Request", "sub9", <img src={user} />, [
      getItem(
        "User Withdrawal Request",
        "26",
        <Dotimage />,
        null,
        null,
        "/userwithdrawalrequest"
      ),
    ]),
    getItem("Brand Withdrawal Request", "sub10", <img src={user} />, [
      getItem(
        "Brand Withdrawal Request",
        "27",
        <Dotimage />,
        null,
        null,
        "/brandwithdrawalrequest"
      ),
    ]),
    getItem(),
    getItem(),
  ];

  const reDirectTab = (path, settab) => {
    switch (path) {
      case "1":
        return settab(1);
      case "2":
        return settab(2);
      case "3":
        return settab(3);
      case "4":
        return settab(4);
      case "5":
        return settab(5);
      case "6":
        return settab(6);
      case "7":
        return settab(7);
      case "8":
        return settab(8);
      case "9":
        return settab(9);
      case "10":
        return settab(10);
      case "11":
        return settab(11);
      case "12":
        return settab(12);
      case "13":
        return settab(13);
      case "14":
        return settab(14);
      case "15":
        return settab(15);
      case "16":
        return settab(16);
      case "17":
        return settab(17);
      case "18":
        return settab(18);
      case "19":
        return settab(19);
      case "20":
        return settab(20);
      case "21":
        return settab(21);
      case "22":
        return settab(22);
      case "23":
        return settab(23);
      case "24":
        return settab(24);
      case "25":
        return settab(25);
      case "26":
        return settab(26);
        case "27":
          return settab(27)
      default:
        return settab(1);
    }
  };

  useEffect(() => {
    setSubmenu(["sub1"]);
  }, []);
  const allOptions = items()?.reduce((acc, item) => {
    item?.children?.map((child) => {
      acc.push(child);
    });
    return acc;
  }, []);

  // const onClick = (value, setTabFun) => {
  //   reDirectTab(value?.key, setTabFun);
  //   const findKey = allOptions
  //     ?.filter((option) => option?.key == value?.key)
  //     ?.at(0); // instead of filter we can use find
  //   navigate(`${findKey.route}`);
  // };
  const onClick = (value, setTabFun) => {
    if (!value || !value.key) {
      return;
    }
    reDirectTab(value.key, setTabFun);
    const findKey = allOptions.find((option) => option?.key === value.key);
    if (findKey) {
      navigate(findKey.route);
    }
  };

  const href = window.location.pathname;
  return (
    <div
      className="ant-table-body"
      style={{
        // overflowY: "scroll",
        overflowX: "hidden",
        // maxHeight: "660px",
        // position: "fixed",
      }}
    >
      <Menu
        onClick={(value) => onClick(value, settab)}
        style={{
          width: "300",
          // height: "1200px",
        }}
        defaultOpenKeys={["sub1"]}
        selectedKeys={[`${tab}`]}
        mode="inline"
        className="menu-item-styl"
        items={items(navigate)}
      />
    </div>
  );
};

export default CommonMenu;

import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../../Assets/MBXlogo.svg";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../utils/Api";
import {
  FaAngleRight,
  FaFacebookF,
  FaGoogle,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";

function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordIsRequiredError, setOldPasswordIsRequiredError] =
    useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordIsRequiredError, setNewPasswordIsRequiredError] =
    useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCnfPassword, setIsShowCnfPassword] = useState(false);

  let err = false;
  let token = localStorage.getItem("token");

  const handleOldPass = (value) => {
    if (value === "") {
      err = true;
      setOldPasswordIsRequiredError(true);
      return;
    } else {
      setOldPasswordIsRequiredError(false);
      err = false;
      setOldPassword(value);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      err = true;
      setCharNumberValid(true);
    } else {
      err = false;
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      err = true;
      setSpecialCharValid(true);
    } else {
      err = false;
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      err = true;
      setUppercaseValid(true);
    } else {
      err = false;
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      err = true;
      setNumberValid(true);
    } else {
      err = false;
      setNumberValid(false);
    }
  };

  const passwordFunction = () => {
    if (numberValid && uppercaseValid && charNumberValid && specialCharValid) {
      setPasswordError(false);
      err = false;
    } else {
      err = true;
      setPasswordError(true);
    }
  };

  const handleNewPass = (value) => {
    if (value === "") {
      err = true;
      //   setPasswordIsRequiredError(true);
      setPasswordError(false);
      setNumberValid(true);
      setUppercaseValid(true);
      setSpecialCharValid(true);
      setCharNumberValid(true);
      return;
    } else {
      checkPasswordLength(value);
      checkSpecialCharacters(value);
      checkUppercase(value);
      checkNumber(value);
      passwordFunction();
      setPasswordIsRequiredError(false);
      err = false;
      setPassword(value);
    }
  };

  const handleConfirmPass = (value) => {
    if (value === "") {
      setNewPasswordIsRequiredError(true);
    } else {
      setNewPasswordIsRequiredError(false);
      setConfirmPassword(value);
    }

    if (password !== value) {
      setConfirmPasswordError(true);
      err = true;
      // return;
    } else {
      err = false;
      setConfirmPassword(value);
      setNewPasswordIsRequiredError(false);
      setConfirmPasswordError(false);
    }
  };

  const handlePassword = async (e) => {
    e.preventDefault();

    handleConfirmPass(confirmPassword);

    if (oldpassword === "") {
      setOldPasswordIsRequiredError(true);
      // return;
    } else {
      setOldPasswordIsRequiredError(false);
    }

    if (password === "") {
      setPasswordIsRequiredError(true);
      toast.warning("Please Enter Password ");
      // return;
    } else {
      setPasswordIsRequiredError(false);
    }

    if (confirmPassword === "") {
      setNewPasswordIsRequiredError(true);
    } else {
      setNewPasswordIsRequiredError(false);
    }

    if (oldpassword !== "" && password !== "" && oldpassword === password) {
      err = true;
      toast.warning("Old password and new password should be differnt");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      toast.warning("Password mismatch");
      // return;
    } else {
      setConfirmPasswordError(false);
    }

    if (
      !oldPasswordIsRequiredError &&
      !passwordIsRequiredError &&
      !passwordError &&
      !confirmPasswordError &&
      !confirmPasswordIsRequiredError &&
      password === confirmPassword
    ) {
      const requestedData = {
        oldPassword: oldpassword,
        newPassword: password,
        confirmPassword: confirmPassword,
      };

      const response = await changePassword(requestedData, token);
      if (response?.data?.status === 200) {
        localStorage.removeItem("token");
        toast.success(response?.data?.message);
        window.location.href = "/";
        // navigate(`/`);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const handleOldPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowOldPassword(true);
    } else {
      setIsShowOldPassword(false);
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  const handleShowCnfPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCnfPassword(true);
    } else {
      setIsShowCnfPassword(false);
    }
  };

  return (
    <>
      <section className="main-wrapper-login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="Login-wrapper">
                <div className="login-wrap-logo text-center">
                  <img src={Logo} alt="Logo" />
                </div>
                <div className="forgt-txt-head text-center">
                  <h1>Change Password</h1>
                </div>
                <div className="loginbody--form">
                  <Form>
                    <Row>
                      <Col xs="12">
                        <div className="form-group">
                          <Form.Control
                            onChange={(event) =>
                              handleOldPass(event.target.value)
                            }
                            type={!isShowOldPassword ? "password" : "text"}
                            className="input-login-control"
                            placeholder="Old Password"
                            name="password"
                          />
                          <span>
                            {!isShowOldPassword ? (
                              <FaEye
                                onClick={(e) => handleOldPassword(e, "show")}
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={(e) => handleOldPassword(e, "hide")}
                              />
                            )}
                          </span>
                        </div>
                      </Col>
                      {oldPasswordIsRequiredError && (
                        <div style={{ color: "red" }}>
                          Old Password is required
                        </div>
                      )}
                      <Col xs="12">
                        <div className="form-group">
                          <Form.Control
                            onChange={(event) =>
                              handleNewPass(event.target.value)
                            }
                            type={!isShowPassword ? "password" : "text"}
                            className="input-login-control"
                            placeholder="New Password"
                            name="password"
                          />
                          <span>
                            {!isShowPassword ? (
                              <FaEye
                                onClick={(e) => handleShowPassword(e, "show")}
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={(e) => handleShowPassword(e, "hide")}
                              />
                            )}
                          </span>
                        </div>
                      </Col>
                      {passwordError && (
                        <div style={{ color: "red" }}>
                          Password at least 8 characters in length.
                          <br />
                          Lowercase letters (a-z)
                          <br />
                          Uppercase letters (A-Z)
                          <br />
                          Numbers (0-9)
                          <br />
                          Special characters ($@$!%*?&) <br />
                        </div>
                      )}
                      {passwordIsRequiredError && (
                        <div style={{ color: "red" }}>
                          New Password is required
                        </div>
                      )}
                      <Col xs="12">
                        <div className="form-group">
                          <Form.Control
                            onChange={(event) =>
                              handleConfirmPass(event.target.value)
                            }
                            type={!isShowCnfPassword ? "password" : "text"}
                            className="input-login-control"
                            placeholder="Confirm Password"
                            name="password"
                          />
                          <span>
                            {!isShowCnfPassword ? (
                              <FaEye
                                onClick={(e) =>
                                  handleShowCnfPassword(e, "show")
                                }
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={(e) =>
                                  handleShowCnfPassword(e, "hide")
                                }
                              />
                            )}
                          </span>
                        </div>
                      </Col>
                      {confirmPasswordError && (
                        <div style={{ color: "red" }}>Password mismatch</div>
                      )}
                      {confirmPasswordIsRequiredError && (
                        <div style={{ color: "red" }}>
                          Confirm Password is required
                        </div>
                      )}
                      <Col xs="12">
                        <div className="login-login">
                          <button
                            type="submit"
                            className="btn btn-login"
                            onClick={(e) => {
                              handlePassword(e);
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="login-back-btn text-center mt-3">
                          {" "}
                          <p>Back To Login </p>{" "}
                          <a
                            href="#"
                            className="btn btn-login-back"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Click Here
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ResetPassword;

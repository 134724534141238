import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Button,
  Menu,
  message,
  Modal,
  Image,
  Input,
} from "antd";
import moment from "moment";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import Cards from "../../common/card";
import CommonTable from "../../common/Table";
import { brandlisting, haltBrand, getBrandByName, BASE_URL } from "../../utils/Api";
import {
  EditOutlined,
  StopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ViewModal from "./ViewModal";
import profilethree from "../../../Assets/profilethree.png";
import profilefour from "../../../Assets/profilefour.png";
import "./style.scss";

const AddManageBrand = () => {
  const [branddata, setbranddata] = useState();
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  const [str, setStr] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewId, setViewId] = useState("");
  const [isHalted, setIsHalted] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [pageData, setPageData] = useState("");

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }

  const getbrandlisting = async () => {
    const res = await brandlisting(pageno, 10);
    setbranddata(res.data.Brandlist);
    settotal(res.data.total);
  };

  const handlePageData = async (e) => {
    setPageData(e);
    const res = await brandlisting(pageno, e);
    setbranddata(res.data.Brandlist);
    settotal(res.data.total);
  };

  useEffect(() => {
    getbrandlisting();
    sessionStorage.setItem("b", pageno);
  }, [pageno, total]);

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Halt Trading",
          key: "1",
          // icon: <UserOutlined />,
        },
        {
          label: "Reactivate",
          key: "2",
          // icon: <UserOutlined />,
        },
      ]}
    />
  );
  const deactivate = async (v) => {
    const id = branddata[v.key]._id;
    const res = await haltBrand({ brandId: id });
    setIsHalted(res.data.data);
    const p = sessionStorage.getItem("b");
    getbrandlisting();
    setpageno(p);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getEditData = async (v) => {
    setViewId(v.id);
    showModal();
  };
  const columns = [
    {
      title: "Brand Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
              src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      key: "Date",
    },

    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      filters: [
        { text: "Halt Trading", value: "Halt" },
        { text: "Active", value: "Active" },
      ],
      onFilter: (value, record) => record.tags.includes(value),
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = "#F48327";
            if (tag === "Active") {
              color = "#009E3F";
            } else {
              color = "#F48327";
            }
            return (
              <Tag color={color} key={tag}>
                {tag === "Active" ? "Active" : "Halt"}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Buy Share",
      dataIndex: "BuyShare",
      sorter: {
        compare: (a, b) => a.BuyShare - b.BuyShare,
        multiple: 2,
      },
      key: "BuyShare",
      render: (text, buyPicture) => (
        <>
          <a>
            {text}
            <Avatar src={<Image src={`${buyPicture.buyPicture}`} />} />
          </a>
        </>
      ),
    },
    {
      title: "Sell Share",
      dataIndex: "SellShare",
      sorter: {
        compare: (a, b) => a.SellShare - b.SellShare,
        multiple: 2,
      },
      key: "SellShare",
      render: (text, sellPicture) => (
        <>
          <a>
            {text}
            <Avatar src={<Image src={`${sellPicture.sellPicture}`} />} />
          </a>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => getEditData(record)}>View Brand</Button>
          <Button className="active" onClick={() => deactivate(record)}>
            {record?.tags[0] === "Active" ? "Halt" : "Re-Active"}
            {record?.tags[0] === "Active" ? (
              <StopOutlined />
            ) : (
              <CheckCircleOutlined />
            )}
          </Button>
        </Space>
      ),
    },
  ];

  const data =
    branddata?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.brandImage,
        name: key?.brandUserName,
        buyPicture: profilethree,
        sellPicture: profilefour,
        date: moment(key.createdAt).format("LL"),
        BuyShare: `$ ${key?.TotalBuyingAmount}`,
        SellShare: `$ ${key?.TotalSellingAmount}`,
        tags: [key?.isHaltTrading ? "Halt" : "Active"],
      };
    }) || [];

  const onChange = async (e) => {
    // e.preventDefault()
    setpageno(e);
    sessionStorage.setItem("b", e);
  };

  const handleSearch = async (nameSearch) => {
    const searchResponse = await getBrandByName({
      nameSearch,
      pageno: pageno,
      perPage: 4,
    });
    setbranddata(searchResponse.Brandlist);
  };

  return (
    <>
      <div className="usermanagecontainer p-0">
        <div className="cardmanager">
          <h4>Overview</h4>
          <Cards />
        </div>
        <div className="manageuser">
          <h4> Manage Brand</h4>
          <div className="table-responsive">
            <div className="managetable">
              <div className="" style={{ display: "flex", flexShrink: "0" }}>
                <div style={{ width: "100%" }}>
                  <Input.Search
                    allowClear
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
                {/* <div>
                  <select
                    className=""
                    style={{
                      marginLeft: "30px",
                      marginTop: "",
                      width: "150px",
                      height: "30px",
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handlePageData(e.target.value);
                    }}
                  >
                    {[10, 50, 100, 200].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
              <Table
                columns={columns}
                dataSource={data}
                pagination={{
                  className: "customPagination",
                  showQuickJumper: {
                    goButton: <button className="go_button">Go</button>,
                  },
                  defaultCurrent: 1,
                  onChange: (page, pageSize) => {
                    setpageno(page);
                    handlePageData(pageSize);
                  },
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "50", "100", "200"],
                  total: total,
                  // showTotal: (total) => `Total ${total} items`,
                }}
                scroll={{ y: 500 }}
                className="oe"
              />
            </div>
          </div>
        </div>
        <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <ViewModal id={viewId} visible={isModalVisible} />
        </Modal>
      </div>
    </>
  );
};

export default AddManageBrand;

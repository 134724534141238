
import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../../../Assets/MBXlogo.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { USERLOGIN, BrandLOGIN, SocialLOGIN } from "../../utils/Api";
import FacebookLogin from "react-facebook-login";
import { Constant } from '../../../../src/Constant/Constant'
// import InstagramLogin from 'react-instagram-login';
import { InstagramLogin } from '@amraneze/react-instagram-login';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


import "./style.scss";

function UserLogin() {
    const navigate = useNavigate();
    const [isShowPassword, setIsShowPassword] = useState(false);

    const [values, setValues] = useState({
        userName: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };


    const handleFacebookResponse = async (response) => {
        const userID = response.email;
        const socialType = 1; // 1 for Facebook
        try {
            const res = await fetch('https://app.mybrand.exchange:5048/userSocialLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userID, socialType }),
            }, error => {
           
            });
            const data = await res.json();
            if (data?.success) {
           
                navigate("/userwallet");
                localStorage.setItem("token", data?.data?.token);
                localStorage.setItem(
                    "resObj",
                    JSON.stringify(data?.data?.user_info.id)
                );
                localStorage.setItem(
                    "userType",
                    JSON.stringify(data?.data?.user_info.type)
                );
                toast.success(data?.message);
                window.location.reload();
                navigate("/userwallet");
            } else {
                toast.error(data?.message);
            }

        } catch (error) {
        }
    };

    const handleGoogleResponse = async (response) => {
        const userID = response.email;
        const socialType = 4; // Set the social type to 4 for user login

        try {
            const res = await fetch('https://app.mybrand.exchange:5048/userSocialLogin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userID, socialType })
            });

            const data = await res.json();
            if (data?.success) {
                navigate("/userwallet");
                localStorage.setItem("token", data?.data?.token);
                localStorage.setItem("resObj", JSON.stringify(data?.data?.user_info.id));
                localStorage.setItem("userType", JSON.stringify(data?.data?.user_info.type));
                toast.success(data?.message);
                window.location.reload();
                navigate("/userwallet");
            } else {
                toast.error(data?.message);
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        
        }
    };
    

    const handleInstagramResponse = async (response) => {
        console.log(response)
        const userID = response.email;
        const socialType = 3; // Set the social type to 3 for Instagram login
      
        try {
          const res = await fetch('https://app.mybrand.exchange:5048/userSocialLogin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userID, socialType })
          });
      
          const data = await res.json();
          console.log(data);
          if (data?.success) {
            navigate("/userwallet");
            localStorage.setItem("token", data?.data?.token);
            localStorage.setItem("resObj", JSON.stringify(data?.data?.user_info.id));
            localStorage.setItem("userType", JSON.stringify(data?.data?.user_info.type));
            toast.success(data?.message);
            window.location.reload();
            navigate("/userwallet");
          } else {
            toast.error(data?.message);
          }
        } catch (error) {
          toast.error("An error occurred. Please try again.");
        }
      };

    const login = async (e) => {
        e.preventDefault();

        try {
            const response = await USERLOGIN(values);
          
            if (response?.data.success) {
                navigate("/userwallet");
                localStorage.setItem("token", response?.data?.data?.token);
                localStorage.setItem(
                    "resObj",
                    JSON.stringify(response?.data?.data?.user_info.id)
                );
                localStorage.setItem(
                    "userType",
                    JSON.stringify(response?.data?.data?.user_info.type)
                );
                toast.success(response?.data?.message);
                window.location.reload();
                navigate("/userwallet");

            } else {
                const response2 = await BrandLOGIN({
                    brandUserName: values.userName,
                    brandPassword: values.password,
                });

                if (response2?.data.success) {
                    navigate("/userwallet");
                    localStorage.setItem("token", response2?.data?.data?.token);
                    localStorage.setItem(
                        "resObj",
                        JSON.stringify(response2?.data?.data?.Brand_info.id)
                    );
                    localStorage.setItem(
                        "brandType",
                        JSON.stringify(response2?.data?.data?.Brand_info.type)
                    );
                    toast.success(response2?.data?.message);
                    window.location.reload();
                    navigate("/userwallet");
                } else {
                    toast.error(response?.data?.message);
                }
            }
        } catch (error) {
            toast.error("Error occurred while logging in.");
        }
    }

    const responseFacebook = (response) => {
    };

    const handleShowPassword = (event, type) => {
        event.preventDefault();
        if (type === "show") {
            setIsShowPassword(true);
        } else {
            setIsShowPassword(false);
        }
    };
    return (
        <>
            <section className="main-wrapper-login">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="Login-wrapper">
                                <div className="login-wrap-logo text-center">
                                    <img src={Logo} alt="Logo" />
                                </div>
                                <div className="loginbody--form">
                                    <Form onSubmit={login}>
                                        <Row>
                                            <Col xs="12">
                                                <Form.Control
                                                    type="text"
                                                    className="input-login-control"
                                                    placeholder="Email"
                                                    name="userName"
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs="12">
                                                <div className="form-group">
                                                    <Form.Control
                                                        type={!isShowPassword ? "password" : "text"}
                                                        className="input-login-control mb-4"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={handleChange}
                                                    />
                                                    <span>
                                                        {!isShowPassword ? (
                                                            <FaEye
                                                                onClick={(e) => handleShowPassword(e, "show")}
                                                            />
                                                        ) : (
                                                            <FaEyeSlash
                                                                onClick={(e) => handleShowPassword(e, "hide")}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs="6">
                                                <div className="remember-me mb-5">
                                                    <span>
                                                        <input type="checkbox" />
                                                        <label className="ms-2 cursor-pointer">
                                                            Remember Me
                                                        </label>
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xs="6">
                                                <div className="forgot--txtlink text-end mb-5">
                                                    <span className="link-forgot cursor-pointer"
                                                        onClick={() => {
                                                            navigate("/forget-password");
                                                        }}>
                                                        Forgot password?
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col xs="12">
                                                <div className="login-login">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-login cursor-pointer"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="social-login">
                                    
                                        <div className="flex space-x-4 mb-4">
                                    
                                            <FacebookLogin
                                                appId={Constant.FACEBOOK_APP_ID}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                callback={handleFacebookResponse}
                                                cssClass="btnFacebook"
                                                 />
                                               
                                        </div>

                                        <div className="flex space-x-4 mb-4">
                                            <GoogleLogin

                                                onSuccess={credentialResponse => {
                                                    handleGoogleResponse(jwt_decode(credentialResponse?.credential));
                                                }}
                                                onError={() => {
                                                    console.log('Login Failed');
                                                }}
                                                useOneTap
                                             
                                            />
                                            

                                        </div>
                                        <div className="flex space-x-4 mb-4" >
                                            <InstagramLogin
                                                clientId={Constant.INSTAGRAM_CLIENT_ID}
                                                buttonText="Login with instagram"
                                                onSuccess={handleInstagramResponse}
                                                onFailure={handleInstagramResponse}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default UserLogin;
import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import CommonTable from "../common/Table";
// import { ReactComponent as Youtube } from "../../../Assets/youtube.svg";
import "./modal.scss";
import moment from "moment";

const ModalUse = ({ details, totalRecords, onSubmit }) => {
  const onChange = async (e) => {
    onSubmit(e);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  const data = details?.holdingsData?.Data?.map((key, index) => {
    return {
      key: index,
      title: key?.title,
      description: key?.description,
      date: moment(key.createdAt).format("LL"),
    };
  });

  return (
    <div className="container">
      <div className="heading">
        <div
          style={{
            marginLeft: "185px",
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <b>{details?.name}</b>
        </div>
      </div>
      <div className="contentTable">
        <CommonTable
          columns={columns}
          data={data}
          onChange={(e) => onChange(e)}
          total={totalRecords}
        />
      </div>
    </div>
  );
};

export default ModalUse;

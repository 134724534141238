import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Dropdown,
  Menu,
  message,
  Input,
  Modal,
  Button,
  Image,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  EditOutlined,
  StopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Cards from "../../common/card";
import CommonTable from "../../common/Table";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import EditModal from "./EditModal";
import { userlisting, EnableDisableUser, getUserByName,BASE_URL } from "../../utils/Api";
import "antd/dist/antd.css";
import { toast } from "react-toastify";
import profilethree from "../../../Assets/profilethree.png";
import profilefour from "../../../Assets/profilefour.png";

const AddManageUser = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userdata, setuserData] = useState([]);
  const [pageno, setpageno] = useState("1");
  const [total, settotal] = useState();
  const [viewId, setViewId] = useState("");
  const [isDeleted, setIsDeleted] = useState("");
  const [pageData, setPageData] = useState("");
  const modalRef = useRef(null);

  let pageNo = 1;

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handlePageData = async (e) => {
    const res = await userlisting(pageno, e);
    if (res?.status === 200) {
      setuserData(res?.data?.userlist);
      settotal(res?.data?.total);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleMenuClick(e) {
    showModal();

    //message.info("Click on menu item.");
    if (e.key === 3) {
    }
  }
  const getuserlisting = async () => {
    const res = await userlisting(pageno, 10);
    if (res?.status === 200) {
      setuserData(res?.data?.userlist);
      settotal(res?.data?.total);
    }
  };

  const getEditData = (v) => {
    setViewId(v.id);
    showModal();
  };

  const deactivate = async (v) => {
    const id = userdata[v.key]._id;
    const res = await EnableDisableUser({ userId: id });
    const p = sessionStorage.getItem("p");

    getuserlisting(p);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!modalRef || !modalRef.current) return false;
  //     if (modalRef.current.contains(event.target)) {
  //       return false;
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [modalRef]);

  useEffect(() => {
    // if (!token) {
    //   toast.error("Not Authorized please login!");
    //   navigate("/");
    // } else {
    sessionStorage.setItem("p", pageno);
    getuserlisting();
    // }
  }, [pageno, total]);

  const onChange = async (e) => {
    sessionStorage.setItem("p", e);
    pageNo = e;
    getuserlisting(e);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Edit Profile",
          key: "1",

          // icon: <UserOutlined />,
        },

        {
          label: "Deactivate",
          key: "3",
          // icon: <UserOutlined />,
        },
      ]}
    />
  );

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
      key: "name",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
              src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      key: "Date",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      filters: [
        { text: "Active", value: "Active" },
        { text: "Deactivated", value: "Deactivated" },
        { text: "Deleted", value: "Deleted" },
      ],
      onFilter: (value, record) => record.tags.includes(value),
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag.Active === "#009E3F";
            if (tag === "Deactivated") {
              color = "Red";
            } else if (tag === "Deleted") {
              color = "Orange";
            } else {
              color = "#009E3F";
            }
            {/* tag === 0 ? (tag === "Deactivated" ? color = "Red" : color = "#009E3F") : "Deleted"  */}
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Buy Share",
      dataIndex: "BuyShare",
      sorter: {
        compare: (a, b) => a.BuyShare - b.BuyShare,
        multiple: 2,
      },
      key: "BuyShare",
      render: (text, buyPicture) => (
        <>
          <a>
            {text}
            <Avatar src={<Image src={`${buyPicture.buyPicture}`} />} />
          </a>
        </>
      ),
    },
    {
      title: "Sell Share",
      dataIndex: "SellShare",
      sorter: {
        compare: (a, b) => a.SellShare - b.SellShare,
        multiple: 2,
      },
      key: "SellShare",
      render: (text, sellPicture) => (
        <>
          <a>
            {text}
            <Avatar src={<Image src={`${sellPicture.sellPicture}`} />} />
          </a>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => getEditData(record)}>View User</Button>
          {/* {console.log("record tags 46: ", record.tags)} */}

          {record.tags.map((tag) => {
            if (tag === "Deleted") {
          return <Button className="active" onClick={() => console.log("")}>
            Deactivate <StopOutlined />
          </Button>
            } else {
              return <Button className="active" onClick={() => deactivate(record)}>
            {/* <Space> */}
            {/* {console.log("record 246: ", record)} */}
            {record?.tags[0] === "Active" ? "Deactivate" : "Activate"}
            {record?.tags[0] === "Active" ? (
              <StopOutlined />
            ) : (
              <CheckCircleOutlined />
            )}
          </Button>

            }
            })}
        </Space>
      ),
    },
  ];
  const handleSearch = async (nameSearch) => {
    if (nameSearch.length === 0) {
      getuserlisting(pageno);
    } else {
      const searchResponse = await getUserByName({
        nameSearch,
        pageno: pageno,
      });
      setuserData(searchResponse?.userlist);
    }
  };
  const data =
    userdata?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.image,
        buyPicture: profilethree,
        sellPicture: profilefour,
        name: key?.userName,
        date: moment(key.createdAt).format("LL"),
        BuyShare: `$ ${key?.TotalBuyingAmount}`,
        SellShare: `$ ${key?.TotalSellingAmount}`,
        // tags: [key?.status],
        tags: key?.isDelete === 0 ? [key?.status]: ["Deleted"],
        // delete: key?.isDelete === 0 ? "": "Deleted" ,
      };
    }) || [];

  return (
    <div className="usermanagecontainer p-0">
      <div className="cardmanager">
        <h4>Overview</h4>
        <Cards />
      </div>
      <div className="manageuser">
        <h4> Manage User</h4>

        <div className="table-responsive">
          <div className="managetable">
            <div className="" style={{ display: "flex", flexShrink: "0" }}>
              <div style={{ width: "100%" }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* <div>
                <select
                  className=""
                  style={{
                    marginLeft: "30px",
                    marginTop: "",
                    width: "150px",
                    height: "30px",
                  }}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handlePageData(e.target.value);
                  }}
                >
                  {[10, 50, 100, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                className: "customPagination",
                showQuickJumper: {
                  goButton: <button className="go_button">Go</button>,
                },
                defaultCurrent: 1,
                onChange: (page, pageSize) => {
                  setpageno(page);
                  handlePageData(pageSize);
                },
                showSizeChanger: true,
                pageSizeOptions: ["10", "50", "100", "200"],
                total: total,
                // showTotal: (total) => `Total ${total} items`,
              }}
              scroll={{ y: 500 }}
              className="oe"
            />
          </div>
        </div>
      </div>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <EditModal id={viewId} visible={isModalVisible} />
      </Modal>
    </div>
  );
};

export default AddManageUser;

import React, { useEffect, useState } from "react";
import {
  Tabs,
  Avatar,
  Button,
  Input,
  Space,
  DatePicker,
  Image,
  Table,
} from "antd";
import CommonTable from "../../common/Table";
import "./style.scss";
import CommanCalender from "../../common/CommanCalender/CommanCalender";
import {
  brandListWithActiveStatus,
  brandListWithUnderwritingStatus,
  getBrandListingToExcel,
  getShareHoldersToExcel,
  brandListWithActiveStatusWithDates,
  brandListWithUnderwritingStatusWithDates,
  brandListActiveStatuswithShowData,
  brandListUnderwritingStatuswithShowData,
  BASE_URL,
} from "../../utils/Api";
import moment from "moment";
import { toast } from "react-toastify";

const { TabPane } = Tabs;

const Brand_listing = () => {
  const [brandlist, setbrandlist] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalUnder, setTotalUnder] = useState(0);
  const [activeStatusData, setActiveStatusData] = useState([]);
  const [underwritingStatusData, setUnderwritingStatusData] = useState([]);
  const [fromDt, setFromDt] = useState("");
  const [endDt, setEndDt] = useState("");
  // const [pageSize, setPageSize] = useState("");
  const [pageno, setpageno] = useState("1");
  const [underPageNo, setUnderPageNo] = useState("1");
  const [pageData, setPageData] = useState("10");

  useEffect(() => {
    getActiveStatus(search);
  }, []);

  useEffect(() => {
    getUnderwritingStatus(search);
  }, []);

  useEffect(() => {
    handleDate(10);
  }, [pageno]);

  useEffect(() => {
    handleUnderDate(10);
  }, [underPageNo]);

  const onChangeStart = (date, dateString) => {
    setFromDt(dateString);
  };
  const onChangeEnd = (date, dateString) => {
    setEndDt(dateString);
  };

  // const handlePageData = async (e, page) => {
  //   const res = await brandListActiveStatuswithShowData(pageno, e);
  //   if (res?.status === 200) {
  //     console.log("page data45: ", res);
  //     setActiveStatusData(res.data.Brandlist);
  //     setTotal(res?.data?.totalRecords);
  //   }
  // };

  // const handleUnderwritingPageData = async (e, page) => {
  //   const res = await brandListUnderwritingStatuswithShowData(underPageNo, e);
  //   if (res?.status === 200) {
  //     setUnderwritingStatusData(res.data.Brandlist);
  //     setTotalUnder(res?.data?.totalRecords);
  //   }
  // };

  const handleDate = async (pageSize) => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
      datePageNo: pageno,
      pageActiveData: pageSize,
    };

    const response = await brandListWithActiveStatusWithDates(requestedData);

    if (response?.status === 200) {
      // console.log("Active status data handel: ", response);
      // toast.success(response.data.message);
      setActiveStatusData(response?.data?.Brandlist);
      setTotal(response?.data?.totalRecords);
    } else {
      toast.error(response.data.message);
    }
  };

  const handleUnderDate = async (pageSize) => {
    const requestedData = {
      fromDate: fromDt,
      toDate: endDt,
      datePageNo: underPageNo,
      pageActiveData: pageSize,
    };

    const response = await brandListWithUnderwritingStatusWithDates(
      requestedData
    );

    if (response?.status === 200) {
      // console.log("underWriting status data: ", response)
      // toast.success(response.data.message);
      setUnderwritingStatusData(response?.data?.Brandlist);
      setTotalUnder(response?.data?.totalRecords);
    } else {
      toast.error(response.data.message);
    }
  };

  const getActiveStatus = async (searchTerm, page) => {
    const response = await brandListWithActiveStatus(searchTerm, 1);
    if (response?.status === 200) {
      // console.log("1st active status:", response)
      setActiveStatusData(response?.Brandlist);
      setTotal(response?.totalRecords);
    }
    // console.log("Total records:", total)
  };

  // console.log("total out: ", total)

  const getUnderwritingStatus = async (searchTearm, page) => {
    const response = await brandListWithUnderwritingStatus(searchTearm, 1);
    if (response?.status === 200) {
      // console.log("underwriting use effect: ", response);
      setUnderwritingStatusData(response?.Brandlist);
      setTotalUnder(response?.totalRecords);
    }
  };

  // console.log("brand total009:", total);

  const ExportToExcel = async (id) => {
    const res = await getBrandListingToExcel(id);
  };

  const UnderwrittingToExcel = async (id) => {
    const res = await getShareHoldersToExcel(id);
  };

  const columns = [
    {
      title: "Active Brands",
      dataIndex: "ActiveBrands",
      key: "ActiveBrands",
      width: "25%",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      with: "75%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => ExportToExcel(record.id)}
            style={{ backgroundColor: "#f48327", color: "white" }}
          >
            Export Share Holders
          </Button>
        </Space>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Underwriting Brands",
      dataIndex: "ActiveBrands",
      key: "ActiveBrands",
      width: "25%",
      render: (text, displayPicture) => (
        <>
          <Avatar
            src={
              <Image
                src={`${BASE_URL}/images/${displayPicture.displayPicture}`}
              />
            }
          />{" "}
          {` `}
          <a>{text}</a>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      with: "75%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => UnderwrittingToExcel(record.id)}
            style={{ backgroundColor: "#f48327", color: "white" }}
          >
            Export Share Holders
          </Button>
        </Space>
      ),
    },
  ];

  const data =
    activeStatusData?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.brandImage,
        ActiveBrands: key?.brandUserName,
        Date: moment(key.createdAt).format("LL"),
      };
    }) || [];

  const data2 =
    underwritingStatusData?.map((key, index) => {
      return {
        key: index,
        id: key._id,
        displayPicture: key?.brandImage,
        ActiveBrands: key?.brandUserName,
        Date: moment(key.createdAt).format("LL"),
      };
    }) || [];

  const onChange = async (e) => {
    // e.preventDefault()
    // setPage(e);
    setpageno(e);
    getUnderwritingStatus(search, e);
  };

  const handleSearchUnderwriting = async (nameSearch) => {
    getUnderwritingStatus(nameSearch, page);
  };

  const handleSearch = async (nameSearch) => {
    getActiveStatus(nameSearch);
  };

  return (
    <div className="Brand_listing">
      <div className="label_brand_listing">
        <h4> Brand_listing </h4>
        {/* <CommanCalender /> */}
        {/* <div className="mainCalender">
          <div className="date_pick_box">
            <label>
              <b>Select Date</b>
            </label>
            <label className="input_label">From</label>
            <DatePicker className="dateInput" onChange={onChangeStart} />
            <label className="input_label">To</label>
            <DatePicker className="dateInput" onChange={onChangeEnd} />
            <button className="buttonMain" onClick={handleDate}>
              View
            </button>
          </div>
        </div> */}
      </div>
      <Tabs defaultActiveKey="1" centered className="brand_tab_pane_sec">
        <TabPane tab="Brand Listed" key="1">
          <div className="mainCalender">
            <div className="date_pick_box">
              <label>
                <b>Select Date</b>
              </label>
              <label className="input_label">From</label>
              <DatePicker className="dateInput" onChange={onChangeStart} />
              <label className="input_label">To</label>
              <DatePicker className="dateInput" onChange={onChangeEnd} />
              <button
                className="buttonMain"
                style={{cursor: "pointer"}}
                onClick={() => {
                  handleDate(10);
                }}
              >
                View
              </button>
            </div>
          </div>
          <div className="branding_list">
            <div className="" style={{ display: "flex", flexShrink: "0" }}>
              <div style={{ width: "100%" }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* <div>
                <select
                  className=""
                  style={{
                    marginLeft: "30px",
                    marginTop: "",
                    width: "150px",
                    height: "30px",
                  }}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handlePageData(e.target.value);
                  }}
                >
                  {[10, 50, 100, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="transaction_table_sec">
              <Table
                columns={columns}
                dataSource={data}
                pagination={{
                  className: "customPagination",
                  showQuickJumper: {
                    goButton: <button className="go_button">Go</button>,
                  },
                  defaultCurrent: 1,
                  onChange: (page, pageSize) => {
                    setpageno(page);
                    handleDate(pageSize);
                  },
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "50", "100", "200"],
                  total: total,
                  // showTotal: (total) => `Total ${total} items`,
                }}
                scroll={{ y: 500 }}
                className="oe"
              />
            </div>
          </div>
        </TabPane>
        <TabPane
          tab="Underwriting Process"
          key="2"
          onClick={() => {
            // console.log("underwriting clicked");
          }}
        >
          <div className="mainCalender">
            <div className="date_pick_box">
              <label>
                <b>Select Date</b>
              </label>
              <label className="input_label">From</label>
              <DatePicker className="dateInput" onChange={onChangeStart} />
              <label className="input_label">To</label>
              <DatePicker className="dateInput" onChange={onChangeEnd} />
              <button
                className="buttonMain"
                style={{cursor: "pointer"}}
                onClick={() => {
                  handleUnderDate(10);
                }}
              >
                View
              </button>
            </div>
          </div>
          <div className="branding_list">
            <div className="" style={{ display: "flex", flexShrink: "0" }}>
              <div style={{ width: "100%" }}>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  onChange={(e) => handleSearchUnderwriting(e.target.value)}
                />
              </div>
              {/* <div>
                <select
                  className=""
                  style={{
                    marginLeft: "30px",
                    marginTop: "",
                    width: "150px",
                    height: "30px",
                  }}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handleUnderwritingPageData(e.target.value);
                  }}
                >
                  {[10, 50, 100, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="transaction_table_sec">
              <Table
                columns={columns2}
                dataSource={data2}
                pagination={{
                  className: "customPagination",
                  showQuickJumper: {
                    goButton: <button className="go_button">Go</button>,
                  },
                  defaultCurrent: 1,
                  onChange: (page, pageSize) => {
                    setUnderPageNo(page);
                    handleUnderDate(pageSize);
                  },
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "50", "100", "200"],
                  total: totalUnder,
                  // showTotal: (totalUnder) => `Total ${totalUnder} items`,
                }}
                scroll={{ y: 500 }}
                className="oe"
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

//showTotal: (totalUnder) => `Total ${totalUnder} items`,

export default Brand_listing;

import React, { useEffect, useState } from "react";
import { Avatar, Divider, Tooltip, Card, Button, Modal, Image } from "antd";
import { UserOutlined, AntDesignOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./style.scss";
import ModalUse from "./ModalUse";
import { buysharebrandlisting, BASE_URL } from "../../utils/Api";

const Brand_Distribution_list = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buybrand, setbuybrand] = useState();
  const [brandDetails, setBrandDetails] = useState();

  const buybrandlisting = async () => {
    await buysharebrandlisting()
      .then((res) => {
        setbuybrand(res.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const brandIdLsitData = buybrand?.BuyShare?.filter(
    (item) => item?.brandId
  )?.map((brand) => brand);

  const brandIdLsit = buybrand?.BuyShare?.filter((item) => item?.brandId)?.map(
    (brand) => brand?.brandId?._id
  );
  const brandListObject = new Set(brandIdLsit);
  const brandListArray = [...brandListObject];

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const brandUserList = brandListArray?.reduce((acc, data) => {
    const brandUserContent = brandIdLsitData?.filter(
      (brand) => brand?.brandId?._id == data
    );
    const extractUser = brandUserContent?.map((user) => user?.userId);
    const users = extractUser?.filter((user) => user?._id);
    const obj = {
      brandId: brandUserContent[0]?.brandId,
      usersList: getUniqueListBy(users, "_id") || [],
    };
    acc.push(obj);
    return acc;
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getBrandDetails = (v) => {
    setBrandDetails(v);
    showModal();
  };

  useEffect(() => {
    buybrandlisting();
  }, []);

  return (
    <div className="Board_distribution_container">
      <h4>Brand Distribution</h4>
      <div className="card_wrapper">
        {brandUserList.map((brand) => {
          return (
            <Card className="card_subwrapper">
              <Avatar
                src={
                  <Image
                    src={`${BASE_URL}/images/${brand.brandId.brandImage}`}
                  />
                }
              />

              <p className="Brand_Name">{brand.brandId.brandUserName}</p>
              <p className="own_shares">Owns Shares</p>
              <Avatar.Group
                maxCount={5}
                gap={1}
                maxStyle={{
                  color: "#F56A00",
                  backgroundColor: "#FDE3CF",
                }}
              >
                <Avatar
                  src={`${BASE_URL}/images/${brand.usersList[0]?.image}`}
                />
                <Avatar
                  src={`${BASE_URL}/images/${brand.usersList[1]?.image}`}
                />
                <Avatar
                  src={`${BASE_URL}/images/${brand.usersList[2]?.image}`}
                />

                <div className="more">
                  {" "}
                  <Button
                    style={{
                      border: "none",
                      color: "orange",
                      width: "50px",
                      marginTop: "-10px",
                    }}
                    onClick={(e) => {
                      getBrandDetails(brand);
                    }}
                  >
                    {" "}
                    {/* +{brand.usersList.length}  */}
                    More
                  </Button>
                </div>
              </Avatar.Group>
            </Card>
          );
        })}
        <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <ModalUse brandDetails={brandDetails} />
        </Modal>
      </div>
    </div>
  );
};
export default Brand_Distribution_list;
